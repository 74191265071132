/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SeasonalityTriggerDto } from '../model/seasonality-trigger-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../../services';
import { TenantService } from '../../services/tenant/tenant-service.service';


@Injectable({
  providedIn: 'root'
})
export class SeasonalityTriggerService {

    protected basePath = 'http://localhost';
    protected unmodifiedBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService, private tenantService: TenantService) {
        if (basePath) {
            this.basePath = basePath;
            this.unmodifiedBasePath = basePath;
        }

        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
            this.unmodifiedBasePath = basePath || configuration.basePath || this.basePath;
        }

        tenantService.currentTenant$.subscribe(tenant => {
            this.setBasePath(tenant);
        });
    }

    private setBasePath(tenant) {
        if (tenant && tenant.SubDomain) {
            let schemalessBasePath = this.unmodifiedBasePath.replace("https://", "").replace("http://", "");
            this.basePath = `https://${tenant.SubDomain}.${schemalessBasePath}`;
        } else {                
            this.basePath = this.unmodifiedBasePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param commitmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDSeasonalityTriggersGet(commitmentID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SeasonalityTriggerDto>>;
    public commitmentsCommitmentIDSeasonalityTriggersGet(commitmentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SeasonalityTriggerDto>>>;
    public commitmentsCommitmentIDSeasonalityTriggersGet(commitmentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SeasonalityTriggerDto>>>;
    public commitmentsCommitmentIDSeasonalityTriggersGet(commitmentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SeasonalityTriggerService")){
            let serviceRequest = this.httpClient.get<Array<SeasonalityTriggerDto>>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<SeasonalityTriggerDto>>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param title 
     * @param startDay 
     * @param startMonth 
     * @param endDay 
     * @param endMonth 
     * @param seasonalityTriggerID 
     * @param description 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDSeasonalityTriggersPut(commitmentID: string, title: string, startDay: number, startMonth: number, endDay: number, endMonth: number, seasonalityTriggerID?: string, description?: string, observe?: 'body', reportProgress?: boolean): Observable<SeasonalityTriggerDto>;
    public commitmentsCommitmentIDSeasonalityTriggersPut(commitmentID: string, title: string, startDay: number, startMonth: number, endDay: number, endMonth: number, seasonalityTriggerID?: string, description?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SeasonalityTriggerDto>>;
    public commitmentsCommitmentIDSeasonalityTriggersPut(commitmentID: string, title: string, startDay: number, startMonth: number, endDay: number, endMonth: number, seasonalityTriggerID?: string, description?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SeasonalityTriggerDto>>;
    public commitmentsCommitmentIDSeasonalityTriggersPut(commitmentID: string, title: string, startDay: number, startMonth: number, endDay: number, endMonth: number, seasonalityTriggerID?: string, description?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersPut.');
        }

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersPut.');
        }

        if (startDay === null || startDay === undefined) {
            throw new Error('Required parameter startDay was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersPut.');
        }

        if (startMonth === null || startMonth === undefined) {
            throw new Error('Required parameter startMonth was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersPut.');
        }

        if (endDay === null || endDay === undefined) {
            throw new Error('Required parameter endDay was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersPut.');
        }

        if (endMonth === null || endMonth === undefined) {
            throw new Error('Required parameter endMonth was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersPut.');
        }



        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (seasonalityTriggerID !== undefined) {
            formParams = formParams.append('SeasonalityTriggerID', <any>seasonalityTriggerID) || formParams;
            
        }
        if (title !== undefined) {
            formParams = formParams.append('Title', <any>title) || formParams;
            
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) || formParams;
            
        }
        if (startDay !== undefined) {
            formParams = formParams.append('StartDay', <any>startDay) || formParams;
            
        }
        if (startMonth !== undefined) {
            formParams = formParams.append('StartMonth', <any>startMonth) || formParams;
            
        }
        if (endDay !== undefined) {
            formParams = formParams.append('EndDay', <any>endDay) || formParams;
            
        }
        if (endMonth !== undefined) {
            formParams = formParams.append('EndMonth', <any>endMonth) || formParams;
            
        }

        if(this.tenantService.excludedServiceNames.includes("SeasonalityTriggerService")){
            let serviceRequest = this.httpClient.put<SeasonalityTriggerDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers`,
                convertFormParamsToString ? formParams.toString() : formParams,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<SeasonalityTriggerDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers`,
                    convertFormParamsToString ? formParams.toString() : formParams,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param seasonalityTriggerID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDDelete(commitmentID: string, seasonalityTriggerID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDDelete(commitmentID: string, seasonalityTriggerID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDDelete(commitmentID: string, seasonalityTriggerID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDDelete(commitmentID: string, seasonalityTriggerID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDDelete.');
        }

        if (seasonalityTriggerID === null || seasonalityTriggerID === undefined) {
            throw new Error('Required parameter seasonalityTriggerID was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SeasonalityTriggerService")){
            let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers/${encodeURIComponent(String(seasonalityTriggerID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers/${encodeURIComponent(String(seasonalityTriggerID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param seasonalityTriggerID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDGet(commitmentID: string, seasonalityTriggerID: string, observe?: 'body', reportProgress?: boolean): Observable<SeasonalityTriggerDto>;
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDGet(commitmentID: string, seasonalityTriggerID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SeasonalityTriggerDto>>;
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDGet(commitmentID: string, seasonalityTriggerID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SeasonalityTriggerDto>>;
    public commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDGet(commitmentID: string, seasonalityTriggerID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDGet.');
        }

        if (seasonalityTriggerID === null || seasonalityTriggerID === undefined) {
            throw new Error('Required parameter seasonalityTriggerID was null or undefined when calling commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SeasonalityTriggerService")){
            let serviceRequest = this.httpClient.get<SeasonalityTriggerDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers/${encodeURIComponent(String(seasonalityTriggerID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<SeasonalityTriggerDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/seasonality-triggers/${encodeURIComponent(String(seasonalityTriggerID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public seasonalityTriggersGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SeasonalityTriggerDto>>;
    public seasonalityTriggersGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SeasonalityTriggerDto>>>;
    public seasonalityTriggersGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SeasonalityTriggerDto>>>;
    public seasonalityTriggersGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SeasonalityTriggerService")){
            let serviceRequest = this.httpClient.get<Array<SeasonalityTriggerDto>>(`${this.basePath}/seasonality-triggers`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<SeasonalityTriggerDto>>(`${this.basePath}/seasonality-triggers`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

}
