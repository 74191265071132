import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { CustomPageService } from "src/app/shared/generated/api/custom-page.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { CustomPageDto, CustomPageUpsertDto } from "src/app/shared/generated/model/models";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { Observable, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { NgForm, FormsModule } from "@angular/forms";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf, AsyncPipe } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";
@Component({
    selector: "beacon-custom-page-detail",
    templateUrl: "./custom-page-detail.component.html",
    styleUrls: ["./custom-page-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinner,
        FormsModule,
        TinymceEditorComponent_1,
        AsyncPipe,
        MatButton,
        MatIcon,
        MatIconButton,
        BypassHtmlPipe,
        LoadingSpinnerComponent,
    ],
})
export class CustomPageDetailComponent implements OnInit, OnDestroy {
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;
    @ViewChild("customPageForm", { read: NgForm }) form: NgForm;
    @Input() customPageVanityUrl: string;
    public customPageContent: string;
    public viewableRoleIDs: Array<number>;
    public isLoading: boolean = true;
    public isEditing: boolean = false;
    public isEmptyContent: boolean = false;
    public originalContent: string;

    private currentUser: UserDto;

    public customPage: CustomPageDto;
    public customPage$: Observable<any>;

    userSubscription: Subscription;
    customRolesSubcription: Subscription;

    constructor(
        private customPageService: CustomPageService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        this.route.params.subscribe((params) => {
            let vanityURL = params["vanity-url"];
            if (vanityURL) {
                this.customPage$ = this.customPageService.customPagesGetByURLCustomPageVanityURLGet(vanityURL).pipe(
                    map((customPage) => {
                        this.customPage = customPage;
                        this.isEmptyContent = customPage.IsEmptyContent;
                        this.originalContent = customPage.CustomPageContent;
                        this.isLoading = false;
                        return customPage;
                    })
                );

                this.customRolesSubcription = this.customPageService.customPagesGetByURLCustomPageVanityURLRolesGet(vanityURL).subscribe((pageRoleDtos) => {
                    this.viewableRoleIDs = pageRoleDtos.map((pageRole) => pageRole.RoleID);
                    this.cdr.markForCheck();
                });
            }
        });
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.customRolesSubcription?.unsubscribe();
    }

    canExit() {
        return !this.isEditing || this.originalContent == this.customPage.CustomPageContent;
    }

    public canReadCustomPages(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Read);
    }

    public showEditButton(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Update);
    }

    public enterEdit(): void {
        this.isEditing = true;
        this.cdr.markForCheck();
    }

    public cancelEdit(): void {
        this.isEditing = false;
    }

    public saveEdit(): void {
        this.isEditing = false;
        this.isLoading = true;
        const updateDto = new CustomPageUpsertDto({
            CustomPageContent: this.customPage.CustomPageContent,
            CustomPageDisplayName: this.customPage.CustomPageDisplayName,
            CustomPageVanityUrl: this.customPage.CustomPageVanityUrl,
            MenuItemID: this.customPage.MenuItem.MenuItemID,
            ViewableRoleIDs: this.viewableRoleIDs,
        });

        this.customPage$ = this.customPageService.customPagesCustomPageIDPut(this.customPage.CustomPageID, updateDto).pipe(
            map(
                (response) => {
                    this.customPage = response;
                    this.isEmptyContent = response.IsEmptyContent;
                    this.isLoading = false;
                    return response;
                },
                catchError((err) => {
                    this.isLoading = false;
                    this.alertService.pushAlert(new Alert("There was an error updating the rich text content", AlertContext.Danger, true));
                    return err;
                })
            )
        );
    }
}
