import { DatePipe, NgIf, AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DailyMonitoringReportService } from "src/app/shared/generated/api/daily-monitoring-report.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { DailyMonitoringReportDetailsEventingServiceService } from "./daily-monitoring-report-details-eventing-service/daily-monitoring-report-details-eventing-service.service";
import { Lightbox, LightboxModule } from "ng-gallery/lightbox";
import { GalleryModule, Gallery, ImageItem } from "ng-gallery";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { DailyMonitoringReportCommunicationsComponent } from "./daily-monitoring-report-communications/daily-monitoring-report-communications.component";
import { DailyMonitoringReportObservationsComponent } from "./daily-monitoring-report-observations/daily-monitoring-report-observations.component";
import { MapComponent } from "../../shared/components/map/map.component";
import { FormsModule } from "@angular/forms";
import { EsaMaterialButtonComponent, EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";

@Component({
    selector: "daily-monitoring-report-details",
    templateUrl: "./daily-monitoring-report-details.component.html",
    styleUrls: ["./daily-monitoring-report-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        EsaMaterialFormFieldComponent,
        MapComponent,
        DailyMonitoringReportObservationsComponent,
        DailyMonitoringReportCommunicationsComponent,
        BackToTopComponent,
        MatProgressSpinner,
        AsyncPipe,
        DatePipe,
        LightboxModule,
        GalleryModule,
        MatButton,
        MatIcon,
        MatTooltip,
        MatIconButton,
        LoadingSpinnerComponent,
    ],
    providers: [DatePipe],
})
export class DailyMonitoringReportDetailsComponent implements OnInit, OnDestroy {
    @ViewChild("customItemTemplate") customItemTemplate: TemplateRef<any>;

    public dailyMonitoringReport$: Observable<any>;
    public dailyMonitoringReport: any;

    public observations$: Observable<any[]>;
    public observations: any[] = [];
    public filteredObservations: any[] = [];
    public selectedObservation: any = null;

    public observationLogPhotos: ImageItem[];
    public showLoadingOverlay: boolean = false;

    public extentFilterToggle: boolean = false;

    private currentUserSubscription: Subscription;
    public currentUser: UserDto;

    constructor(
        private dailyMonitoringReportService: DailyMonitoringReportService,
        private dailyMonitoringReportDetailsEventingService: DailyMonitoringReportDetailsEventingServiceService,
        private gallery: Gallery,
        private lightbox: Lightbox,
        private datePipe: DatePipe,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.route.params.subscribe((params) => {
            const dailyMonitoringReportID = params.id;

            this.dailyMonitoringReport$ = this.dailyMonitoringReportService
                .dailyMonitoringReportsDailyMonitoringReportRecordIDGet(dailyMonitoringReportID)
                .pipe(
                    map((dmr) => {
                        dmr.$WindSpeed = dmr["wind_speed"] ? `${dmr["wind_speed"]} kts` : "";
                        dmr.$AirTemperature = dmr["air_temp_current_f"] ? `${dmr["air_temp_current_f"]} °F` : "";
                        this.dailyMonitoringReport = dmr;
                        this.cdr.markForCheck();
                        return dmr;
                    })
                );

            this.observations$ = this.dailyMonitoringReportService
                .dailyMonitoringReportsDailyMonitoringReportRecordIDObservationsGet(dailyMonitoringReportID)
                .pipe(
                    tap((result) => {
                        this.observations = result;

                        this.observations.forEach((observation) => {
                            observation["_geometry"]["properties"] = {};

                            let observationName =
                                observation["follow_up_yes_no"] === "no"
                                    ? `${observation["general_activity"]} - ${observation["location_of_observation"]}`
                                    : `${observation["category"]} - ${observation["location_of_observation"]} - ${this?.datePipe?.transform(
                                          observation["initial_date_of_trackable"],
                                          "longDate",
                                          "UTC"
                                      )}`;

                            observation["_geometry"]["properties"]["tooltip"] = observationName;
                        });

                        this.filteredObservations = this.observations;
                    })
                );
        });
    }

    ngOnDestroy(): void {
        this.currentUserSubscription?.unsubscribe();
    }

    copyLink() {
        let url = window.location.href;
        navigator.clipboard.writeText(url).then(() => {
            this.alertService.pushAlert(new Alert("Link copied to clipboard", AlertContext.Success, true, "link-copied"), 1000 * 3);
        });
    }

    returnToList() {
        this.router.navigate(["/daily-monitoring-reports"]);
        this.cdr.markForCheck();
    }

    viewPhotos() {
        if (this.observationLogPhotos && this.observationLogPhotos.length > 0) {
            this.lightbox.open(0, this.dailyMonitoringReport["_record_id"], {
                panelClass: "fullscreen",
            });

            return;
        }

        this.showLoadingOverlay = true;
        this.cdr.markForCheck();

        this.dailyMonitoringReportService
            .dailyMonitoringReportsDailyMonitoringReportRecordIDObservationLogPhotosGet(this.dailyMonitoringReport["_record_id"])
            .subscribe((result) => {
                let photos = result.map((photo) => {
                    // MK 4/25/2024 -- The photo object is nested in a "photo" property huzzah :shrug: try to address this in the field mapping spike ECP-163
                    let p = photo["photo"];

                    let dateTimeKey = "date_time";
                    if (!p[dateTimeKey]) {
                        dateTimeKey = "date_time_original";
                    }

                    let hasExifDate = p["exif"][dateTimeKey];
                    if (hasExifDate) {
                        let date = p["exif"][dateTimeKey];
                        p.$SortTime = this.datePipe.transform(date, "HH:mm:ss", "UTC");
                        return photo["photo"];
                    }

                    let date = p["created_at"];
                    p.$SortTime = this.datePipe.transform(date, "HH:mm:ss", "PST"); //MK 5/9/2024 -- This is a temporary fix until we can get the correct timezone from the Tenant/API.
                    return photo["photo"];
                });

                photos.sort((a, b) => {
                    let timeA = a.$SortTime;
                    let timeB = b.$SortTime;

                    if (timeA === null && timeB === null) {
                        return 0;
                    } else if (timeA === null) {
                        return -1;
                    } else if (timeB === null) {
                        return 1;
                    }

                    return timeA.localeCompare(timeB);
                });

                this.observationLogPhotos = photos.map((photo) => {
                    return new ImageItem({ src: photo["original"], thumb: photo["thumbnail"], alt: photo.$Caption });
                });

                const galleryRef = this.gallery.ref(this.dailyMonitoringReport["_record_id"], {
                    loadingStrategy: "lazy",
                });

                galleryRef.load(this.observationLogPhotos);

                galleryRef.setConfig({
                    itemTemplate: this.customItemTemplate,
                });

                this.showLoadingOverlay = false;
                this.cdr.markForCheck();

                this.lightbox.open(0, this.dailyMonitoringReport["_record_id"], {
                    panelClass: "fullscreen",
                });
            });
    }

    mapFeatureClicked(event) {
        this.selectedObservation = this.observations.find((obs) => {
            return obs["_record_id"] === event["properties"]["_record_id"];
        });

        this.dailyMonitoringReportDetailsEventingService.mapClicked(this.selectedObservation);

        this.cdr.markForCheck();
    }

    panelToggled(observation) {
        this.selectedObservation = observation;
        this.cdr.markForCheck();
    }
}
