import { Component, Input } from "@angular/core";
import { ComponentStatusEnum } from "../../generated/model/component-status-enum";

@Component({
    selector: "pill-component-status",
    standalone: true,
    imports: [],
    templateUrl: "./pill-component-status.component.html",
    styleUrl: "./pill-component-status.component.scss",
})
export class PillComponentStatusComponent {
    ComponentStatusEnum = ComponentStatusEnum;
    @Input() componentStatusID: number;
}
