/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SourceDto } from '../model/source-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../../services';
import { TenantService } from '../../services/tenant/tenant-service.service';


@Injectable({
  providedIn: 'root'
})
export class SourceService {

    protected basePath = 'http://localhost';
    protected unmodifiedBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService, private tenantService: TenantService) {
        if (basePath) {
            this.basePath = basePath;
            this.unmodifiedBasePath = basePath;
        }

        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
            this.unmodifiedBasePath = basePath || configuration.basePath || this.basePath;
        }

        tenantService.currentTenant$.subscribe(tenant => {
            this.setBasePath(tenant);
        });
    }

    private setBasePath(tenant) {
        if (tenant && tenant.SubDomain) {
            let schemalessBasePath = this.unmodifiedBasePath.replace("https://", "").replace("http://", "");
            this.basePath = `https://${tenant.SubDomain}.${schemalessBasePath}`;
        } else {                
            this.basePath = this.unmodifiedBasePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param projectID 
     * @param name 
     * @param dateOfLatestAmendment 
     * @param sourceDocumentID 
     * @param referenceNumber 
     * @param approvingAgency 
     * @param agencyContact 
     * @param agencySignatory 
     * @param description 
     * @param agreementTerms 
     * @param sourceDocumentFilesJsonStrings 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourceDocumentsPut(projectID: string, name: string, dateOfLatestAmendment: string, sourceDocumentID?: string, referenceNumber?: string, approvingAgency?: string, agencyContact?: string, agencySignatory?: string, description?: string, agreementTerms?: string, sourceDocumentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<SourceDto>;
    public sourceDocumentsPut(projectID: string, name: string, dateOfLatestAmendment: string, sourceDocumentID?: string, referenceNumber?: string, approvingAgency?: string, agencyContact?: string, agencySignatory?: string, description?: string, agreementTerms?: string, sourceDocumentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SourceDto>>;
    public sourceDocumentsPut(projectID: string, name: string, dateOfLatestAmendment: string, sourceDocumentID?: string, referenceNumber?: string, approvingAgency?: string, agencyContact?: string, agencySignatory?: string, description?: string, agreementTerms?: string, sourceDocumentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SourceDto>>;
    public sourceDocumentsPut(projectID: string, name: string, dateOfLatestAmendment: string, sourceDocumentID?: string, referenceNumber?: string, approvingAgency?: string, agencyContact?: string, agencySignatory?: string, description?: string, agreementTerms?: string, sourceDocumentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling sourceDocumentsPut.');
        }

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling sourceDocumentsPut.');
        }

        if (dateOfLatestAmendment === null || dateOfLatestAmendment === undefined) {
            throw new Error('Required parameter dateOfLatestAmendment was null or undefined when calling sourceDocumentsPut.');
        }










        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (sourceDocumentID !== undefined) {
            formParams = formParams.append('SourceDocumentID', <any>sourceDocumentID) || formParams;
            
        }
        if (projectID !== undefined) {
            formParams = formParams.append('ProjectID', <any>projectID) || formParams;
            
        }
        if (name !== undefined) {
            formParams = formParams.append('Name', <any>name) || formParams;
            
        }
        if (dateOfLatestAmendment !== undefined) {
            formParams = formParams.append('DateOfLatestAmendment', <any>dateOfLatestAmendment) || formParams;
            
        }
        if (referenceNumber !== undefined) {
            formParams = formParams.append('ReferenceNumber', <any>referenceNumber) || formParams;
            
        }
        if (approvingAgency !== undefined) {
            formParams = formParams.append('ApprovingAgency', <any>approvingAgency) || formParams;
            
        }
        if (agencyContact !== undefined) {
            formParams = formParams.append('AgencyContact', <any>agencyContact) || formParams;
            
        }
        if (agencySignatory !== undefined) {
            formParams = formParams.append('AgencySignatory', <any>agencySignatory) || formParams;
            
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) || formParams;
            
        }
        if (agreementTerms !== undefined) {
            formParams = formParams.append('AgreementTerms', <any>agreementTerms) || formParams;
            
        }
        if (sourceDocumentFilesJsonStrings !== undefined) {
            for (let i = 0; i < sourceDocumentFilesJsonStrings.length; i++) {
                formParams = formParams.append('SourceDocumentFilesJsonStrings[]', <any>sourceDocumentFilesJsonStrings[i]) || formParams;
            }
            
            
        }
        if (files !== undefined) {
            for (let i = 0; i < files.length; i++) {
                formParams = formParams.append('files', <any>files[i]) || formParams;
            }
        }

        if(this.tenantService.excludedServiceNames.includes("SourceService")){
            let serviceRequest = this.httpClient.put<SourceDto>(`${this.basePath}/source-documents`,
                convertFormParamsToString ? formParams.toString() : formParams,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<SourceDto>(`${this.basePath}/source-documents`,
                    convertFormParamsToString ? formParams.toString() : formParams,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<SourceDto>>;
    public sourcesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SourceDto>>>;
    public sourcesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SourceDto>>>;
    public sourcesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SourceService")){
            let serviceRequest = this.httpClient.get<Array<SourceDto>>(`${this.basePath}/sources`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<SourceDto>>(`${this.basePath}/sources`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param sourceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesSourceIDDelete(sourceID: string, observe?: 'body', reportProgress?: boolean): Observable<SourceDto>;
    public sourcesSourceIDDelete(sourceID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SourceDto>>;
    public sourcesSourceIDDelete(sourceID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SourceDto>>;
    public sourcesSourceIDDelete(sourceID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling sourcesSourceIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SourceService")){
            let serviceRequest = this.httpClient.delete<SourceDto>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.delete<SourceDto>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param sourceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesSourceIDGet(sourceID: string, observe?: 'body', reportProgress?: boolean): Observable<SourceDto>;
    public sourcesSourceIDGet(sourceID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SourceDto>>;
    public sourcesSourceIDGet(sourceID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SourceDto>>;
    public sourcesSourceIDGet(sourceID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling sourcesSourceIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SourceService")){
            let serviceRequest = this.httpClient.get<SourceDto>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<SourceDto>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

}
