<div class="detail-view">
    <div class="page-header">
        <h1 class="page-title">Labels & Definitions</h1>

        <div class="g-col-6">
            <p>A list of labels in the system and their definitions.</p>
        </div>
    </div>

    <div class="table-responsive" style="height: 700px">
        <beacon-grid-actions [grid]="fieldDefinitionsGrid" downloadFileName="field-definitions"></beacon-grid-actions>
        <ag-grid-angular
            #fieldDefinitionsGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [suppressMenuHide]="true"
            (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>
