import { Component, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { MsalBroadcastService, MsalService, MsalModule } from "@azure/msal-angular";
import { IdTokenClaims } from "@azure/msal-common";
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, SsoSilentRequest } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { b2cPolicies } from "./auth.config";
import { AuthenticationService } from "./services/authentication.service";
import { HeaderNavComponent } from "./shared/components/header-nav/header-nav.component";
import { RouterOutlet } from "@angular/router";
import { MatCardModule } from "@angular/material/card";

import { MatDividerModule } from "@angular/material/divider";
import { MatButton } from "@angular/material/button";

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string;
    tfp?: string;
};

@Component({
    standalone: true,
    imports: [HeaderNavComponent, RouterOutlet, MatCardModule, MatButton, MatDividerModule, MsalModule],
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();
    claimsUser: any;

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private localAuthService: AuthenticationService,
        public viewRef: ViewContainerRef
    ) {}

    ngOnInit(): void {
        this.authService.handleRedirectObservable().subscribe();

        this.isIframe = window !== window.parent && !window.opener;

        this.setLoginDisplay();

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                let payload = result.payload as AuthenticationResult;
                let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

                if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
                    this.authService.instance.setActiveAccount(payload.account);
                    this.claimsUser = this.authService.instance.getActiveAccount()?.idTokenClaims;
                    // set this in the local auth service here?
                    this.localAuthService.setClaimsUser(this.claimsUser);
                }

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
                 * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
                 * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = this.authService.instance
                        .getAllAccounts()
                        .find(
                            (account: AccountInfo) =>
                                account.idTokenClaims?.oid === idtoken.oid &&
                                account.idTokenClaims?.sub === idtoken.sub &&
                                ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn ||
                                    (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest: SsoSilentRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    this.authService.ssoSilent(signUpSignInFlowRequest).subscribe((a) => {
                        this.authService.instance.setActiveAccount(a.account);
                        this.claimsUser = this.authService.instance.getActiveAccount()?.idTokenClaims;
                        var authObj = { scopes: b2cPolicies.apiScopes, account: a.account, forceRefresh: true };

                        this.authService.acquireTokenSilent(authObj);
                        this.localAuthService.setClaimsUser(this.claimsUser);
                    });
                }

                return result;
            });
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }

        let newClaimsUser = this.authService.instance.getActiveAccount()?.idTokenClaims;
        if (newClaimsUser && newClaimsUser["tfp"] == b2cPolicies.names.editProfile) {
            this.claimsUser = newClaimsUser;
            this.localAuthService.setClaimsUser(newClaimsUser);
        } else if (newClaimsUser && (!this.claimsUser || newClaimsUser["sub"] != this.claimsUser["sub"])) {
            this.claimsUser = newClaimsUser;
            this.localAuthService.setClaimsUser(newClaimsUser);
        }
    }

    login() {
        this.localAuthService.loginRedirct();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
