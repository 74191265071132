import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ITooltipAngularComp } from "ag-grid-angular";
import { ITooltipParams } from "ag-grid-community";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";

@Component({
    selector: "tooltip",
    standalone: true,
    imports: [CommonModule, MatIcon, MatButton],
    templateUrl: "./tooltip.component.html",
    styleUrl: "./tooltip.component.scss",
})
export class AgGridTooltipComponent implements ITooltipAngularComp {
    constructor(
        private alertService: AlertService,
        private cdr: ChangeDetectorRef
    ) {}

    public displayValue: string;

    agInit(params: ITooltipParams): void {
        if (!params.value) return;

        this.displayValue =
            params.value instanceof Object ? (params.value.LinkDisplay ?? params.value.downloadDisplay) : (params.valueFormatted ?? params.value);
    }

    copyToClipboard(): void {
        navigator.clipboard.writeText(this.displayValue);
        this.alertService.pushAlert(new Alert("Copied text to clipboard.", AlertContext.Success), 2000);
    }
}
