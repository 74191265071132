<div class="commitment-upsert-dialog">
    @if (isLoading) {
        <beacon-loading-overlay>
            <loading-spinner [strokeWidth]="5" [diameter]="48"></loading-spinner>
            Saving Commitment... Please be patient when uploading large files.
        </beacon-loading-overlay>
    }

    <h1 mat-dialog-title>
        {{ data.Commitment?.CommitmentID ? "Edit Commitment" : "Add Commitment" }}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <esa-material-ff name="Title" [editMode]="true" [required]="true" type="text" label="Title" formControlName="Title"> </esa-material-ff>
            <esa-material-ff
                name="Client Commitment ID"
                [editMode]="true"
                [required]="true"
                type="text"
                label="Client Commitment ID"
                formControlName="ClientCommitmentID">
            </esa-material-ff>

            <esa-material-ff
                name="Source"
                [editMode]="true"
                [required]="true"
                type="dropdown"
                label="Source Document"
                [options]="sourceDocuments$ | async"
                optionLabel="Name"
                optionValue="SourceID"
                formControlName="SourceID">
            </esa-material-ff>

            <esa-material-ff
                name="Commitment Type"
                [editMode]="true"
                [required]="true"
                type="dropdown"
                label="Commitment Type"
                [options]="commitmentTypes$ | async"
                optionLabel="Name"
                optionValue="CommitmentTypeID"
                formControlName="CommitmentTypeID">
            </esa-material-ff>

            <esa-material-ff
                name="Technical Leads"
                [editMode]="true"
                [required]="false"
                type="dropdown"
                label="Technical Leads"
                [dropdownMultiple]="true"
                [options]="users$ | async"
                optionLabel="FullName"
                optionValue="UserID"
                formControlName="TechnicalLeadIDs">
            </esa-material-ff>

            <esa-material-ff
                name="ResourceCategory"
                [editMode]="true"
                [required]="false"
                type="dropdown"
                label="Resource Category"
                [options]="resourceCategories$ | async"
                optionLabel="Name"
                optionValue="ResourceCategoryID"
                formControlName="ResourceCategoryID">
            </esa-material-ff>

            <div class="species-code kv-pair">
                <custom-form-label class="key" label="Species Code"></custom-form-label>
                <beacon-select
                    class="value"
                    label="Species Code"
                    formControlName="SpeciesCode"
                    [options]="allSpeciesCodes$ | async"
                    [requireSelection]="false"></beacon-select>
            </div>

            <esa-material-ff name="Seasonality" [editMode]="true" [required]="false" type="text" label="Seasonality" formControlName="Seasonality">
            </esa-material-ff>

            <div class="form-group">
                <div class="kv-pair">
                    <custom-form-label label="Work Activities" fieldDefinitionType="WorkActivities"></custom-form-label>
                    <div class="value">
                        <mat-form-field appearance="outline" style="width: 100%">
                            <mat-chip-grid #chipList aria-label="Work Activity selection">
                                <mat-chip-row
                                    *ngFor="let workActivity of formGroup.get('WorkActivities').value"
                                    [removable]="true"
                                    (removed)="removeWorkActivity(workActivity)">
                                    {{ workActivity.Name }}
                                    <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                                </mat-chip-row>
                                <input
                                    matInput
                                    type="text"
                                    placeholder="Add work activity..."
                                    [formControl]="workActivitySearchControl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="false"
                                    (matChipInputTokenEnd)="addWorkActivity($event)" />
                            </mat-chip-grid>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectWorkActivity($event)">
                                <mat-option *ngFor="let workActivity of filteredWorkActivities$ | async" [value]="workActivity.Name">
                                    {{ workActivity.Name }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <esa-material-ff name="Location" [editMode]="true" [required]="false" type="text" label="Location" formControlName="Location"> </esa-material-ff>

            <esa-material-ff
                name="General Guidance"
                [editMode]="true"
                [required]="false"
                type="text"
                label="General Guidance"
                formControlName="GeneralGuidance">
            </esa-material-ff>

            <div class="form-group flex-between gap">
                <custom-form-label label="Full Commitment Text" fieldDefinitionType="FullCommitmentText"></custom-form-label>
                <div class="value">
                    <div class="grid-12">
                        <div class="g-col-12">
                            <editor class="mb-2" #tinyMceEditor [init]="tinyMceEditor | tinyMceConfig" formControlName="FullCommitmentText"></editor>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <kv-pair key="Files" [value]="fileDropper">
                    <ng-template #fileDropper>
                        <file-drop-upload uploadFileAccepts=".pdf,.csv,.xlsx" [multiple]="true" (filesChanged)="onFileUpload($event)"></file-drop-upload>
                        <ul class="file-max-height">
                            <li *ngFor="let file of formGroup.controls.NewCommitmentFiles.value">
                                <simple-file-display
                                    [isNotYetUploaded]="true"
                                    [fileName]="file.name"
                                    [displayDelete]="true"
                                    (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            </li>
                            <li *ngFor="let file of formGroup.controls.CommitmentFiles.value">
                                <simple-file-display
                                    [fileName]="file.Name"
                                    [displayDelete]="true"
                                    [downloadUrl]="'commitments/' + data.Commitment.CommitmentID + '/files/' + file.CommitmentFileID"
                                    (deleteFile)="deleteExistingFile(file)"></simple-file-display>
                            </li>
                        </ul>
                    </ng-template>
                </kv-pair>
            </div>
        </form>

        <alert-box [alertContext]="AlertContext.Danger" *ngIf="duplicateFileNames.length > 0" [alertContext]="AlertContext.Danger"
            ><strong>Warning:</strong> You are uploading {{ duplicateFileNames.length }} file(s) that already exist. Are you sure you want to override the
            following files? {{ duplicateFileNames.join(", ") }}</alert-box
        >
    </div>

    <div mat-dialog-actions align="end">
        <span [matTooltip]="!formGroup.valid ? 'You are missing required fields.' : ''">
            <button mat-flat-button color="primary" style="margin-right: 1rem" (click)="onSave()" cdkFocusInitial [disabled]="!formGroup.valid || isLoading">
                Save
            </button>
        </span>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
