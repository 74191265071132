@if (currentUser$ | async; as currentUser) {
    <aside class="sidebar">
        <div class="sticky">
            @if (sidebarHeader) {
                <div class="sidebar-header">
                    <ng-container [ngTemplateOutlet]="sidebarHeader" />
                </div>
            }
            @for (group of sideNavGroups; track group) {
                @if (group.Title) {
                    <h3 class="sidebar-group-title">{{ group.Title }}</h3>
                }
                <ul class="sidebar-nav">
                    @for (item of group.Items; track item) {
                        <!-- If the item has a permission and the current user has that permission, or if the item has no permission requirements, display the item. -->
                        @if ((item.Permission && currentUser | withPermission: item.Permission) || !item.Permission) {
                            <li class="sidebar-item">
                                <a [routerLink]="item.RouterLink" routerLinkActive="active" class="sidebar-link" [routerLinkActiveOptions]="{ exact: true }">
                                    <span>
                                        @if (item.Icon) {
                                            <beacon-entity-icon [entity]="item.Icon" [inline]="true" />
                                        }
                                        {{ item.Title }}
                                    </span>

                                    <span style="float: right" *ngIf="item.ItemCount || item.ItemCount == 0">({{ item.ItemCount }})</span>
                                </a>
                            </li>
                        }
                    }
                    <!-- If the item has a permission and the current user has that permission, or if the item has no permission requirements, display the item. -->
                    <!-- @if ((item.Permission && currentUser | withPermission: item.Permission) || !item.Permission) {
                        <li class="sidebar-item">
                            <a [routerLink]="item.RouterLink" routerLinkActive="active" class="sidebar-link" [routerLinkActiveOptions]="{ exact: true }">
                                {{ item.Title }}
                                <span style="float: right" *ngIf="item.ItemCount || item.ItemCount == 0">({{ item.ItemCount }})</span>
                            </a>
                        </li>
                    } -->
                </ul>
            }
        </div>
    </aside>
}
