<div class="seasonality-trigger-container">
    <div class="seasonality-trigger-container__header">
        <h3>Seasonality Triggers</h3>
        <div class="add-seasonality-trigger-button-container">
            <button mat-flat-button color="primary" type="button" (click)="addSeasonalityTrigger()" class="btn btn-primary">Add New</button>
        </div>
    </div>

    @if (commitmentExistingSeasonalityTriggers$ | async; as commitmentExistingSeasonalityTrigger) {
        @if (commitmentExistingSeasonalityTrigger.length > 0) {
            <div class="existing-seasonality-triggers">
                <ul>
                    <li *ngFor="let seasonalityTrigger of commitmentExistingSeasonalityTrigger">
                        <div class="seasonality-trigger-item">
                            <div class="seasonality-trigger-item__header">
                                <strong>{{ seasonalityTrigger.Title }}</strong>
                                <mat-icon matTooltip="{{ seasonalityTrigger.Description }}">help_outline</mat-icon>
                            </div>
                            <div class="seasonality-trigger-item__body">
                                <kv-pair key="" [value]="dateRangeValue">
                                    <ng-template #dateRangeValue>
                                        {{ { month: seasonalityTrigger.StartMonth, day: seasonalityTrigger.StartDay } | seasonalityTriggerDate: "MMM d" }} -
                                        {{ { month: seasonalityTrigger.EndMonth, day: seasonalityTrigger.EndDay } | seasonalityTriggerDate: "MMM d" }}
                                    </ng-template>
                                </kv-pair>
                            </div>
                            <div class="flex-end seasonality-trigger-item__footer">
                                <button
                                    mat-icon-button
                                    color="primary"
                                    type="button"
                                    class="small-icon-button"
                                    (click)="editSeasonalityTrigger(seasonalityTrigger)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    color="primary"
                                    type="button"
                                    class="small-icon-button"
                                    (click)="deleteSeasonalityTrigger(seasonalityTrigger)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        } @else {
            <div class="no-seasonality-trigger-container">
                <div class="no-seasonality-trigger-text">No Seasonality Triggers</div>
            </div>
        }
    } @else {
        <loading-spinner></loading-spinner>
    }
</div>
