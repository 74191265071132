import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef } from "@angular/core";
import { DailyMonitoringReportService } from "src/app/shared/generated/api/daily-monitoring-report.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatIcon } from "@angular/material/icon";
import { MatCard, MatCardHeader, MatCardTitle, MatCardSubtitle } from "@angular/material/card";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";

@Component({
    selector: "daily-monitoring-report-communications",
    templateUrl: "./daily-monitoring-report-communications.component.html",
    styleUrls: ["./daily-monitoring-report-communications.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, MatCard, MatCardHeader, MatIcon, MatCardTitle, MatCardSubtitle, AsyncPipe],
})
export class DailyMonitoringReportCommunicationsComponent implements OnInit {
    @Input() dailyMonitoringReport: any;

    public communications$: Observable<any[]>;

    constructor(
        private dailyMonitoringReportService: DailyMonitoringReportService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        const dailyMonitoringReportID = this.dailyMonitoringReport["_record_id"];
        this.communications$ = this.dailyMonitoringReportService
            .dailyMonitoringReportsDailyMonitoringReportRecordIDCommunicationsGet(dailyMonitoringReportID)
            .pipe(
                map((comms) => {
                    comms.sort((a, b) => {
                        return a["time"].localeCompare(b["time"]);
                    });
                    this.cdr.markForCheck();
                    return comms;
                })
            );
    }
}
