<div style="width: fit-content; margin-left: auto; margin-right: auto; padding-top: 5rem">
    @if (currentModule$ | async; as currentModule) {
        @if (currentUser$ | async; as currentUser) {
            @if (currentUser.Tenants.length == 0) {
                You do not have access to any tenants. Please contact your administrator to gain access.
            }
        } @else {
            <h2>Loading...</h2>
        }
    } @else {
        <h2>Loading...</h2>
    }
</div>
