<div class="details-layout">
    <section class="sidebar">
        <layout-sidebar-nav [sideNavGroups]="sourceDocumentSideNav" [sidebarHeader]="sidebarHeader"></layout-sidebar-nav>

        <ng-template #sidebarHeader>
            @if (sourceDocument$ | async; as sourceDocument) {
                <h2>{{ sourceDocument.Name }}</h2>
                <pill>{{ sourceDocument.DateOfLatestAmendment | date }}</pill>
            }
        </ng-template>
    </section>
    <section class="outlet">
        <router-outlet />
    </section>
</div>
