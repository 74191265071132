<div class="communications-wrapper" *ngIf="communications$ | async as communications">
    <h2 class="section-header">
        COMMUNICATIONS <span>({{ communications.length }} TOTAL)</span>
    </h2>

    <mat-card *ngFor="let communication of communications" style="margin-bottom: 1rem">
        <mat-card-header>
            <div style="margin-right: 1rem; align-self: center">
                <mat-icon style="color: #04608d">mode_comment</mat-icon>
            </div>
            <div style="width: 100%">
                <mat-card-title matTooltipShowDelay="500" matTooltipPosition="above">
                    {{ communication["time"] }} - {{ communication["contacted"] }}
                </mat-card-title>
                <mat-card-subtitle style="margin-bottom: 8px">
                    <div style="text-wrap: wrap">
                        {{ communication["discussed"] }}
                    </div>
                </mat-card-subtitle>
            </div>
        </mat-card-header>
    </mat-card>

    <div *ngIf="communications.length < 1">No Communications found.</div>
</div>
