import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { MatButton } from "@angular/material/button";
import { CustomRichTextComponent } from "../../shared/components/custom-rich-text/custom-rich-text.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { ComplianceTrackingService } from "src/app/shared/generated/api/compliance-tracking.service";
import { Observable } from "rxjs";
import { ComplianceTrackingSummaryDto } from "src/app/shared/generated/model/compliance-tracking-summary-dto";
import { AsyncPipe } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { ProjectSummaryComponent } from "src/app/shared/components/project-summary/project-summary.component";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";

@Component({
    selector: "compliance-tracking-home",
    templateUrl: "./compliance-tracking-home.component.html",
    styleUrls: ["./compliance-tracking-home.component.scss"],
    standalone: true,
    imports: [MatButton, RouterLink, CustomRichTextComponent, AsyncPipe, MatIcon, ProjectSummaryComponent, LoadingSpinnerComponent],
})
export class ComplianceTrackingHomeComponent {
    CustomRichTextTypeEnum = CustomRichTextTypeEnum;
    constructor(
        private router: Router,
        private complianceTrackingService: ComplianceTrackingService
    ) {}

    public summary$: Observable<ComplianceTrackingSummaryDto> = this.complianceTrackingService.complianceTrackingSummaryGet();
}
