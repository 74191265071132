import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { ComplianceRequirementTagService } from "src/app/shared/generated/api/compliance-requirement-tag.service";
import { ComplianceRequirementService } from "src/app/shared/generated/api/compliance-requirement.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { ComplianceRequirementUpsertDto } from "src/app/shared/generated/model/compliance-requirement-upsert-dto";
import { VComplianceRequirementTagDto } from "src/app/shared/generated/model/v-compliance-requirement-tag-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { ComplianceRequirementFormComponent } from "../compliance-requirement-for-commitment-list/form/compliance-requirement-form.component";
import { CurrentComplianceRequirementService } from "src/app/services/current-compliance-requirement";
import { NgIf, AsyncPipe } from "@angular/common";

@Component({
    selector: "compliance-requirement-create",
    templateUrl: "./compliance-requirement-create.component.html",
    styleUrls: ["./compliance-requirement-create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ComplianceRequirementFormComponent, AsyncPipe],
})
export class ComplianceRequirementCreateComponent implements OnInit {
    @ViewChild("createNewComplianceRequirementForm", { read: ComplianceRequirementFormComponent }) form: ComplianceRequirementFormComponent;

    public allComplianceRequirementTags$: Observable<VComplianceRequirementTagDto[]>;
    public commitment$: Observable<any>;

    public model: ComplianceRequirementUpsertDto = new ComplianceRequirementUpsertDto();
    public commitment: CommitmentDto;
    private complianceResponse: any;

    constructor(
        private complianceRequirementService: ComplianceRequirementService,
        private vComplianceRequirementTagService: ComplianceRequirementTagService,
        private currentCommitmentService: CurrentCommitmentService,
        private currentComplianceReqService: CurrentComplianceRequirementService,
        private alertService: AlertService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.model = this.currentComplianceReqService.createComplianceRequirementDto({});

        this.commitment$ = this.currentCommitmentService.getCurrentCommitment().pipe(
            map((data) => {
                this.commitment = data;
                return data;
            })
        );

        this.allComplianceRequirementTags$ = this.vComplianceRequirementTagService.complianceRequirementTagsGet();
    }

    save(form: NgForm): void {
        this.complianceRequirementService.commitmentsCommitmentIDComplianceRequirementsPost(this.commitment.CommitmentID, this.form.model).subscribe(
            (response) => {
                this.complianceResponse = response;
                this.currentCommitmentService.setCurrentCommitment(this.commitment);
            },
            (error) => {
                this.cdr.detectChanges();
            },
            () => {
                this.router
                    .navigateByUrl(
                        `/commitment-library/commitments/${this.commitment.CommitmentID}/compliance-requirements?activeComplianceRequirementID=${this.complianceResponse.ComplianceRequirementID}`
                    )
                    .then(() => {
                        this.alertService.pushAlert(new Alert("The compliance requirement was successfully created.", AlertContext.Success), 5000);
                    });
            }
        );
    }

    canExit() {
        if (this.complianceResponse) {
            return true;
        } else if (this.form && this.form.model) {
            return JSON.stringify(this.model) === JSON.stringify(this.form.model);
        }
        return true;
    }

    cancelEdit() {
        this.router.navigateByUrl(`/commitment-library/commitments/${this.commitment.CommitmentID}/compliance-requirements`);
    }
}
