import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { DailyMonitoringReportService } from "src/app/shared/generated/api/daily-monitoring-report.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "daily-monitoring-report-list",
    templateUrl: "./daily-monitoring-report-list.component.html",
    styleUrls: ["./daily-monitoring-report-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ClearGridFiltersButtonComponent, AgGridAngular, GridActionsComponent],
})
export class DailyMonitoringReportListComponent implements OnInit {
    @ViewChild("dailyMonitoringReportsGrid") dailyMonitoringReportsGrid: AgGridAngular;

    public currentUser: UserDto;
    public dailyMonitoringReports: any[];

    public rowData = [];
    public columnDefs: ColDef[];

    user: Subscription;
    getDaily: Subscription;

    constructor(
        private dailyMonitoringReportService: DailyMonitoringReportService,
        private dateColumnCreatorService: DateColumnCreatorService,
        private cdr: ChangeDetectorRef,
        private utilityFunctionService: UtilityFunctionsService,
        private gridCommonService: GridCommonService
    ) {
        var dateColumn = this.dateColumnCreatorService.createDateColumnDef("Report Date", "date_general", "MM/dd/YYYY", 1, "desc", "UTC");
        this.columnDefs = [
            {
                headerName: "Field Person",
                headerTooltip: "Field Person",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data["_record_id"],
                        LinkDisplay: params.data["field_person"],
                    };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/daily-monitoring-reports/"),
                comparator: this.utilityFunctionService.linkRendererComparator,
                filter: true,
                filterValueGetter: function (params: any) {
                    return params.data["field_person"];
                },
                flex: 1,
            },
            dateColumn,
            {
                headerName: "Discipline",
                headerTooltip: "Discipline",
                field: "discipline",
                tooltipField: "discipline",
                flex: 1,
            },
        ];
    }

    ngOnInit(): void {}

    onDailyMonitoringReportsGridReady(gridEvent) {
        this.dailyMonitoringReportsGrid.api.showLoadingOverlay();

        this.dailyMonitoringReportService.dailyMonitoringReportsGet().subscribe((results) => {
            this.dailyMonitoringReports = results;
            this.rowData = results;
            this.cdr.markForCheck();
        });
    }

    setDefaultFilter() {
        this.dailyMonitoringReportsGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }
}
