<div class="project-summary">
    <div class="project-summary__header flex-between">
        <div class="flex-start gap">
            <a [routerLink]="['projects', project.ProjectID]">
                <h2><beacon-entity-icon entity="Project" matTooltip="Project" /> {{ project.Name }}</h2>
            </a>
            <pill matTooltip="Start Date" backgroundColor="rgb(0 0 0 / 15%)" color="#000">Start Date: {{ project.StartDate | date }}</pill>
            <pill matTooltip="Start Date" backgroundColor="rgb(0 0 0 / 15%)" color="#000">End Date: {{ project.EndDate | date }}</pill>
        </div>
        <div>
            <button
                mat-icon-button
                [loadingButton]="isLoading"
                (click)="downloadCoversheet()"
                class="small-icon-button"
                matTooltip="Download Project Coversheet">
                <mat-icon>list_alt_check</mat-icon>
            </button>
        </div>
    </div>

    <div [innerHTML]="project.Description"></div>

    <hr />
    @if (moduleType == "compliance-tracking") {
        <h3>Components</h3>
        <div class="components">
            @if (project.Components.length > 0) {
                @for (component of project.Components; track $index) {
                    <component-summary [component]="component"></component-summary>
                }
            } @else {
                <em>There are no components for this project.</em>
            }
        </div>
    }
    @if (moduleType == "commitment-library") {
        <h3>Source Documents</h3>
        <div class="sources">
            @if (project.Sources.length > 0) {
                @for (source of project.Sources; track $index) {
                    <source-summary [source]="source"></source-summary>
                }
            } @else {
                <em>There are no Source Documents for this project.</em>
            }
        </div>
    }
</div>
