import { CommonModule } from "@angular/common";
import { Component, inject, OnDestroy } from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { combineLatest, switchMap, of, tap } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { INavGroup, LayoutSidebarNavComponent } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";
import { SidebarNavComponent } from "src/app/shared/components/sidebar-nav/sidebar-nav.component";
import { PillComponent } from "../../shared/components/pill/pill.component";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";
import { SourceService } from "src/app/shared/generated/api/source.service";

@Component({
    selector: "source-document-details-layout",
    standalone: true,
    imports: [RouterOutlet, SidebarNavComponent, LayoutSidebarNavComponent, CommonModule, PillComponent],
    templateUrl: "./source-document-details-layout.component.html",
    styleUrl: "./source-document-details-layout.component.scss",
})
export class SourceDocumentDetailsLayoutComponent implements OnDestroy {
    ngOnDestroy(): void {
        this.sourceDocumentUpdatedService.setCurrentSourceDocument(null);
    }

    private sourceDocumentUpdatedService: CurrentSourceDocumentService = inject(CurrentSourceDocumentService);
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private sourceDocumentService: SourceService = inject(SourceService);
    public sourceDocumentCount: number;
    public componentCount: number;
    public commitmentCount: number;
    public evidenceOfComplianceCount: number;
    public sourceDocumentSideNav: INavGroup[] = [];

    sourceDocument$ = combineLatest([this.activatedRoute.paramMap, this.sourceDocumentUpdatedService.getCurrentSourceDocument()]).pipe(
        switchMap(([paramMap, updatedSourceDocument]) => {
            let sourceDocumentChange = paramMap.get(routeParams.sourceDocumentID).toLowerCase() != updatedSourceDocument?.SourceID.toLowerCase();
            if (updatedSourceDocument && !sourceDocumentChange) {
                return of(updatedSourceDocument);
            }
            return this.sourceDocumentService.sourcesSourceIDGet(paramMap.get(routeParams.sourceDocumentID)).pipe(
                tap((x) => {
                    this.sourceDocumentUpdatedService.setCurrentSourceDocument(x);
                })
            );
        }),
        tap((sourceDocument) => {
            this.sourceDocumentSideNav = [
                {
                    Items: [
                        {
                            Title: "Source Document Summary",
                            RouterLink: "./",
                        },
                    ],
                },
                {
                    Title: "Commitment Library",
                    Items: [
                        {
                            Title: "Commitments",
                            RouterLink: "related-commitments",
                            Icon: "Commitment",
                            ItemCount: sourceDocument.RelatedCommitmentCount,
                        },
                    ],
                },
            ];
        })
    );
}
