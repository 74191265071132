import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { NgIf, NgFor } from "@angular/common";
import { CustomFormLabelComponent } from "../../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { FormsModule } from "@angular/forms";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { CommitmentVersionWorkflowService } from "src/app/shared/generated/api/commitment-version-workflow.service";
import { KvPairComponent } from "../../../shared/components/kv-pair/kv-pair.component";
import { SimpleFileDisplayComponent } from "../../../shared/components/simple-file-display/simple-file-display.component";
import { FileDropUploadComponent } from "../../../shared/components/file-drop-upload/file-drop-upload.component";

@Component({
    selector: "version-dialog",
    templateUrl: "./version-dialog.component.html",
    styleUrls: ["./version-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        FormsModule,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        TinymceEditorComponent,
        MatButton,
        MatIcon,
        NgIf,
        NgFor,
        MatIconButton,
        MatDialogActions,
        MatTooltip,
        KvPairComponent,
        SimpleFileDisplayComponent,
        FileDropUploadComponent,
    ],
})
export class VersionDialogComponent implements OnInit {
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    public originatorOfChange: string;
    public summaryOfChange: string;
    public filesToUpload: File[] = [];

    //MK 3/29/2023 -- After a lot of trial and error finally got the popperjs to work in a material dialog for field-definitions.
    // I know its janky but it seems to work.
    public popperOptions?: any = {
        placement: "bottom",
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: ({ placement, reference, popper }) => {
                        return [0, -150];
                    },
                },
            },
        ],
    };

    public isLoading: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<VersionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private alertService: AlertService,
        private commitmentVersionWorkflowService: CommitmentVersionWorkflowService
    ) {}

    ngOnInit(): void {}

    onFileUpload(files: File[]) {
        files.forEach((file) => {
            this.filesToUpload.push(file);
        });
    }

    deleteNewFile(file: File) {
        const index = this.filesToUpload.findIndex((f: File) => f === file);
        if (index !== -1) {
            this.filesToUpload.splice(index, 1);
        }
    }

    version() {
        this.commitmentVersionWorkflowService
            .commitmentsCommitmentIDVersionPost(this.data.CommitmentID, this.originatorOfChange, this.summaryOfChange, this.filesToUpload)
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.dialogRef.close(result);
                        this.alertService.pushAlert(new Alert("Successfully versioned Commitment.", AlertContext.Success));
                    }
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
    }

    cancel() {
        this.dialogRef.close();
    }
}
