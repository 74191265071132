<div class="key-value-pair">
    <div class="key-value-pair__key">{{ key }}</div>
    <div class="key-value-pair__value">
        @if (value != null && value != undefined && value) {
            @if (isTemplateRef) {
                <ng-container *ngTemplateOutlet="templateRef"></ng-container>
            } @else {
                @if (isHtmlString) {
                    <div [innerHTML]="domSanitizer.sanitize(securityContext, value)">{{ value }}</div>
                } @else {
                    <div>{{ value }}</div>
                }
            }
        } @else {
            <em>{{ nullValue }}</em>
        }
        <ng-content />
    </div>
</div>
