import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs/internal/Subscription";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto, TenantDto, EvidenceOfComplianceGridDto } from "src/app/shared/generated/model/models";
import { TenantService } from "src/app/shared/services/tenant/tenant-service.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { NgIf } from "@angular/common";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { EvidenceOfComplianceService } from "src/app/shared/generated/api/evidence-of-compliance.service";

@Component({
    selector: "evidence-of-compliance-list",
    templateUrl: "./evidence-of-compliance-list.component.html",
    styleUrls: ["./evidence-of-compliance-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, MatButton, MatIcon, AgGridAngular, GridActionsComponent],
})
export class EvidenceOfComplianceListComponent implements OnInit, OnDestroy {
    @ViewChild("evidenceOfComplianceGrid") evidenceOfComplianceGrid: AgGridAngular;

    public currentUser: UserDto;
    public evidencesOfCompliance: EvidenceOfComplianceGridDto[];

    public rowData = [];
    public columnDefs: ColDef[];

    public currentTenant: TenantDto;

    user: Subscription;
    getEvidencesOfComplianceRequest: Subscription;

    constructor(
        private evidenceOfComplianceService: EvidenceOfComplianceService,
        private tenantService: TenantService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute
    ) {
        this.columnDefs = [
            {
                headerName: "ID",
                field: "EvidenceOfComplianceID",
                flex: 2,
                sort: "asc",
            },
            {
                headerName: "Notes",
                field: "Notes",
                flex: 2,
            },
            {
                headerName: "# Of Files",
                field: "NumberOfFiles",
                flex: 1.5,
            },
            {
                headerName: "Project",
                headerTooltip: "Project Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ProjectID,
                        LinkDisplay: params.data.ProjectName,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/compliance-tracking/projects" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ProjectName;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 175,
            },
            {
                headerName: "Component",
                headerTooltip: "Component Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.ComponentID,
                        LinkDisplay: params.data.ComponentName,
                        ChildRoute: "summary-information",
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: function (params: any) {
                    return { inRouterLink: `/compliance-tracking/projects/${params.data.ProjectID}/components` };
                },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.ComponentName;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 175,
            },
            {
                headerName: "Compliance Requirement",
                headerTooltip: "Compliance Requirement Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentID,
                        LinkDisplay: params.data.ComplianceRequirementName,
                        ChildRoute: "compliance-requirements",
                        QueryParams: {
                            activeComplianceRequirementID: params.data.ComplianceRequirementID,
                        },
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitment-library/commitments/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.Name;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 250,
                autoHeight: true,
            },
            {
                headerName: "Commitment",
                headerTooltip: "Commitment ID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CommitmentID,
                        LinkDisplay: params.data.CommitmentName,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitment-library/commitments/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.CommitmentName;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 175,
            },
            {
                headerName: "Source Document",
                headerTooltip: "Source Document Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.SourceID,
                        LinkDisplay: params.data.SourceName,
                    };
                },
                cellRenderer: LinkRendererComponent,
                cellRendererParams: { inRouterLink: "/commitment-library/source-documents/" },
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.SourceName;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                width: 200,
            },
        ];

        this.tenantService.currentTenant$.subscribe((currentTenant) => {
            this.currentTenant = currentTenant;
            this.cdr.markForCheck();
        });
    }

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.getEvidencesOfComplianceRequest?.unsubscribe();
    }

    onEvidencesOfComplianceGridReady(gridEvent) {
        this.evidenceOfComplianceGrid.api.showLoadingOverlay();

        this.getEvidencesOfComplianceRequest = this.evidenceOfComplianceService.evidenceOfComplianceGet().subscribe((results) => {
            this.evidencesOfCompliance = results;
            this.rowData = results;
            this.evidenceOfComplianceGrid.api.hideOverlay();
            this.cdr.markForCheck();
        });
    }
}
