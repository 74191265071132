<div class="evidence-of-compliance-upsert-dialog">
    @if (isLoading) {
    <beacon-loading-overlay>
        <loading-spinner [strokeWidth]="5" [diameter]="48"></loading-spinner>
        Saving Evidence of Compliance... Please be patient when uploading large files.
    </beacon-loading-overlay>
    }

    <h1 mat-dialog-title>
        {{ data.EvidenceOfCompliance.EvidenceOfComplianceID ? "Edit Evidence of Compliance" : "Add Evidence of Compliance" }}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <div class="form-group">
                <esa-material-ff name="Title" [editMode]="true" [required]="true" type="text" label="Title" formControlName="Title"> </esa-material-ff>
            </div>
            <div class="form-group">
                <esa-material-ff name="Notes" [editMode]="true" [required]="false" type="textarea" label="Notes" formControlName="Notes"> </esa-material-ff>
            </div>
            <div class="form-group">
                <kv-pair key="Files" [value]="fileDropper">
                    <ng-template #fileDropper>
                        <file-drop-upload uploadFileAccepts=".pdf,.csv,.xlsx" [multiple]="true" (filesChanged)="onFileUpload($event)"></file-drop-upload>
                        <ul class="file-max-height">
                            <li *ngFor="let file of formGroup.controls.NewEvidenceOfComplianceFiles.value">
                                <simple-file-display
                                    [isNotYetUploaded]="true"
                                    [fileName]="file.name"
                                    [displayDelete]="true"
                                    (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            </li>
                            <li *ngFor="let file of formGroup.controls.EvidenceOfComplianceFiles.value">
                                <simple-file-display
                                    [fileName]="file.Name"
                                    [displayDelete]="true"
                                    [downloadUrl]="'evidence-of-compliance-file/' + file.EvidenceOfComplianceFileID"
                                    (deleteFile)="deleteExistingFile(file)"></simple-file-display>
                            </li>
                        </ul>
                    </ng-template>
                </kv-pair>
            </div>
        </form>

        <alert-box [alertContext]="AlertContext.Danger" *ngIf="duplicateFileNames.length > 0" [alertContext]="AlertContext.Danger"
            ><strong>Warning:</strong> You are uploading {{ duplicateFileNames.length }} file(s) that already exist. Are you sure you want to override the
            following files? {{ duplicateFileNames.join(", ") }}</alert-box
        >

        <alert-box
            [alertContext]="AlertContext.Danger"
            *ngIf="data.EvidenceOfCompliance.EvidenceOfComplianceChecklistItems?.length > 1"
            class="warning__relatedList">
            <strong>Warning:</strong> This Evidence is shared with {{ data.EvidenceOfCompliance.EvidenceOfComplianceChecklistItems?.length - 1 }} other
            Checklist Items.
            <ul>
                @for (relatedItem of data.EvidenceOfCompliance.EvidenceOfComplianceChecklistItems; track relatedItem) {
                <li *ngIf="relatedItem.ChecklistItemID !== data.ChecklistItemID">
                    <a target="_blank" [routerLink]="[currentRoute, relatedItem.ChecklistItemID]">{{ relatedItem.ComplianceRequirementName }}</a>
                </li>
                }
            </ul>
            Changes made here will affect ALL {{ data.EvidenceOfCompliance.EvidenceOfComplianceChecklistItems?.length - 1 }} related Checklist Items.
        </alert-box>
    </div>

    <div mat-dialog-actions align="end">
        <span [matTooltip]="!formGroup.valid ? 'You are missing required fields.' : ''">
            <button mat-flat-button color="primary" style="margin-right: 1rem" (click)="onSave()" cdkFocusInitial [disabled]="!formGroup.valid || isLoading">
                Save
            </button>
        </span>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
