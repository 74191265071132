/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BadRequestResult } from '../model/bad-request-result';
import { NotFoundResult } from '../model/not-found-result';
import { SourceFileDto } from '../model/source-file-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../../services';
import { TenantService } from '../../services/tenant/tenant-service.service';


@Injectable({
  providedIn: 'root'
})
export class SourceFileService {

    protected basePath = 'http://localhost';
    protected unmodifiedBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService, private tenantService: TenantService) {
        if (basePath) {
            this.basePath = basePath;
            this.unmodifiedBasePath = basePath;
        }

        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
            this.unmodifiedBasePath = basePath || configuration.basePath || this.basePath;
        }

        tenantService.currentTenant$.subscribe(tenant => {
            this.setBasePath(tenant);
        });
    }

    private setBasePath(tenant) {
        if (tenant && tenant.SubDomain) {
            let schemalessBasePath = this.unmodifiedBasePath.replace("https://", "").replace("http://", "");
            this.basePath = `https://${tenant.SubDomain}.${schemalessBasePath}`;
        } else {                
            this.basePath = this.unmodifiedBasePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param sourceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesSourceIDFilesGet(sourceID: string, observe?: 'body', reportProgress?: boolean): Observable<Array<SourceFileDto>>;
    public sourcesSourceIDFilesGet(sourceID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SourceFileDto>>>;
    public sourcesSourceIDFilesGet(sourceID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SourceFileDto>>>;
    public sourcesSourceIDFilesGet(sourceID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling sourcesSourceIDFilesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SourceFileService")){
            let serviceRequest = this.httpClient.get<Array<SourceFileDto>>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<SourceFileDto>>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param sourceID 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesSourceIDFilesPost(sourceID: string, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<SourceFileDto>>;
    public sourcesSourceIDFilesPost(sourceID: string, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SourceFileDto>>>;
    public sourcesSourceIDFilesPost(sourceID: string, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SourceFileDto>>>;
    public sourcesSourceIDFilesPost(sourceID: string, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling sourcesSourceIDFilesPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (files !== undefined) {
            for (let i = 0; i < files.length; i++) {
                formParams = formParams.append('files', <any>files[i]) || formParams;
            }
        }

        if(this.tenantService.excludedServiceNames.includes("SourceFileService")){
            let serviceRequest = this.httpClient.post<Array<SourceFileDto>>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files`,
                convertFormParamsToString ? formParams.toString() : formParams,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.post<Array<SourceFileDto>>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files`,
                    convertFormParamsToString ? formParams.toString() : formParams,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param sourceID 
     * @param sourceFileID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesSourceIDFilesSourceFileIDDelete(sourceID: string, sourceFileID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sourcesSourceIDFilesSourceFileIDDelete(sourceID: string, sourceFileID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sourcesSourceIDFilesSourceFileIDDelete(sourceID: string, sourceFileID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sourcesSourceIDFilesSourceFileIDDelete(sourceID: string, sourceFileID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling sourcesSourceIDFilesSourceFileIDDelete.');
        }

        if (sourceFileID === null || sourceFileID === undefined) {
            throw new Error('Required parameter sourceFileID was null or undefined when calling sourcesSourceIDFilesSourceFileIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SourceFileService")){
            let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files/${encodeURIComponent(String(sourceFileID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files/${encodeURIComponent(String(sourceFileID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param sourceID 
     * @param sourceFileID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesSourceIDFilesSourceFileIDDownloadGet(sourceID: string, sourceFileID: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public sourcesSourceIDFilesSourceFileIDDownloadGet(sourceID: string, sourceFileID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public sourcesSourceIDFilesSourceFileIDDownloadGet(sourceID: string, sourceFileID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public sourcesSourceIDFilesSourceFileIDDownloadGet(sourceID: string, sourceFileID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling sourcesSourceIDFilesSourceFileIDDownloadGet.');
        }

        if (sourceFileID === null || sourceFileID === undefined) {
            throw new Error('Required parameter sourceFileID was null or undefined when calling sourcesSourceIDFilesSourceFileIDDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SourceFileService")){
            let serviceRequest = this.httpClient.get(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files/${encodeURIComponent(String(sourceFileID))}/download`,
                {
                    responseType: "blob",
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files/${encodeURIComponent(String(sourceFileID))}/download`,
                    {
                        responseType: "blob",
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param sourceID 
     * @param sourceFileID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sourcesSourceIDFilesSourceFileIDGet(sourceID: string, sourceFileID: string, observe?: 'body', reportProgress?: boolean): Observable<SourceFileDto>;
    public sourcesSourceIDFilesSourceFileIDGet(sourceID: string, sourceFileID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SourceFileDto>>;
    public sourcesSourceIDFilesSourceFileIDGet(sourceID: string, sourceFileID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SourceFileDto>>;
    public sourcesSourceIDFilesSourceFileIDGet(sourceID: string, sourceFileID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling sourcesSourceIDFilesSourceFileIDGet.');
        }

        if (sourceFileID === null || sourceFileID === undefined) {
            throw new Error('Required parameter sourceFileID was null or undefined when calling sourcesSourceIDFilesSourceFileIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("SourceFileService")){
            let serviceRequest = this.httpClient.get<SourceFileDto>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files/${encodeURIComponent(String(sourceFileID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<SourceFileDto>(`${this.basePath}/sources/${encodeURIComponent(String(sourceID))}/files/${encodeURIComponent(String(sourceFileID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

}
