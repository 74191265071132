import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { ColDef, SortDirection } from "ag-grid-community";

@Injectable({
    providedIn: "root",
})
export class DateColumnCreatorService {
    constructor() {}

    public createDateColumnDef(
        headerName: string,
        fieldName: string,
        dateFormat: string,
        flex: number = 1,
        sort: SortDirection = null,
        timeZone: string = null
    ): ColDef {
        let datePipe = new DatePipe("en-US");

        return {
            headerName: headerName,
            valueGetter: function (params: any) {
                return datePipe.transform(params.data[fieldName], dateFormat, timeZone);
            },
            filter: "agDateColumnFilter",
            filterParams: {
                filterOptions: ["inRange"],
                comparator: this.dateFilterComparator,
            },
            flex: flex,
            comparator: function (dateA, dateB, nodeA, nodeB, isDescending) {
                let valueA = Date.parse(dateA);
                let valueB = Date.parse(dateB);

                return valueA - valueB;
            },
            sort: sort,
        };
    }

    private dateFilterComparator(filterLocalDateAtMidnight, cellValue) {
        const cellDate = Date.parse(cellValue);
        if (cellDate == filterLocalDateAtMidnight) {
            return 0;
        }

        return cellDate < filterLocalDateAtMidnight ? -1 : 1;
    }
}
