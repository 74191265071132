import { Component, OnInit, Input, inject } from "@angular/core";
import { RouterLinkActive, RouterLink, RouterOutlet } from "@angular/router";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { IWithPermission, WithPermissionPipe } from "../../pipes/with-permission/with-permission.pipe";
import { AuthenticationService } from "src/app/services/authentication.service";
import { shareReplay } from "rxjs";
import { INavItem } from "../layout-sidebar-nav/layout-sidebar-nav.component";

@Component({
    selector: "sidebar-nav",
    templateUrl: "./sidebar-nav.component.html",
    styleUrls: ["./sidebar-nav.component.scss"],
    standalone: true,
    imports: [NgFor, RouterLinkActive, RouterLink, NgIf, RouterOutlet, AsyncPipe, WithPermissionPipe],
})
export class SidebarNavComponent implements OnInit {
    @Input() sideNavItems: INavItem[] = [];
    public authenticationService = inject(AuthenticationService);
    currentUser$ = this.authenticationService.getCurrentUser().pipe(shareReplay(1));
    constructor() {}

    ngOnInit(): void {}
}
