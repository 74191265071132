<div class="project-upsert-dialog">
    @if (isLoading) {
    <beacon-loading-overlay>
        <loading-spinner [strokeWidth]="5" [diameter]="48"></loading-spinner>
        Saving Project... Please be patient when uploading large files.
    </beacon-loading-overlay>
    }

    <h1 mat-dialog-title>
        {{ data.Project?.ProjectID ? "Edit Project" : "Add Project" }}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <esa-material-ff name="Name" [editMode]="true" [required]="true" type="text" label="Name" formControlName="Name"> </esa-material-ff>
            <div class="form-group flex-start gap">
                <custom-form-label label="Start Date"></custom-form-label>
                <mat-form-field class="value" appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startDatePicker" formControlName="StartDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="form-group flex-start gap">
                <custom-form-label label="End Date"></custom-form-label>
                <mat-form-field class="value" appearance="fill">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endDatePicker" formControlName="EndDate" />
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="form-group flex-between gap">
                <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
                <div class="value">
                    <div class="grid-12">
                        <div class="g-col-12">
                            <editor class="mb-2" #tinyMceEditor [init]="tinyMceEditor | tinyMceConfig" formControlName="Description"></editor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <kv-pair key="Files" [value]="fileDropper">
                    <ng-template #fileDropper>
                        <file-drop-upload uploadFileAccepts=".pdf,.csv,.xlsx" [multiple]="true" (filesChanged)="onFileUpload($event)"></file-drop-upload>
                        <ul class="file-max-height">
                            <li *ngFor="let file of formGroup.controls.NewProjectFiles.value">
                                <simple-file-display
                                    [isNotYetUploaded]="true"
                                    [fileName]="file.name"
                                    [displayDelete]="true"
                                    (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            </li>
                            <li *ngFor="let file of formGroup.controls.ProjectFiles.value">
                                <simple-file-display
                                    [fileName]="file.Name"
                                    [displayDelete]="true"
                                    [downloadUrl]="'projects/' + data.Project.ProjectID + '/files/' + file.ProjectFileID"
                                    (deleteFile)="deleteExistingFile(file)"></simple-file-display>
                            </li>
                        </ul>
                    </ng-template>
                </kv-pair>
            </div>
        </form>

        <alert-box [alertContext]="AlertContext.Danger" *ngIf="duplicateFileNames.length > 0" [alertContext]="AlertContext.Danger"
            ><strong>Warning:</strong> You are uploading {{ duplicateFileNames.length }} file(s) that already exist. Are you sure you want to override the
            following files? {{ duplicateFileNames.join(", ") }}</alert-box
        >
    </div>

    <div mat-dialog-actions align="end">
        <span [matTooltip]="!formGroup.valid ? 'You are missing required fields.' : ''">
            <button mat-flat-button color="primary" style="margin-right: 1rem" (click)="onSave()" cdkFocusInitial [disabled]="!formGroup.valid || isLoading">
                Save
            </button>
        </span>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
