import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogModule } from "@angular/material/dialog";
import { combineLatest, forkJoin, Observable, Subscription } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";
import { ChecklistService } from "src/app/shared/generated/api/checklist.service";
import { ChecklistItemDto } from "src/app/shared/generated/model/checklist-item-dto";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, NgModel, ReactiveFormsModule } from "@angular/forms";
import { EvidenceOfComplianceService } from "src/app/shared/generated/api/evidence-of-compliance.service";
import { MatLabel, MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatFormField } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { KvPairComponent } from "../../kv-pair/kv-pair.component";
import { BeaconLoadingOverlayComponent } from "../../beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "../../loading-spinner/loading-spinner.component";
import { EvidenceOfComplianceChecklistItemDto } from "src/app/shared/generated/model/evidence-of-compliance-checklist-item-dto";
import { MatOptionModule } from "@angular/material/core";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { ChecklistItemStatusDto } from "src/app/shared/generated/model/checklist-item-status-dto";
import { ComplianceRequirementTypeDto } from "src/app/shared/generated/model/compliance-requirement-type-dto";
import { FrequencyDto } from "src/app/shared/generated/model/frequency-dto";
import { PhaseDto } from "src/app/shared/generated/model/phase-dto";
import { ResourceCategoryDto } from "src/app/shared/generated/model/resource-category-dto";
import { ScopeDto } from "src/app/shared/generated/model/scope-dto";
import { ComplianceRequirementTypeService } from "src/app/shared/generated/api/compliance-requirement-type.service";
import { FrequencyService } from "src/app/shared/generated/api/frequency.service";
import { PhaseService } from "src/app/shared/generated/api/phase.service";
import { ResourceCategoryService } from "src/app/shared/generated/api/resource-category.service";
import { ScopeService } from "src/app/shared/generated/api/scope.service";
import { MatCheckbox, MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";
import { E } from "@angular/cdk/keycodes";
import { ObjectInArrayPipe } from "src/app/shared/pipes/object-in-array/object-in-array.pipe";
import { ChecklistItemFilterVisiblePipe } from "src/app/shared/pipes/checklist-item-filtered/checklist-item-filtered.pipe";
import { MatInput } from "@angular/material/input";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";

@Component({
    selector: "evidence-of-compliance-associate-to-checklist-items-dialog",
    standalone: true,
    imports: [
        MatFormField,
        AsyncPipe,
        NgFor,
        NgIf,
        MatLabel,
        MatSelectModule,
        MatOptionModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatIcon,
        KvPairComponent,
        BeaconLoadingOverlayComponent,
        LoadingSpinnerComponent,
        MatDialogModule,
        MatDialogContent,
        EsaMaterialFormFieldComponent,
        MatCheckboxModule,
        ObjectInArrayPipe,
        MatInput,
    ],
    providers: [ChecklistItemFilterVisiblePipe],
    templateUrl: "./evidence-of-compliance-associate-to-checklist-items-dialog.component.html",
    styleUrl: "./evidence-of-compliance-associate-to-checklist-items-dialog.component.scss",
})
export class EvidenceOfComplianceAssociateToChecklistItemsDialogComponent implements OnInit, OnDestroy {
    formGroup: FormGroup;
    checklistItems$: Observable<ChecklistItemDto[]>;
    filteredChecklistItems$: Observable<ChecklistItemDto[]>;
    public filterFormGroup: FormGroup = new FormGroup({
        scope: new FormControl([], { nonNullable: true }),
        frequency: new FormControl([], { nonNullable: true }),
        complianceRequirementType: new FormControl([], { nonNullable: true }),
        phase: new FormControl([], { nonNullable: true }),
        resourceCategory: new FormControl([], { nonNullable: true }),
        status: new FormControl([], { nonNullable: true }),
        search: new FormControl("", { nonNullable: true }),
        speciesCode: new FormControl([], { nonNullable: true }),
    });
    public filterOptions$: Observable<{
        scopes: ScopeDto[];
        frequencies: FrequencyDto[];
        complianceRequirementTypes: ComplianceRequirementTypeDto[];
        phases: PhaseDto[];
        resourceCategories: ResourceCategoryDto[];
        statuses: ChecklistItemStatusDto[];
        speciesCodes: string[];
    }>;
    private filterSubscription: Subscription = Subscription.EMPTY;
    public filterActive: boolean = false;

    public selectedChecklistItemIDs: string[] = [];
    public filteredChecklistItems: ChecklistItemDto[] = [];

    constructor(
        public dialogRef: MatDialogRef<EvidenceOfComplianceAssociateToChecklistItemsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentData,
        private checklistService: ChecklistService,
        private evidenceOfComplianceService: EvidenceOfComplianceService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private scopeService: ScopeService,
        private frequencyService: FrequencyService,
        private complianceRequirementTypeService: ComplianceRequirementTypeService,
        private phaseService: PhaseService,
        private resourceCategoryService: ResourceCategoryService,
        private checklistItemVisiblePipe: ChecklistItemFilterVisiblePipe,
        private commitmentService: CommitmentService
    ) {}

    ngOnDestroy(): void {
        this.filterSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            checklistItems: [[]],
        });

        this.checklistItems$ = this.checklistService.componentsComponentIDChecklistItemsGet(this.data.ComponentID).pipe(
            map((checklistItems) => {
                return checklistItems.filter((checklistItem) => {
                    return !this.data.ExistingChecklistItems.some(
                        (existingChecklistItem) => existingChecklistItem.ChecklistItemID === checklistItem.ChecklistItemID
                    );
                });
            })
        );

        this.filteredChecklistItems$ = combineLatest([
            this.checklistItems$,
            this.filterFormGroup.valueChanges.pipe(startWith(this.filterFormGroup.value)),
        ]).pipe(
            map(([items, filterFormValue]) => {
                return items.filter((item) => this.checklistItemVisiblePipe.transform(item, filterFormValue));
            }),
            tap((items) => {
                this.filteredChecklistItems = items;
            })
        );

        this.filterOptions$ = forkJoin([
            this.phaseService.phasesGet(),
            this.scopeService.scopesGet(),
            this.frequencyService.frequenciesGet(),
            this.complianceRequirementTypeService.complianceRequirementTypesGet(),
            this.resourceCategoryService.resourceCategoriesGet(),
            this.checklistService.checklistItemStatusesGet(),
            this.commitmentService.commitmentsSpeciesCodesGet(),
        ]).pipe(
            map(([phases, scopes, frequencies, complianceRequirementTypes, resourceCategories, statuses, speciesCodes]) => {
                return { phases, scopes, frequencies, complianceRequirementTypes, resourceCategories, statuses, speciesCodes };
            })
        );
        this.filterSubscription = this.filterFormGroup.valueChanges.subscribe((event) => {
            this.filterActive = Object.values(event).some((x) => (x !== null && x !== undefined && x !== "" && Array.isArray(x) ? x.length > 0 : false));
        });
    }

    filterDisplayFn(value: ScopeDto | FrequencyDto | ComplianceRequirementTypeDto): string {
        return value?.Name;
    }

    onSave() {
        const response = {
            ...this.formGroup.value,
        } as IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentRequest;
        this.saveEvidence(response);
    }

    saveEvidence(evidenceData: IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentRequest) {
        this.cdr.markForCheck();
        this.evidenceOfComplianceService
            .componentsComponentIDEvidenceOfComplianceEvidenceOfComplianceIDChecklistItemsPost(
                this.data.ComponentID,
                this.data.EvidenceOfComplianceID,
                this.selectedChecklistItemIDs
            )
            .subscribe({
                next: (response) => {
                    const dialogResponse: IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentResponse = {
                        Success: true,
                    };
                    this.dialogRef.close(dialogResponse);
                },
                error: () => {
                    this.cdr.markForCheck();
                },
            });
    }

    clearSelection() {
        this.selectedChecklistItemIDs = [];
        this.cdr.markForCheck();
    }

    selectAll() {
        this.selectedChecklistItemIDs = this.filteredChecklistItems.map((x) => x.ChecklistItemID);
        this.cdr.markForCheck();
    }

    checklistItemSelectionChanged(event: MatCheckboxChange, checklistItemID: string) {
        if (event.checked) {
            this.selectedChecklistItemIDs = [...this.selectedChecklistItemIDs, checklistItemID];
        } else {
            this.selectedChecklistItemIDs = [...this.selectedChecklistItemIDs.filter((x) => x !== checklistItemID)];
        }
        this.cdr.markForCheck();
    }
}

export interface IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentData {
    EvidenceOfComplianceID: string;
    ComponentID: string;
    ExistingChecklistItems: EvidenceOfComplianceChecklistItemDto[];
}

export interface IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentRequest {
    checklistItems: string[];
}

export interface IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentResponse {
    Success: boolean;
}
