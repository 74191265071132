import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ChecklistService } from './api/checklist.service';
import { ChecklistTaskService } from './api/checklist-task.service';
import { CommitmentService } from './api/commitment.service';
import { CommitmentBulkUploadService } from './api/commitment-bulk-upload.service';
import { CommitmentFileService } from './api/commitment-file.service';
import { CommitmentLibraryService } from './api/commitment-library.service';
import { CommitmentRelationshipService } from './api/commitment-relationship.service';
import { CommitmentTypeService } from './api/commitment-type.service';
import { CommitmentVersionService } from './api/commitment-version.service';
import { CommitmentVersionWorkflowService } from './api/commitment-version-workflow.service';
import { ComplianceRequirementService } from './api/compliance-requirement.service';
import { ComplianceRequirementTagService } from './api/compliance-requirement-tag.service';
import { ComplianceRequirementTypeService } from './api/compliance-requirement-type.service';
import { ComplianceTrackingService } from './api/compliance-tracking.service';
import { ComponentService } from './api/component.service';
import { ComponentCommitmentRationaleService } from './api/component-commitment-rationale.service';
import { CustomPageService } from './api/custom-page.service';
import { CustomRichTextService } from './api/custom-rich-text.service';
import { DailyMonitoringReportService } from './api/daily-monitoring-report.service';
import { EvidenceOfComplianceService } from './api/evidence-of-compliance.service';
import { EvidenceOfComplianceFileService } from './api/evidence-of-compliance-file.service';
import { FieldDefinitionService } from './api/field-definition.service';
import { FrequencyService } from './api/frequency.service';
import { FulcrumPhotoService } from './api/fulcrum-photo.service';
import { ImpersonationService } from './api/impersonation.service';
import { ImplementationResponsibilityService } from './api/implementation-responsibility.service';
import { MenuItemService } from './api/menu-item.service';
import { ModuleService } from './api/module.service';
import { PhaseService } from './api/phase.service';
import { ProjectService } from './api/project.service';
import { ProjectCommitmentService } from './api/project-commitment.service';
import { ProjectFileService } from './api/project-file.service';
import { ProjectSourceService } from './api/project-source.service';
import { RelationshipTypeService } from './api/relationship-type.service';
import { ResourceCategoryService } from './api/resource-category.service';
import { RoleService } from './api/role.service';
import { ScopeService } from './api/scope.service';
import { SeasonalityTriggerService } from './api/seasonality-trigger.service';
import { SourceService } from './api/source.service';
import { SourceFileService } from './api/source-file.service';
import { SystemInfoService } from './api/system-info.service';
import { TestSchedulingService } from './api/test-scheduling.service';
import { TrackableService } from './api/trackable.service';
import { UserService } from './api/user.service';
import { UserClaimsService } from './api/user-claims.service';
import { WorkActivityService } from './api/work-activity.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ChecklistService,
    ChecklistTaskService,
    CommitmentService,
    CommitmentBulkUploadService,
    CommitmentFileService,
    CommitmentLibraryService,
    CommitmentRelationshipService,
    CommitmentTypeService,
    CommitmentVersionService,
    CommitmentVersionWorkflowService,
    ComplianceRequirementService,
    ComplianceRequirementTagService,
    ComplianceRequirementTypeService,
    ComplianceTrackingService,
    ComponentService,
    ComponentCommitmentRationaleService,
    CustomPageService,
    CustomRichTextService,
    DailyMonitoringReportService,
    EvidenceOfComplianceService,
    EvidenceOfComplianceFileService,
    FieldDefinitionService,
    FrequencyService,
    FulcrumPhotoService,
    ImpersonationService,
    ImplementationResponsibilityService,
    MenuItemService,
    ModuleService,
    PhaseService,
    ProjectService,
    ProjectCommitmentService,
    ProjectFileService,
    ProjectSourceService,
    RelationshipTypeService,
    ResourceCategoryService,
    RoleService,
    ScopeService,
    SeasonalityTriggerService,
    SourceService,
    SourceFileService,
    SystemInfoService,
    TestSchedulingService,
    TrackableService,
    UserService,
    UserClaimsService,
    WorkActivityService,
     ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
