import { Pipe, PipeTransform, inject } from "@angular/core";
import { UserDto } from "../../generated/model/models";
import { PermissionEnum } from "../../generated/enum/permission-enum";
import { RightsEnum } from "../../models/enums/rights.enum";
import { AuthenticationService } from "src/app/services/authentication.service";

@Pipe({
    name: "withPermission",
    standalone: true,
})
export class WithPermissionPipe implements PipeTransform {
    authenticationService = inject(AuthenticationService);

    transform(user: UserDto, permissions: IWithPermission): boolean {
        if (!user) return false;
        const userHasPermission = this.authenticationService.hasPermission(user, permissions.permission, permissions.rights);
        return userHasPermission;
    }
}

export interface IWithPermission {
    permission: PermissionEnum;
    rights: RightsEnum;
}
