import { NgIf, AsyncPipe, NgFor, CommonModule } from "@angular/common";
import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { RouterLink } from "@angular/router";
import { EsaValueDisplayComponent, EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";
import { CustomFormLabelComponent } from "../../custom-form-label/custom-form-label.component";
import { SeasonalityTriggerDto } from "src/app/shared/generated/model/models";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { FileDropUploadComponent } from "../../file-drop-upload/file-drop-upload.component";
import { SimpleFileDisplayComponent } from "../../simple-file-display/simple-file-display.component";
import { KvPairComponent } from "../../kv-pair/kv-pair.component";
import { MatSelectModule } from "@angular/material/select";
import { SeasonalityTriggerComponent } from "../../seasonality-trigger/seasonality-trigger.component";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, provideMomentDateAdapter } from "@angular/material-moment-adapter";
import { MatCalendarHeader, MatDatepicker, MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from "@angular/material/core";
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { MatInputModule } from "@angular/material/input";
import { ExampleHeader, MONTH_DAY_FORMATS } from "../../custom-date-adapter/custom-date-adapter";

const moment = _rollupMoment || _moment;

@Component({
    selector: "seasonality-trigger-upsert-dialog",
    standalone: true,
    imports: [
        MatTooltip,
        KvPairComponent,
        SeasonalityTriggerComponent,
        MatDialogContent,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        MatDialogTitle,
        NgIf,
        MatSelectModule,
        NgFor,
        AsyncPipe,
        MatButton,
        EsaValueDisplayComponent,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        RouterLink,
        BypassHtmlPipe,
        ReactiveFormsModule,
        FileDropUploadComponent,
        SimpleFileDisplayComponent,
        CommonModule,
        MatDialogModule,
        MatDatepickerModule,
        MatInputModule,
        MatCalendarHeader,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./seasonality-trigger-upsert-dialog.component.html",
    styleUrl: "./seasonality-trigger-upsert-dialog.component.scss",
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MONTH_DAY_FORMATS },
    ],
})
export class SeasonalityTriggerUpsertDialogComponent {
    formGroup: FormGroup;
    exampleHeader = ExampleHeader;

    startDate = new FormControl(moment(), { validators: [Validators.required] });
    endDate = new FormControl(moment(), { validators: [Validators.required] });

    constructor(
        public dialogRef: MatDialogRef<SeasonalityTriggerUpsertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISeasonalityTriggerUpsertDialogComponentData,
        private fb: FormBuilder
    ) {
        this.startDate.setValue(moment({ date: data.SeasonalityTrigger?.StartDay, month: data.SeasonalityTrigger?.StartMonth - 1 }));
        this.endDate.setValue(moment({ date: data.SeasonalityTrigger?.EndDay, month: data.SeasonalityTrigger?.EndMonth - 1 }));

        this.formGroup = this.fb.group({
            Title: [data.SeasonalityTrigger?.Title || "", Validators.required],
            Description: [data.SeasonalityTrigger?.Description || ""],
            StartDay: [data.SeasonalityTrigger?.StartDay || ""],
            StartMonth: [data.SeasonalityTrigger?.StartMonth || ""],
            EndDay: [data.SeasonalityTrigger?.EndDay || ""],
            EndMonth: [data.SeasonalityTrigger?.EndMonth || ""],
        });
    }

    onSave() {
        this.formGroup.controls.StartDay.setValue(this.startDate.value.date());
        this.formGroup.controls.EndDay.setValue(this.endDate.value.date());
        this.formGroup.controls.StartMonth.setValue(this.startDate.value.month() + 1);
        this.formGroup.controls.EndMonth.setValue(this.endDate.value.month() + 1);

        const response = { ...this.formGroup.value, SeasonalityTriggerID: this.data.SeasonalityTrigger.SeasonalityTriggerID };
        this.dialogRef.close(response);
        this.formGroup.reset();
    }
}

export interface ISeasonalityTriggerUpsertDialogComponentData {
    SeasonalityTrigger?: SeasonalityTriggerDto;
    CommitmentID?: string;
}

export interface ISeasonalityTriggerUpsertDialogComponentResponse {
    SeasonalityTriggerID?: string;
    Title: string;
    Description: string;
    StartDay: number;
    EndDay: number;
    StartMonth: number;
    EndMonth: number;
}
