import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProjectDto } from "src/app/shared/generated/model/models";

@Injectable({
    providedIn: "root",
})
export class CurrentProjectService {
    private currentProject$ = new BehaviorSubject<ProjectDto>(null);

    constructor() {}

    setCurrentProject(project: ProjectDto): void {
        this.currentProject$.next(project);
    }

    getCurrentProject(): Observable<ProjectDto> {
        return this.currentProject$.asObservable();
    }
}
