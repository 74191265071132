import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, Sanitizer, SecurityContext, TemplateRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: "kv-pair",
    standalone: true,
    imports: [NgTemplateOutlet],
    templateUrl: "./kv-pair.component.html",
    styleUrl: "./kv-pair.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KvPairComponent {
    @Input() key: string;
    @Input() nullValue: string = "None";
    @Input() isHtmlString: boolean = false;
    public isTemplateRef: boolean = false;
    public templateRef: TemplateRef<any>;
    public stringValue: string;

    public get value(): string | TemplateRef<any> {
        return this.isTemplateRef ? this.templateRef : this.stringValue;
    }

    @Input() set value(value: string | TemplateRef<any>) {
        if (value instanceof TemplateRef) {
            this.isTemplateRef = true;
            this.templateRef = value;
        } else {
            this.stringValue = value;
        }
    }

    public securityContext: SecurityContext = SecurityContext.HTML;
    constructor(public domSanitizer: DomSanitizer) {}
}
