<div class="tasks">
    @if(checklistTasks$ | async;) { @if(sortedTasks.length > 0) {
    <table class="task-table" mat-table [dataSource]="dataSource" matSort matSortActive="dueDate" matSortDirection="asc" (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="no-wrap">Task Name</th>
            <td mat-cell *matCellDef="let checklistTask">{{checklistTask.TaskName}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let checklistTask">{{checklistTask.Description}}</td>
        </ng-container>

        <ng-container matColumnDef="assignedTo">
            <th mat-header-cell *matHeaderCellDef class="no-wrap">Assigned To</th>
            <td mat-cell *matCellDef="let checklistTask" class="no-wrap">{{checklistTask.AssignedTo}}</td>
        </ng-container>

        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef class="no-wrap">Owner</th>
            <td mat-cell *matCellDef="let checklistTask" class="no-wrap">{{checklistTask.Owner.FullName}}</td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Due Date</th>
            <td mat-cell *matCellDef="let checklistTask" class="no-wrap">{{checklistTask.DueDate | date}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let task" class="no-wrap">
                <button mat-icon-button color="primary" class="small-icon-button" (click)="editTask(task)"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button color="primary" class="small-icon-button" (click)="deleteTask(task)"><mat-icon>delete</mat-icon></button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    } @else {
    <em>This checklist item has no tasks.</em>
    } } @else {
    <loading-spinner></loading-spinner>
    }
</div>

<button mat-raised-button color="primary" (click)="addTask()"><mat-icon>add</mat-icon> Add Task</button>
