@if (project$ | async; as project) {
    <page-body>
        <page-header [pageTitle]="project.Name" [preTitle]="'Project'" [templateRight]="templateRight">
            <ng-template #templateRight>
                <div class="header-actions">
                    <button mat-icon-button (click)="editProject(project)"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button (click)="delete()" *ngIf="canDelete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-template>
            <div class="metadata">
                <kv-pair key="Start Date" [value]="project.StartDate | date"></kv-pair>
                <kv-pair key="End Date" [value]="project.EndDate | date"></kv-pair>
            </div>
        </page-header>

        <div class="fields">
            <div class="grid-12">
                <div class="g-col-8">
                    <kv-pair key="Description" [value]="descriptionValue">
                        <ng-template #descriptionValue>
                            <div [innerHTML]="project.Description === null ? '-' : (project.Description | bypassHtml)"></div>
                        </ng-template>
                    </kv-pair>
                    <div class="grid-12 mt-6">
                        <div class="g-col-6">
                            <a class="summary-item" [routerLink]="['./related-source-documents']">
                                <div class="summary-item__icon">
                                    <beacon-entity-icon [inline]="true" entity="SourceDocument" />
                                </div>
                                <div class="summary-item__content">
                                    <div class="summary-item__count">
                                        {{ project.SourceDocumentCount }}
                                    </div>
                                    <div class="summary-item__title">Source Documents</div>
                                </div>
                                <div class="summar-item__footer">(click to view all)</div>
                                <button mat-flat-button *ngIf="canCreateSourceDocument" color="primary" (click)="createSourceDocument()">
                                    <mat-icon>add</mat-icon>Source Document
                                </button>
                            </a>
                        </div>
                        <div class="g-col-6">
                            <a class="summary-item" [routerLink]="['./components']">
                                <div class="summary-item__icon">
                                    <beacon-entity-icon [inline]="true" entity="Component" />
                                </div>
                                <div class="summary-item__content">
                                    <div class="summary-item__count">
                                        {{ project.ComponentCount }}
                                    </div>
                                    <div class="summary-item__title">Components</div>
                                </div>
                                <div class="summar-item__footer">(click to view all)</div>
                                <a
                                    mat-raised-button
                                    color="primary"
                                    [routerLink]="['/compliance-tracking/components/create']"
                                    [queryParams]="{ projectID: project.ProjectID, projectName: project.Name }">
                                    <mat-icon>add</mat-icon> Add New
                                </a>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="g-col-4">
                    <kv-pair key="Files" [value]="filesTemplate"></kv-pair>
                    <ng-template #filesTemplate>
                        @if (project.ProjectFiles?.length > 0) {
                            <ul>
                                <li *ngFor="let file of project.ProjectFiles">
                                    <simple-file-display
                                        [fileName]="file.Name"
                                        [displayDelete]="false"
                                        [downloadUrl]="'projects/' + project.ProjectID + '/files/' + file.ProjectFileID">
                                    </simple-file-display>
                                </li>
                            </ul>
                        } @else {
                            <em>No Files</em>
                        }
                    </ng-template>
                </div>
            </div>
        </div>
    </page-body>
} @else {
    <loading-spinner></loading-spinner>
}
