import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { UserService } from "src/app/shared/generated/api/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Observable, Subscription, forkJoin } from "rxjs";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { ImpersonationService } from "src/app/shared/generated/api/impersonation.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { FlagEnum } from "src/app/shared/generated/enum/flag-enum";
import { map } from "rxjs/operators";

import { NgIf, AsyncPipe } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "beacon-user-detail",
    templateUrl: "./user-detail.component.html",
    styleUrls: ["./user-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatButton, MatIcon, AsyncPipe],
})
export class UserDetailComponent implements OnInit, OnDestroy {
    private currentUser: UserDto;
    currentUserSubscription: Subscription;
    impersonateSubscription: Subscription;
    user$: Observable<UserDto>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private impersonationService: ImpersonationService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        this.currentUserSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        const id = parseInt(this.route.snapshot.paramMap.get("id"));
        if (!id) return;

        this.user$ = this.userService.usersUserIDGet(id).pipe(
            map((data) => {
                const user = data instanceof Array ? null : (data as UserDto);
                return user;
            })
        );
    }

    impersonateUser(userID: number) {
        this.impersonateSubscription = this.impersonationService.impersonateUserIDPost(userID).subscribe({
            next: (response) => {
                this.currentUser = response;
                this.authenticationService.refreshUserInfo(response);
                this.cdr.detectChanges();
            },
            error: (error) => {
                this.cdr.detectChanges();
            },
            complete: () => {
                this.router.navigateByUrl("/");
                this.alertService.pushAlert(new Alert(`Successfully impersonating user: ${this.currentUser.FullName}`, AlertContext.Success));
            },
        });
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.currentUserSubscription?.unsubscribe();
        this.impersonateSubscription?.unsubscribe();
    }

    editUser() {
        this.router.navigate(["edit"], { relativeTo: this.route });
    }

    canUpdateUser(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.UserRights, RightsEnum.Update);
    }

    canImpersonate(): boolean {
        return this.authenticationService.hasFlag(this.currentUser, FlagEnum.CanImpersonateUsers);
    }
}
