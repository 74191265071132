import { NgIf, AsyncPipe, NgFor, CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, FormsModule, Validators } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { RouterLink } from "@angular/router";
import { EsaValueDisplayComponent, EsaMaterialFormFieldComponent, EsaLabelComponent } from "esa-material-form-field";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";
import { CustomFormLabelComponent } from "../../custom-form-label/custom-form-label.component";
import { ProjectDto, SourceDto, SourceFileDto } from "src/app/shared/generated/model/models";
import { ComponentChecklistFormComponent } from "src/app/pages/component-checklist-form/component-checklist-form.component";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { FileDropUploadComponent } from "../../file-drop-upload/file-drop-upload.component";
import { EvidenceOfComplianceUpsertComponent } from "../../evidence-of-compliance-upsert/evidence-of-compliance-upsert.component";
import { SimpleFileDisplayComponent } from "../../simple-file-display/simple-file-display.component";
import { KvPairComponent } from "../../kv-pair/kv-pair.component";
import { MatSelectModule } from "@angular/material/select";
import { BeaconLoadingOverlayComponent } from "../../beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "../../loading-spinner/loading-spinner.component";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertBoxComponent } from "../../alert-box/alert-box.component";
import { ProjectService } from "src/app/shared/generated/api/project.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { TinyMceConfigPipe } from "../../../pipes/tiny-mce-config.pipe";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { Observable, tap } from "rxjs";
import { SourceService } from "src/app/shared/generated/api/source.service";
import { CurrentSourceDocumentService } from "src/app/services/current-source-document/current-source-document.service";

@Component({
    selector: "source-document-upsert-dialog",
    standalone: true,
    imports: [
        ComponentChecklistFormComponent,
        MatTooltip,
        KvPairComponent,
        EvidenceOfComplianceUpsertComponent,
        MatDialogContent,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        MatDialogTitle,
        NgIf,
        MatSelectModule,
        NgFor,
        AsyncPipe,
        MatButton,
        EsaValueDisplayComponent,
        EsaMaterialFormFieldComponent,
        EsaLabelComponent,
        CustomFormLabelComponent,
        RouterLink,
        BypassHtmlPipe,
        ReactiveFormsModule,
        FileDropUploadComponent,
        SimpleFileDisplayComponent,
        CommonModule,
        MatDialogModule,
        BeaconLoadingOverlayComponent,
        LoadingSpinnerComponent,
        AlertBoxComponent,
        MatDatepickerModule,
        MatInputModule,
        TinyMceConfigPipe,
        EditorComponent,
    ],
    providers: [provideNativeDateAdapter()],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./source-document-upsert-dialog.component.html",
    styleUrl: "./source-document-upsert-dialog.component.scss",
})
export class SourceDocumentUpsertDialogComponent implements OnInit {
    testChange($event: any) {}
    formGroup: FormGroup;
    public duplicateFileNames: string[] = [];
    public currentRoute = "";
    public isLoading: boolean = false;
    public projects$: Observable<ProjectDto[]>;
    AlertContext = AlertContext;

    constructor(
        public dialogRef: MatDialogRef<SourceDocumentUpsertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ISourceDocumentUpsertDialogComponentData,
        private fb: FormBuilder,
        private projectService: ProjectService,
        private sourceDocumentService: SourceService,
        private cdr: ChangeDetectorRef,
        private currentSourceDocumentService: CurrentSourceDocumentService
    ) {
        this.formGroup = this.fb.group({
            Name: [data.SourceDocument?.Name || "", Validators.required],
            ProjectID: [data.SourceDocument?.ProjectID || "", Validators.required],
            DateOfLatestAmendment: this.fb.control(
                data.SourceDocument?.DateOfLatestAmendment ? new Date(data.SourceDocument.DateOfLatestAmendment).toISOString() : null,
                Validators.required
            ),
            ReferenceNumber: [data.SourceDocument?.ReferenceNumber || ""],
            ApprovingAgency: [data.SourceDocument?.ApprovingAgency || ""],
            AgencyContact: [data.SourceDocument?.AgencyContact || ""],
            AgencySignatory: [data.SourceDocument?.AgencySignatory || ""],
            Description: [data.SourceDocument?.Description || ""],
            AgreementTerms: [data.SourceDocument?.AgreementTerms || ""],
            NewSourceDocumentFiles: this.fb.array([]),
            SourceDocumentFiles: [data.SourceDocument?.SourceDocumentFiles || []],
        });
    }

    ngOnInit(): void {
        this.projects$ = this.projectService.projectsGet().pipe(
            tap((projects: ProjectDto[]) => {
                if (projects.length == 1) {
                    this.formGroup.controls.ProjectID.patchValue(projects[0].ProjectID);
                }
            })
        );
        if (this.data.ProjectID) {
            this.formGroup.controls.ProjectID.patchValue(this.data.ProjectID);
        }
    }

    onFileUpload(files: File[]) {
        const newFilesArray = this.formGroup.get("NewSourceDocumentFiles") as FormArray;
        files.forEach((file) => {
            newFilesArray.push(this.fb.control(file));
        });
        this.checkForDuplicates();
    }

    checkForDuplicates() {
        const newFilesArray = this.formGroup.get("NewSourceDocumentFiles") as FormArray;
        const existingFiles = this.formGroup.controls.SourceDocumentFiles.value;

        const newFileNames = newFilesArray.value.map((f: File) => `"${f.name}"`);
        const existingFileNames = existingFiles.map((f: SourceFileDto) => `"${f.Name}"`);
        const duplicateFileNames = newFileNames.filter((x) => existingFileNames.includes(x));

        if (duplicateFileNames.length > 0) {
            this.duplicateFileNames = duplicateFileNames;
        } else {
            this.duplicateFileNames = [];
        }
    }

    deleteNewFile(file: File) {
        const newFilesArray = this.formGroup.get("NewSourceDocumentFiles") as FormArray;
        const index = newFilesArray.value.findIndex((f: File) => f === file);
        if (index !== -1) {
            newFilesArray.removeAt(index);
        }
    }

    deleteExistingFile(file: SourceFileDto) {
        const files = this.formGroup.controls.SourceDocumentFiles.value.filter((f: SourceFileDto) => f !== file);
        this.formGroup.controls.SourceDocumentFiles.patchValue(files);
    }

    onSave() {
        const formValue = this.formGroup.value;
        this.formGroup.value.DateOfLatestAmendment = formValue.DateOfLatestAmendment ? new Date(formValue.DateOfLatestAmendment).toISOString() : null;

        const response = {
            ...this.formGroup.value,
            SourceDocumentID: this.data.SourceDocument?.SourceID || "",
        } as ISourceDocumentUpsertDialogComponentRequest;
        this.saveSourceDocument(response);
    }

    saveSourceDocument(sourceDocumentData: ISourceDocumentUpsertDialogComponentRequest) {
        this.isLoading = true;
        this.cdr.markForCheck();

        const filesJson = sourceDocumentData.SourceDocumentFiles.map((f: SourceFileDto) => JSON.stringify(f));
        const newFiles = sourceDocumentData.NewSourceDocumentFiles;
        this.sourceDocumentService
            .sourceDocumentsPut(
                sourceDocumentData.ProjectID,
                sourceDocumentData.Name,
                sourceDocumentData.DateOfLatestAmendment,
                sourceDocumentData.SourceDocumentID,
                sourceDocumentData.ReferenceNumber,
                sourceDocumentData.ApprovingAgency,
                sourceDocumentData.AgencyContact,
                sourceDocumentData.AgencySignatory,
                sourceDocumentData.Description,
                sourceDocumentData.AgreementTerms,
                filesJson,
                newFiles
            )
            .subscribe({
                next: (response) => {
                    const dialogResponse: ISourceDocumentUpsertDialogComponentResponse = {
                        SourceDocumentID: response.SourceID,
                        Success: true,
                    };
                    this.currentSourceDocumentService.setCurrentSourceDocument(response);
                    this.isLoading = false;
                    this.dialogRef.close(dialogResponse);
                },
                error: () => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                },
            });
    }
}

export interface ISourceDocumentUpsertDialogComponentData {
    SourceDocument?: SourceDto;
    ProjectID?: string;
}

export interface ISourceDocumentUpsertDialogComponentRequest {
    SourceDocumentID?: string;
    Name: string;
    ProjectID: string;
    DateOfLatestAmendment: string;
    ReferenceNumber: string;
    ApprovingAgency: string;
    AgencyContact: string;
    AgencySignatory: string;
    Description: string;
    AgreementTerms: string;
    NewSourceDocumentFiles: File[];
    SourceDocumentFiles: SourceFileDto[];
}

export interface ISourceDocumentUpsertDialogComponentResponse {
    SourceDocumentID: string;
    Success: boolean;
}
