<form class="form grid-12" #roleForm="ngForm">
    <div class="g-col-12">
        <esa-material-ff name="RoleName" [editMode]="editMode" [required]="true" label="Name" type="text" [(ngModel)]="roleUpsertDto.RoleName">
        </esa-material-ff>

        <esa-material-ff
            name="RoleDescription"
            [editMode]="editMode"
            [required]="false"
            label="Description"
            type="textarea"
            [(ngModel)]="roleUpsertDto.RoleDescription">
        </esa-material-ff>

        <esa-material-ff
            name="CustomPageRights"
            [editMode]="editMode"
            [required]="true"
            label="Custom Page Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.CustomPageRights">
        </esa-material-ff>

        <esa-material-ff
            name="CustomRichTextRights"
            [editMode]="editMode"
            [required]="true"
            label="Custom Rich Text Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.CustomRichTextRights">
        </esa-material-ff>

        <esa-material-ff
            name="FieldDefinitionRights"
            [editMode]="editMode"
            [required]="true"
            label="Field Definition Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.FieldDefinitionRights">
        </esa-material-ff>

        <esa-material-ff
            name="UserRights"
            [editMode]="editMode"
            [required]="true"
            label="User Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.UserRights">
        </esa-material-ff>

        <esa-material-ff
            name="RoleRights"
            [editMode]="editMode"
            [required]="true"
            label="Role Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.RoleRights">
        </esa-material-ff>

        <esa-material-ff
            name="ProjectRights"
            [editMode]="editMode"
            [required]="true"
            label="Project Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.ProjectRights">
        </esa-material-ff>

        <esa-material-ff
            name="SourceDocumentRights"
            [editMode]="editMode"
            [required]="true"
            label="Source Document Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.SourceDocumentRights">
        </esa-material-ff>

        <esa-material-ff
            name="CommitmentRights"
            [editMode]="editMode"
            [required]="true"
            label="Commitment Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.CommitmentRights">
        </esa-material-ff>

        <esa-material-ff
            name="ChecklistRights"
            [editMode]="editMode"
            [required]="true"
            label="Checklist Rights"
            type="dropdown"
            [options]="permissionsOptions"
            optionLabel="Name"
            optionValue="MaskValue"
            [(ngModel)]="roleUpsertDto.Rights.ChecklistRights">
        </esa-material-ff>

        <div [ngClass]="editMode ? 'checkboxes' : 'g-col-12'">
            <esa-material-ff
                name="CanImpersonateUsers"
                [editMode]="editMode"
                [required]="true"
                label="Can Impersonate Users"
                type="checkbox"
                [(ngModel)]="roleUpsertDto.Flags.CanImpersonateUsers">
            </esa-material-ff>

            <esa-material-ff
                name="CanFinalizeCommitments"
                [editMode]="editMode"
                [required]="true"
                type="checkbox"
                label="Can Approve Commitments"
                [(ngModel)]="roleUpsertDto.Flags.CanFinalizeCommitments"
                [customLabel]="editMode ? canFinalizeCustomLabel : null">
            </esa-material-ff>
            <ng-template #canFinalizeCustomLabel>
                <div class="key" style="width: 100%">
                    <label>Can Approve Commitments</label>
                </div>
            </ng-template>

            <esa-material-ff
                name="CanEditFinalizedCommitments"
                [editMode]="editMode"
                [required]="true"
                type="checkbox"
                label="Can Edit Approved Commitments"
                [(ngModel)]="roleUpsertDto.Flags.CanEditFinalizedCommitments"
                [customLabel]="editMode ? canEditCustomLabel : null">
            </esa-material-ff>
            <ng-template #canEditCustomLabel>
                <div class="key" style="width: 100%">
                    <label>Can Edit Approved Commitments</label>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <span [matTooltip]="!roleForm.form.valid ? 'You are missing requried fields.' : ''">
            <button
                mat-flat-button
                color="primary"
                label="Save"
                icon="save"
                (click)="saveForm(form)"
                [disabled]="!roleForm.form.valid"
                style="margin-right: 1rem">
                <mat-icon>save</mat-icon> Save
            </button>
        </span>

        <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</form>
