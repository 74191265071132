import { Component, Input, OnInit } from "@angular/core";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";
import { NgIf, UpperCasePipe, DatePipe } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { RouterLink } from "@angular/router";
import { MatCard, MatCardSubtitle, MatCardContent } from "@angular/material/card";

@Component({
    selector: "revision-history-details-card",
    templateUrl: "./revision-history-details-card.component.html",
    styleUrls: ["./revision-history-details-card.component.scss"],
    standalone: true,
    imports: [MatCard, RouterLink, MatIcon, MatCardSubtitle, NgIf, MatCardContent, UpperCasePipe, DatePipe, BypassHtmlPipe],
})
export class RevisionHistoryDetailsCardComponent implements OnInit {
    @Input() versionData: any;
    @Input() isLast: boolean;
    listColor: string;

    constructor() {}

    ngOnInit(): void {
        switch (this.versionData.CommitmentVersionStatus.CommitmentVersionStatusName) {
            case "Draft":
                this.listColor = "gold";
                break;
            case "Approved":
                this.listColor = "green";
                break;
            default:
                this.listColor = "gold";
        }
    }
}
