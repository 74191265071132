import { ChangeDetectionStrategy, ChangeDetectorRef, Inject } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from "@angular/material/dialog";
import { forkJoin } from "rxjs";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { RelationshipTypeService } from "src/app/shared/generated/api/relationship-type.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentRelationshipCreateDto } from "src/app/shared/generated/model/commitment-relationship-create-dto";
import { RelationshipTypeDto } from "src/app/shared/generated/model/relationship-type-dto";
import { CustomFormLabelComponent } from "../../../shared/components/custom-form-label/custom-form-label.component";
import { FormsModule } from "@angular/forms";
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "create-relationship-dialog",
    templateUrl: "./create-relationship-dialog.component.html",
    styleUrls: ["./create-relationship-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        NgIf,
        EsaMaterialFormFieldComponent,
        FormsModule,
        CustomFormLabelComponent,
        MatDialogActions,
        MatDialogClose,
        MatButton,
        MatIcon,
    ],
})
export class CreateRelationshipDialogComponent implements OnInit {
    relationshipTypes: RelationshipTypeDto[];
    commitments: CommitmentDto[];

    commitmentsWithDisplayName: any[] = [];

    public commitmentRelationshipCreateDto: CommitmentRelationshipCreateDto;

    constructor(
        public dialogRef: MatDialogRef<CreateRelationshipDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private relationshipTypeService: RelationshipTypeService,
        private commitmentService: CommitmentService,
        private cdr: ChangeDetectorRef
    ) {
        this.commitmentRelationshipCreateDto = {
            RelationshipTypeID: null,
            OtherCommitmentID: null,
        };
    }

    ngOnInit(): void {
        forkJoin([this.relationshipTypeService.relationshipTypesGet(), this.commitmentService.commitmentsGet()]).subscribe((results) => {
            this.relationshipTypes = results[0];
            this.commitments = results[1];

            this.commitments.forEach((c) => {
                let x = c as any;
                x.DisplayName = `${c.ClientCommitmentID} - ${c.Title}`;

                if (c.CommitmentID != this.data.commitment.CommitmentID) {
                    this.commitmentsWithDisplayName.push(x);
                }
            });

            this.cdr.markForCheck();
        });
    }
}
