import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { filter, tap } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { TenantModuleService } from "src/app/shared/services/tenant-module/tenant-module.service";
import { AsyncPipe } from "@angular/common";
import { Observable } from "rxjs";
import { TenantModuleDto } from "src/app/shared/generated/model/tenant-module-dto";

@Component({
    selector: "login-redirect",
    templateUrl: "./login-redirect.component.html",
    styleUrls: ["./login-redirect.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe],
})
export class LoginRedirectComponent {
    public currentUser$: Observable<UserDto> = this.localAuthService.currentUser$;
    public currentModule$: Observable<TenantModuleDto> = this.tenantModuleService.currentModule$.pipe(
        filter((module) => module != null),
        tap((module) => {
            setTimeout(() => {
                this.router.navigate(["/" + module.LandingPage]);
            });
        })
    );

    constructor(
        private router: Router,
        private tenantModuleService: TenantModuleService,
        private localAuthService: AuthenticationService
    ) {}
}
