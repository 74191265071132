<div class="grid-actions-wrapper">
    <div class="grid-actions-start">
        <ng-container *ngIf="templatePrepend" [ngTemplateOutlet]="templatePrepend"></ng-container>
    </div>

    <div class="grid-actions-end">
        <div class="grid-actions" *ngIf="grid">
            <ng-container *ngIf="rowCount$ | async as gridCount">
                <input
                    type="text"
                    name="quickFilterText"
                    [(ngModel)]="quickFilterText"
                    (ngModelChange)="onQuickFilterTextChanged()"
                    placeholder="Search grid..."
                    [disabled]="disableGlobalFilter" />
                <span *ngIf="gridCount.total === gridCount.visible; else expandedText"> {{ gridCount.total }} Record(s) </span>
                <ng-template #expandedText>
                    <span>Viewing {{ gridCount.visible }} of {{ gridCount.total }} Record(s)</span>
                </ng-template>
            </ng-container>
            <beacon-clear-grid-filters-button [grid]="grid"></beacon-clear-grid-filters-button>
            <beacon-csv-download-button *ngIf="enableDownload" [grid]="grid" [fileName]="downloadFileName" [colIDsToExclude]="downloadColIDsToExclude">
            </beacon-csv-download-button>
        </div>
        <ng-content></ng-content>
    </div>
</div>
