import { Component, Input } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { MatTooltip } from "@angular/material/tooltip";

import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "beacon-csv-download-button",
    templateUrl: "./csv-download-button.component.html",
    styleUrls: ["./csv-download-button.component.scss"],
    standalone: true,
    imports: [MatTooltip, MatButton, MatIcon],
})
export class CsvDownloadButtonComponent {
    @Input() grid: AgGridAngular;
    @Input() fileName: string;
    @Input() colIDsToExclude = [];

    constructor(private utilityFunctionsService: UtilityFunctionsService) {}

    public exportToCsv() {
        let columnsKeys = this.grid.api.getAllDisplayedColumns();

        const columnIDs = columnsKeys
            .filter((key) => {
                const colID = key.getColId();
                return this.colIDsToExclude.indexOf(colID) < 0;
            })
            .map((x) => x.getColId());

        this.utilityFunctionsService.exportGridToCsv(this.grid, this.fileName + ".csv", columnIDs);
    }
}
