import { Component, input, InputSignal } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { BeaconEntityType, BeaconEntityTypeEnum, BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "empty-list",
    standalone: true,
    imports: [MatIcon, BeaconEntityIconComponent],
    templateUrl: "./empty-list.component.html",
    styleUrl: "./empty-list.component.scss",
})
export class EmptyListComponent {
    showIcon = input<boolean>(true);
    entityIcon: InputSignal<typeof BeaconEntityType> = input<typeof BeaconEntityType>();
}
