/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserDto } from '././user-dto';

export class SeasonalityTriggerDto { 
    SeasonalityTriggerID?: string;
    CommitmentID?: string;
    Title?: string;
    Description?: string;
    StartDay?: number;
    StartMonth?: number;
    EndDay?: number;
    EndMonth?: number;
    CreateUser?: UserDto;
    UpdateUser?: UserDto;
    CreateDate?: string;
    UpdateDate?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
