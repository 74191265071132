<div class="details-layout">
    <section class="sidebar">
        <layout-sidebar-nav [sideNavGroups]="commitmentSideNav" [sidebarHeader]="sidebarHeader"></layout-sidebar-nav>

        <ng-template #sidebarHeader>
            @if (commitment$ | async; as commitment) {
                <h2>{{ commitment.Title }}</h2>
                <pill>{{ commitment.ClientCommitmentID }}</pill>
                <strong> version: {{ commitment.VersionNumber }}</strong>
            }
        </ng-template>
    </section>
    <section class="outlet">
        <router-outlet />
    </section>
</div>
