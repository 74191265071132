import { CommonModule } from "@angular/common";
import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { combineLatest, switchMap, of, tap, Observable } from "rxjs";
import { routeParams } from "src/app/app.routes";
import { INavGroup, LayoutSidebarNavComponent } from "src/app/shared/components/layout-sidebar-nav/layout-sidebar-nav.component";
import { PillComponent } from "../../shared/components/pill/pill.component";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";

@Component({
    selector: "commitment-details-layout",
    standalone: true,
    imports: [RouterOutlet, LayoutSidebarNavComponent, CommonModule, PillComponent],
    templateUrl: "./commitment-details-layout.component.html",
    styleUrl: "./commitment-details-layout.component.scss",
})
export class CommitmentDetailsLayoutComponent implements OnDestroy {
    ngOnDestroy(): void {
        this.commitmentUpdatedService.setCurrentCommitment(null);
    }

    private commitmentUpdatedService: CurrentCommitmentService = inject(CurrentCommitmentService);
    private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    private commitmentService: CommitmentService = inject(CommitmentService);
    public sourceDocumentCount: number;
    public componentCount: number;
    public commitmentCount: number;
    public evidenceOfComplianceCount: number;
    public commitmentSideNav: INavGroup[] = [];

    commitment$ = combineLatest([this.activatedRoute.paramMap, this.commitmentUpdatedService.getCurrentCommitment()]).pipe(
        switchMap(([paramMap, updatedCommitment]) => {
            let commitmentChange = paramMap.get(routeParams.commitmentID).toLowerCase() != updatedCommitment?.CommitmentID.toLowerCase();
            if (updatedCommitment && !commitmentChange) {
                return of(updatedCommitment);
            }
            return this.commitmentService.commitmentsCommitmentIDGet(paramMap.get(routeParams.commitmentID)).pipe(
                tap((x) => {
                    this.commitmentUpdatedService.setCurrentCommitment(x);
                })
            );
        }),
        tap((commitment) => {
            this.commitmentSideNav = [
                {
                    Items: [
                        {
                            Title: "Commitment Summary",
                            RouterLink: "./",
                        },
                    ],
                },
                {
                    Title: "Commitment Library",
                    Items: [
                        {
                            Title: "Compliance Requirements",
                            RouterLink: "compliance-requirements",
                            Icon: "ComplianceRequirement",
                            ItemCount: commitment.ComplianceRequirementCount,
                        },
                        {
                            Title: "Related Commitments",
                            RouterLink: "commitment-relationships",
                            Icon: "Commitment",
                            ItemCount: commitment.RelatedCommitmentCount,
                        },
                    ],
                },
                {
                    Title: "Commitment History",
                    Items: [
                        {
                            Title: "Version Metadata",
                            RouterLink: "version-metadata",
                        },
                        {
                            Title: "Revision History",
                            RouterLink: "revision-history",
                        },
                    ],
                },
            ];
        })
    );
}
