<form class="form grid-12" #customPageForm="ngForm">
    <div class="g-col-12">
        <esa-material-ff
            name="MenuItem"
            [editMode]="true"
            [required]="true"
            label="Menu"
            type="dropdown"
            [options]="menuItems"
            optionLabel="MenuItemDisplayName"
            optionValue="MenuItemID"
            [(ngModel)]="customPageUpsertDto.MenuItemID">
        </esa-material-ff>

        <esa-material-ff
            name="CustomPageDisplayName"
            placeholder="Page Name"
            [editMode]="true"
            [required]="true"
            label="Page Name"
            type="text"
            [(ngModel)]="customPageUpsertDto.CustomPageDisplayName"
            (ngModelChange)="slugifyPageName($event)">
        </esa-material-ff>
        <div class="error" *ngIf="!validPageName(customPageUpsertDto.CustomPageDisplayName)">
            <mat-error>Page name must consist of letters, numbers, spaces, underscores(_), and hyphens(-) only</mat-error>
        </div>

        <esa-material-ff
            name="CustomPageVanityUrl"
            placeholder="Vanity URL"
            [editMode]="true"
            [required]="true"
            label="Vanity URL"
            type="text"
            [(ngModel)]="customPageUpsertDto.CustomPageVanityUrl"
            (ngModelChange)="slugifyPageName($event)">
        </esa-material-ff>
        <div class="error" *ngIf="!validVanityUrl(customPageUpsertDto.CustomPageVanityUrl)">
            <mat-error>Vanity URL must consist of letters, numbers, underscores(_), and hyphens(-) only</mat-error>
        </div>

        <esa-material-ff
            name="Viewable by Roles"
            [editMode]="true"
            [required]="true"
            label="Viewable by Roles:"
            placeholder="Select Item(s)"
            [options]="roles"
            optionLabel="RoleName"
            optionValue="RoleID"
            type="dropdown"
            [(ngModel)]="customPageUpsertDto.ViewableRoleIDs"
            [dropdownMultiple]="true">
        </esa-material-ff>
    </div>

    <div class="g-col-12 actions-bar" style="margin-top: 1rem">
        <button
            mat-flat-button
            color="primary"
            [disabled]="
                !customPageForm.valid || !validVanityUrl(customPageUpsertDto.CustomPageVanityUrl) || !validPageName(customPageUpsertDto.CustomPageDisplayName)
            "
            (click)="saveForm(form)"
            style="margin-right: 1rem">
            <mat-icon>save</mat-icon> Save
        </button>
        <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</form>
