import { Component, computed, inject, input, InputSignal, Signal, signal } from "@angular/core";
import { AlertContext } from "../../models/enums/alert-context.enum";
import { AlertService } from "../../services/alert.service";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "alert-box",
    standalone: true,
    imports: [MatIcon],
    templateUrl: "./alert-box.component.html",
    styleUrl: "./alert-box.component.scss",
})
export class AlertBoxComponent {
    public alertContext: InputSignal<AlertContext> = input(AlertContext.Info);
    public alertContextClass: Signal<string> = computed(() => {
        switch (this.alertContext()) {
            case AlertContext.Info:
                return "info";
            case AlertContext.Success:
                return "success";
            case AlertContext.Warning:
                return "warning";
            case AlertContext.Danger:
                return "danger";
            case AlertContext.Primary:
                return "primary";
            default:
                return "info";
        }
    });

    public matIcon: Signal<string> = computed(() => {
        switch (this.alertContext()) {
            case AlertContext.Success:
            case AlertContext.Info:
            case AlertContext.Primary:
                return "info";
            case AlertContext.Warning:
            case AlertContext.Danger:
                return "warning";

            default:
                return "info";
        }
    });
}
