@if (commitment$ | async; as commitment) {
    <page-body>
        <page-header [pageTitle]="commitment.Title" [preTitle]="'Commitment'" [templateRight]="templateRight">
            <ng-template #templateRight>
                <div class="header-actions">
                    <button mat-raised-button #profileMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="profile" color="primary">
                        <mat-icon>expand_more</mat-icon>&nbsp;Options
                    </button>
                    <mat-menu #profile="matMenu" [overlapTrigger]="false">
                        <button mat-menu-item (click)="revertToDraft()" *ngIf="canRevertToDraft">
                            <mat-icon>undo</mat-icon>
                            <span>Revert to Draft</span>
                        </button>

                        <button mat-menu-item (click)="approve()" *ngIf="canApprove">
                            <mat-icon>task_alt</mat-icon>
                            <span>Approve</span>
                        </button>

                        <button mat-menu-item (click)="version()" *ngIf="canVersion">
                            <mat-icon>difference</mat-icon>
                            <span>Version</span>
                        </button>
                    </mat-menu>
                    <button mat-icon-button (click)="editCommitment(commitment)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="delete(commitment)" *ngIf="canDelete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-template>
            <div class="metadata">
                <kv-pair key="Commitment ID" [value]="commitment.ClientCommitmentID"></kv-pair>
                <kv-pair key="Version" [value]="commitment.VersionNumber.toString()"></kv-pair>
                <mat-icon *ngIf="!commitment.IsLatestVersion" matTooltip="This is not the latest version" color="warn">warning</mat-icon>
            </div>
        </page-header>

        <div>
            <div class="grid-12">
                <div class="g-col-12 fields">
                    <div class="grid-12">
                        <div class="g-col-4 fields">
                            <mat-label label="Project">
                                <span class="related-label">Project</span>
                                <a class="related-display" [routerLink]="['/commitment-library/projects/', commitment.ProjectRoutingData.ProjectID]">{{
                                    commitment.ProjectRoutingData.Name
                                }}</a>
                            </mat-label>

                            <mat-label label="Source">
                                <span class="related-label">Source</span>
                                <a class="related-display" [routerLink]="['/commitment-library/source-documents/', commitment.Source.SourceID]">{{
                                    commitment.Source.Name
                                }}</a>
                            </mat-label>

                            <kv-pair key="Technical Leads" [value]="technicalLeadUserNames"></kv-pair>
                            <kv-pair key="Seasonality" [value]="commitment.Seasonality"></kv-pair>
                            <kv-pair key="Seasonality" [value]="commitment.Seasonality"></kv-pair>
                            <kv-pair key="Work Activities" [value]="workActivitiesTemplate">
                                <ng-template #workActivitiesTemplate>
                                    <mat-chip-row *ngFor="let workActivity of workActivities">
                                        {{ workActivity.Name }}
                                    </mat-chip-row>
                                </ng-template>
                            </kv-pair>
                        </div>
                        <div class="g-col-4 fields">
                            <kv-pair key="Commitment Type" [value]="commitment.CommitmentType.Name"></kv-pair>
                            <kv-pair key="Resource Category" [value]="commitment.ResourceCategory?.Name"></kv-pair>
                            <kv-pair key="Species Code" [value]="commitment.SpeciesCode"></kv-pair>
                            <kv-pair key="Location" [value]="commitment.LocationDescription"></kv-pair>
                            <kv-pair key="General Guidance" [value]="commitment.GeneralGuidance"></kv-pair>
                        </div>
                        <div class="g-col-4 fields">
                            <kv-pair key="Files" [value]="filesTemplate"></kv-pair>
                            <ng-template #filesTemplate>
                                @if (commitment.CommitmentFiles?.length > 0) {
                                    <ul>
                                        <li *ngFor="let file of commitment.CommitmentFiles">
                                            <simple-file-display
                                                [fileName]="file.Name"
                                                [displayDelete]="false"
                                                [downloadUrl]="'sources/' + commitment.CommitmentID + '/files/' + file.CommitmentFileID">
                                            </simple-file-display>
                                        </li>
                                    </ul>
                                } @else {
                                    <em>No Files</em>
                                }
                            </ng-template>
                            <div class="seasonality-trigger-display-and-upsert">
                                <seasonality-trigger [commitmentID]="commitment.CommitmentID"></seasonality-trigger>
                            </div>
                        </div>
                        <div class="g-col-12 fields">
                            <kv-pair key="Full Commitment Text" [value]="fullCommitmentTextValue">
                                <ng-template #fullCommitmentTextValue>
                                    <div [innerHTML]="commitment.FullCommitmentText === null ? '-' : (commitment.FullCommitmentText | bypassHtml)"></div>
                                </ng-template>
                            </kv-pair>
                        </div>
                    </div>

                    <div class="grid-12 mt-6">
                        <div class="g-col-6">
                            <a class="summary-item" [routerLink]="['./commitment-relationships']">
                                <div class="summary-item__icon">
                                    <beacon-entity-icon [inline]="true" entity="Commitment" />
                                </div>
                                <div class="summary-item__content">
                                    <div class="summary-item__count">
                                        {{ commitment.RelatedCommitmentCount }}
                                    </div>
                                    <div class="summary-item__title">Related Commitments</div>
                                </div>
                                <div class="summar-item__footer">(click to view all)</div>
                                <button
                                    mat-flat-button
                                    *ngIf="currentUser && canUpdateCommitment()"
                                    icon="add"
                                    color="primary"
                                    (click)="openCreateRelationshipDialog(commitment)">
                                    <mat-icon>add</mat-icon> Relationship
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </page-body>
} @else {
    <loading-spinner></loading-spinner>
}
