<div class="list-view">
    <div class="page-header"></div>
    <div class="g-col-12">
        <div class="component-commitment-list grid-12">
            <div class="table-responsive" [ngClass]="editMode ? 'g-col-10' : 'g-col-12'" style="height: 800px">
                <beacon-grid-actions [grid]="commitmentsGrid" downloadFileName="commitments" [downloadColIDsToExclude]="['Details']">
                    <button
                        mat-button
                        *ngIf="canEdit()"
                        type="button"
                        color="primary"
                        (click)="enableEditMode()"
                        matTooltip="Edit"
                        [disabled]="!commitmentsLoaded || possibleCommitments.length == 0">
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-flat-button style="margin-right: 1rem" color="primary" (click)="save()" *ngIf="editMode"><mat-icon>save</mat-icon> Save</button>
                    <button mat-button color="primary" (click)="cancelEditMode(false, 'cancel')" *ngIf="editMode"><mat-icon>cancel</mat-icon> Cancel</button>
                </beacon-grid-actions>

                <ag-grid-angular
                    #commitmentsGrid
                    style="width: 100%; height: 100%"
                    class="ag-theme-material"
                    [rowData]="rowData$ | async"
                    [columnDefs]="columnDefs"
                    (cellValueChanged)="onCellValueChanged($event)"
                    [suppressMenuHide]="true"
                    (gridReady)="onCommitmentsGridReady($event)"
                    [rowSelection]="'multiple'"
                    [rowMultiSelectWithClick]="true"
                    [suppressRowClickSelection]="true"
                    [stopEditingWhenCellsLoseFocus]="true"
                    [suppressRowDeselection]="true"
                    (rowSelected)="commitmentSelected($event)"
                    [getRowId]="getRowNodeId"
                    [overlayNoRowsTemplate]="noRowsTemplate">
                </ag-grid-angular>
            </div>

            <div class="g-col-2 table-responsive" *ngIf="editMode">
                <mat-card class="table-responsive commitments-list">
                    <mat-card-title style="font-size: medium">Selected Commitments</mat-card-title>
                    <mat-card-content class="commitments-list-content">
                        <ng-container *ngIf="selectedCommitments.length > 0; else noCommitments">
                            <mat-list role="list" *ngFor="let commitment of selectedCommitments">
                                <mat-list-item role="listitem" class="commitments-list-item">
                                    <mat-icon mat-list-icon style="color: lightgray">check_small</mat-icon>
                                    {{ commitment.ClientCommitmentID }}
                                </mat-list-item>
                                <mat-divider></mat-divider>
                            </mat-list>
                        </ng-container>
                        <ng-template #noCommitments>
                            <mat-list role="list">
                                <mat-list-item role="listitem" class="commitments-list-item">No commitments are selected.</mat-list-item>
                            </mat-list>
                        </ng-template>
                    </mat-card-content>
                    <mat-card-actions align="end">
                        <mat-card-subtitle>Total: {{ selectedCommitments.length }}</mat-card-subtitle>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</div>
