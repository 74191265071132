<mat-form-field>
    <mat-label>{{ label() }}</mat-label>
    <input
        #searchInput
        type="text"
        placeholder="Search..."
        aria-label="Select an Option"
        matInput
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        (input)="filter()"
        (focus)="filter()" />
    <mat-autocomplete #auto="matAutocomplete" [requireSelection]="requireSelection()" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
        @for (option of filteredOptions; track option) {
            <mat-option [value]="option">{{ option.displayValue }}</mat-option>
        }
    </mat-autocomplete>
</mat-form-field>
