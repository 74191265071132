<div class="source-document-upsert-dialog">
    @if (isLoading) {
        <beacon-loading-overlay>
            <loading-spinner [strokeWidth]="5" [diameter]="48"></loading-spinner>
            Saving Source Document... Please be patient when uploading large files.
        </beacon-loading-overlay>
    }

    <h1 mat-dialog-title>
        {{ data.SourceDocument?.SourceID ? "Edit Source Document" : "Add Source Document" }}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <esa-material-ff name="Name" [editMode]="true" [required]="true" type="text" label="Name" formControlName="Name"> </esa-material-ff>

            <esa-material-ff
                name="Project"
                [editMode]="true"
                [required]="true"
                type="dropdown"
                label="Project"
                [options]="projects$ | async"
                optionLabel="Name"
                optionValue="ProjectID"
                formControlName="ProjectID">
            </esa-material-ff>

            <div class="form-group flex-start gap">
                <custom-form-label label="Date Of Latest Amendment"></custom-form-label>
                <mat-form-field class="value" appearance="fill">
                    <mat-label>Date Of Latest Amendment</mat-label>
                    <input matInput [matDatepicker]="latestAmendmendDatePicker" formControlName="DateOfLatestAmendment" />
                    <mat-datepicker-toggle matSuffix [for]="latestAmendmendDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #latestAmendmendDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

            <esa-material-ff name="ReferenceNumber" [editMode]="true" [required]="false" type="text" label="Reference Number" formControlName="ReferenceNumber">
            </esa-material-ff>

            <esa-material-ff name="ApprovingAgency" [editMode]="true" [required]="false" type="text" label="Approving Agency" formControlName="ApprovingAgency">
            </esa-material-ff>

            <esa-material-ff name="AgencyContact" [editMode]="true" [required]="false" type="text" label="Agency Contact" formControlName="AgencyContact">
            </esa-material-ff>

            <esa-material-ff name="AgencySignatory" [editMode]="true" [required]="false" type="text" label="Agency Signatory" formControlName="AgencySignatory">
            </esa-material-ff>

            <div class="form-group flex-between gap">
                <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
                <div class="value">
                    <div class="grid-12">
                        <div class="g-col-12">
                            <editor class="mb-2" #tinyMceEditor [init]="tinyMceEditor | tinyMceConfig" formControlName="Description"></editor>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group flex-between gap">
                <custom-form-label label="Agreement Terms" fieldDefinitionType="AgreementTerms"></custom-form-label>
                <div class="value">
                    <div class="grid-12">
                        <div class="g-col-12">
                            <editor class="mb-2" #tinyMceEditor [init]="tinyMceEditor | tinyMceConfig" formControlName="AgreementTerms"></editor>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <kv-pair key="Files" [value]="fileDropper">
                    <ng-template #fileDropper>
                        <file-drop-upload uploadFileAccepts=".pdf,.csv,.xlsx" [multiple]="true" (filesChanged)="onFileUpload($event)"></file-drop-upload>
                        <ul class="file-max-height">
                            <li *ngFor="let file of formGroup.controls.NewSourceDocumentFiles.value">
                                <simple-file-display
                                    [isNotYetUploaded]="true"
                                    [fileName]="file.name"
                                    [displayDelete]="true"
                                    (deleteFile)="deleteNewFile(file)"></simple-file-display>
                            </li>
                            <li *ngFor="let file of formGroup.controls.SourceDocumentFiles.value">
                                <simple-file-display
                                    [fileName]="file.Name"
                                    [displayDelete]="true"
                                    [downloadUrl]="'sources/' + data.SourceDocument.SourceID + '/files/' + file.SourceFileID"
                                    (deleteFile)="deleteExistingFile(file)"></simple-file-display>
                            </li>
                        </ul>
                    </ng-template>
                </kv-pair>
            </div>
        </form>

        <alert-box [alertContext]="AlertContext.Danger" *ngIf="duplicateFileNames.length > 0" [alertContext]="AlertContext.Danger"
            ><strong>Warning:</strong> You are uploading {{ duplicateFileNames.length }} file(s) that already exist. Are you sure you want to override the
            following files? {{ duplicateFileNames.join(", ") }}</alert-box
        >
    </div>

    <div mat-dialog-actions align="end">
        <span [matTooltip]="!formGroup.valid ? 'You are missing required fields.' : ''">
            <button mat-flat-button color="primary" style="margin-right: 1rem" (click)="onSave()" cdkFocusInitial [disabled]="!formGroup.valid || isLoading">
                Save
            </button>
        </span>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
