<div class="beacon-snackbar">
    <div class="beacon-snackbar__message {{ data.alert.context }}">
        @switch (data.alert.context) {
            @case (AlertContext.Success) {
                <mat-icon>check</mat-icon>
            }
            @case (AlertContext.Danger) {
                <mat-icon>close</mat-icon>
            }
            @case (AlertContext.Info) {
                <mat-icon>info</mat-icon>
            }
            @case (AlertContext.Primary) {
                <mat-icon>info</mat-icon>
            }
            @case (AlertContext.Warning) {
                <mat-icon>warning</mat-icon>
            }
        }

        <span>{{ data.alert.message }}</span>
    </div>
    @if (data.alert.dismissable) {
        <div class="beacon-snackbar__actions">
            <button mat-button color="primary" (click)="dismiss()">Dismiss</button>
        </div>
    }
</div>

<!-- 
    
    Success = "success",
    Danger = "danger",
    Warning = "warning",
    Info = "info",
     -->
