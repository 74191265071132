import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CustomPageService } from "src/app/shared/generated/api/custom-page.service";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { UtilityFunctionsService } from "src/app/services/utility-functions.service";
import { Router } from "@angular/router";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { RefreshCustomPagesService } from "src/app/shared/services/refresh-custom-pages/refresh-custom-pages.service";
import { Subscription } from "rxjs";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";
import { CustomRichTextComponent } from "../../shared/components/custom-rich-text/custom-rich-text.component";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { ConfirmService } from "src/app/services/confirm.service";

@Component({
    selector: "beacon-custom-page-list",
    templateUrl: "./custom-page-list.component.html",
    styleUrls: ["./custom-page-list.component.scss"],
    standalone: true,
    imports: [NgIf, CustomRichTextComponent, ClearGridFiltersButtonComponent, MatButton, MatIcon, AgGridAngular, GridActionsComponent],
})
export class CustomPageListComponent implements OnInit, OnDestroy {
    @ViewChild("pageGrid") pageGrid: AgGridAngular;

    public currentUser: UserDto;
    public canCreate: boolean;
    public canEdit: boolean;
    public canDelete: boolean;

    public rowData = [];
    public columnDefs: ColDef[];

    public richTextTypeID: number = CustomRichTextTypeEnum.CustomPages;
    public isPerformingAction: boolean = false;

    userSubscription: Subscription;
    customPageSubscription: Subscription;
    deletePageSubscription: Subscription;

    constructor(
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private authenticationService: AuthenticationService,
        private customPageService: CustomPageService,
        private refreshCustomPagesService: RefreshCustomPagesService,
        private utilityFunctionService: UtilityFunctionsService,
        private router: Router,
        private gridCommonService: GridCommonService,
        private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.canCreate = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Create);
            this.canEdit = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Update);
            this.canDelete = this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomPageRights, RightsEnum.Delete);
            this.createColumnDefs();
        });
    }

    addPage() {
        this.router.navigateByUrl("manage/custom-pages/create");
    }

    onFirstDataRendered(params): void {
        this.pageGrid.api.sizeColumnsToFit();
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.customPageSubscription?.unsubscribe();
        this.deletePageSubscription?.unsubscribe();
    }

    navigateToEditPage(rowParams) {
        this.router.navigateByUrl(`manage/custom-pages/edit-properties/${rowParams.rowData.CustomPageVanityUrl}`);
    }

    createColumnDefs() {
        this.columnDefs = [];
        let component = this;
        if (this.canEdit) {
            this.columnDefs.push({
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: {
                    onClick: function (params) {
                        component.navigateToEditPage(params);
                    },
                    icon: "edit",
                },
                cellStyle: (params) => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                width: 80,
            });
        }

        this.columnDefs.push(
            {
                headerName: "Page Name",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.CustomPageVanityUrl,
                        LinkDisplay: params.data.CustomPageDisplayName,
                    };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/custom-pages/"),
                comparator: this.utilityFunctionService.linkRendererComparator,
                filter: true,
                filterValueGetter: function (params: any) {
                    return params.data.CustomPageDisplayName;
                },
                flex: 2,
            },
            {
                headerName: "Menu",
                field: "MenuItem.MenuItemDisplayName",
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "MenuItem.MenuItemDisplayName",
                },
                flex: 1,
            },
            {
                headerName: "Content?",
                field: "IsEmptyContent",
                valueGetter: function (params) {
                    return params.data.IsEmptyContent ? "No" : "Yes";
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "IsEmptyContent",
                },
                flex: 1,
            },
            {
                headerName: "Viewable By",
                field: "ViewableRoles",
                valueGetter: function (params) {
                    return params.data.ViewableRoles.map((x) => x.RoleName);
                },
                valueFormatter: function (params) {
                    return params.data.ViewableRoles.map((x) => x.RoleName).join(", ");
                },
                filter: CustomDropdownFilterComponent,
                filterParams: {
                    field: "RoleName",
                    columnContainsMultipleValues: true,
                },
                flex: 2,
            }
        );

        if (this.canDelete) {
            this.columnDefs.push({
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: {
                    onClick: function (params) {
                        component.deleteCustomPage(params.rowData.CustomPageID, params.rowData.CustomPageDisplayName);
                    },
                    icon: "delete",
                    type: "clear",
                },
                cellStyle: (params) => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                width: 80,
            });
        }
    }

    updateGridData() {
        this.customPageSubscription = this.customPageService.customPagesWithRolesGet().subscribe((customPagesWithRoles) => {
            this.rowData = customPagesWithRoles;
            this.pageGrid.api.hideOverlay();
            this.cdr.detectChanges();
        });
    }

    onGridReady(gridEvent: any) {
        this.pageGrid?.api.showLoadingOverlay();
        this.updateGridData();
    }

    public deleteCustomPage(customPageID: number, customPageDisplayName: string) {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Delete Custom Page`,
                text: `You are about to delete custom page ${customPageDisplayName}. This action cannot be undone. Are you sure you wish to proceed?`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.isPerformingAction = true;
                this.deletePageSubscription = this.customPageService.customPagesCustomPageIDDelete(customPageID).subscribe(
                    () => {
                        this.isPerformingAction = false;
                        this.alertService.pushAlert(new Alert(`Custom page successfully deleted.`, AlertContext.Success, true));
                        this.updateGridData();
                        this.refreshCustomPagesService.triggerRefresh();
                    },
                    (error) => {
                        this.isPerformingAction = false;
                        this.alertService.pushAlert(new Alert(`There was an error deleting the page. Please try again.`, AlertContext.Danger, true));
                    }
                );
            });
    }
}
