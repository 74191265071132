@if (isLoading) {
    <loading-spinner [strokeWidth]="1" [diameter]="20" />
}

<div class="d-inline" *ngIf="fieldDefinition$ | async as fieldDefinition">
    <ng-template #popTitle>
        <div class="field-definition-title flex-between">
            <div class="mt-1">{{ fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName }}</div>
            <button mat-button color="primary" *ngIf="showEditButton()" [hidden]="isEditing" (click)="enterEdit()"><mat-icon>edit</mat-icon></button>
        </div>
    </ng-template>
    <ng-template #popContent>
        <ng-container *ngIf="!isEditing">
            <div *ngIf="showEditButton()" style="position: relative; min-height: 30px">
                <div *ngIf="!emptyContent" [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
                <div *ngIf="emptyContent" class="hoverEmptySpace"></div>
            </div>
            <div *ngIf="!showEditButton()" [innerHtml]="fieldDefinition.FieldDefinitionValue"></div>
        </ng-container>
        <div *ngIf="isEditing">
            <tinymce-editor #tinyMceEditor name="FullText" [(content)]="editedContent"></tinymce-editor>
            <div class="actions-bar" style="margin-top: 1rem">
                <button mat-flat-button color="primary" (click)="saveEdit()" style="margin-right: 1rem"><mat-icon>save</mat-icon> Save</button>
                <button mat-button color="primary" (click)="cancelEdit()"><mat-icon>cancel</mat-icon> Cancel</button>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="showEditButton()">
        <div class="d-inline-block">
            <span style="overflow: hidden; text-overflow: ellipsis">
                <span *ngIf="!iconOnly">{{ getLabelText() }}</span>
                <mat-icon class="help-icon" [popper]="popContent" [popperTitle]="popTitle" [popperOptions]="popperOptions">help_outline</mat-icon>
            </span>
        </div>
    </ng-container>
    <ng-container *ngIf="!showEditButton()">
        <span>
            <span *ngIf="!iconOnly">{{ getLabelText() }}</span>
            <mat-icon class="help-icon" [popper]="popContent" [popperTitle]="popTitle" [popperOptions]="popperOptions">help_outline</mat-icon>
        </span>
    </ng-container>
</div>
