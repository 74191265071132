import { Component, Input } from "@angular/core";
import { BeaconEntityType, BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "pill",
    standalone: true,
    imports: [BeaconEntityIconComponent],
    templateUrl: "./pill.component.html",
    styleUrl: "./pill.component.scss",
})
export class PillComponent {
    @Input() backgroundColor: string = "#43608A";
    @Input() color: string = "#fff";
    @Input() entityIcon: typeof BeaconEntityType = null;
}
