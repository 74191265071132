<div class="component-summary">
    <div class="component-summary__header">
        <div>
            <a [routerLink]="['projects', component.ProjectID, 'components', component.ComponentID]">
                <h2><beacon-entity-icon entity="Component" matTooltip="Component" /> {{ component.Name }}</h2>
            </a>
        </div>

        <div class="flex-between gap">
            <pill-component-status [componentStatusID]="component.Status.ComponentStatusID" />
            <div matTooltip="There Are No Files On This Component" [matTooltipDisabled]="hasFiles">
                <button
                    mat-icon-button
                    color="primary"
                    [loadingButton]="isLoadingOne"
                    [disabled]="!hasFiles"
                    (click)="downloadCoversheetWithFiles()"
                    class="small-icon-button"
                    matTooltip="Download Component Coversheet With Files">
                    <mat-icon>list_alt_check</mat-icon>
                </button>
            </div>

            <button
                mat-icon-button
                [loadingButton]="isLoadingTwo"
                (click)="downloadCoversheet()"
                class="small-icon-button"
                matTooltip="Download Only Component Coversheet">
                <mat-icon>list_alt</mat-icon>
            </button>
        </div>
    </div>

    @if (component.Description) {
        <div class="component-summary__content">
            <div [innerHTML]="component.Description"></div>
        </div>
    }

    <div class="component-summary__footer">
        <div>
            Associated Commitments: <strong>{{ component.CountCommitments }}</strong>
        </div>
        <div>
            Complete Compliance Requirements: <strong>{{ component.CountCompletedChecklistItems }}/{{ component.CountChecklistItems }}</strong>
        </div>
    </div>
</div>
