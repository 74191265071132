import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError as _throw } from "rxjs";
import { AlertService } from "../alert.service";
import { Alert } from "src/app/shared/models/alert";
// import { OAuthService } from 'angular-oauth2-oidc';
import { AlertContext } from "../../models/enums/alert-context.enum";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    constructor(
        private alertService: AlertService,
        // private oauthService: OAuthService,
        private router: Router
    ) {}

    public handleError(error: any, supressErrorMessage = false, clearBusyGlobally = true): Observable<any> {
        return _throw(error);
    }
}
