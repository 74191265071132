/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type GeoJSONObjectType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const GeoJSONObjectType = {
    Point: 1 as GeoJSONObjectType,
    MultiPoint: 2 as GeoJSONObjectType,
    LineString: 3 as GeoJSONObjectType,
    MultiLineString: 4 as GeoJSONObjectType,
    Polygon: 5 as GeoJSONObjectType,
    MultiPolygon: 6 as GeoJSONObjectType,
    GeometryCollection: 7 as GeoJSONObjectType,
    Feature: 8 as GeoJSONObjectType,
    FeatureCollection: 9 as GeoJSONObjectType
};
