import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, tap } from "rxjs";
import { ChecklistTaskDto } from "../../generated/model/checklist-task-dto";
import { ChecklistTaskDialogComponent } from "./checklist-task-dialog/checklist-task-dialog-component";
import { ChecklistTaskService } from "../../generated/api/checklist-task.service";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule } from "@angular/forms";
import { ChecklistTaskUpsertDto } from "../../generated/model/checklist-task-upsert-dto";
import { ConfirmService } from "src/app/services/confirm.service";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { Sort } from "@angular/material/sort";
import { MatSortModule } from "@angular/material/sort";

@Component({
    selector: "checklist-task-grid",
    standalone: true,
    templateUrl: "./checklist-task-grid-component.html",
    styleUrls: ["./checklist-task-grid-component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MatTableModule, MatButtonModule, MatIconModule, FormsModule, LoadingSpinnerComponent, MatSortModule],
})
export class ChecklistTaskGridComponent implements OnInit {
    public dataSource = new MatTableDataSource<ChecklistTaskDto>();
    public _checklistItemID: string;
    public _componentID: string;
    public checklistTasks$: Observable<ChecklistTaskDto[]>;
    tasks: ChecklistTaskDto[];
    sortedTasks: ChecklistTaskDto[];

    @Input() set componentID(value: string) {
        if (value) {
            this._componentID = value;
        }
    }

    @Input() set checklistItemID(value: string) {
        if (value) {
            this._checklistItemID = value;
        }
    }

    displayedColumns: string[] = ["name", "description", "assignedTo", "owner", "dueDate", "actions"];

    constructor(
        private dialog: MatDialog,
        private checklistTaskService: ChecklistTaskService,
        private confirmService: ConfirmService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<ChecklistTaskDto>();
        this.loadChecklistTasks();
    }

    convertoToUpsertDto(checklistTask: ChecklistTaskDto): ChecklistTaskUpsertDto {
        return {
            TaskName: checklistTask.TaskName,
            Description: checklistTask.Description,
            AssignedTo: checklistTask.AssignedTo,
            OwnerUserID: checklistTask.Owner.UserID,
            DueDate: checklistTask.DueDate,
            ChecklistItemID: checklistTask.ChecklistItemID,
            ChecklistTaskID: checklistTask.ChecklistTaskID,
        };
    }

    private loadChecklistTasks(): void {
        if (this._componentID && this._checklistItemID) {
            this.checklistTasks$ = this.checklistTaskService
                .componentsComponentIDChecklistsChecklistItemsChecklistItemIDTasksGet(this._componentID, this._checklistItemID)
                .pipe(
                    tap((tasks) => {
                        this.tasks = tasks;
                        this.sortedTasks = tasks.slice();
                        this.dataSource.data = this.sortedTasks;
                        this.sortData({ active: "dueDate", direction: "asc" });
                    })
                );
            this.cdr.markForCheck();
        }
    }

    sortData(sort: Sort) {
        const data = this.tasks.slice();
        if (!sort.active || sort.direction === "") {
            this.sortedTasks = data;
            return;
        }

        this.sortedTasks = data.sort((a, b) => {
            const isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "dueDate":
                    var dateA = new Date(a.DueDate);
                    var dateB = new Date(b.DueDate);
                    return (dateA < dateB ? -1 : 1) * (isAsc ? 1 : -1);
                default:
                    return 0;
            }
        });
        this.dataSource.data = this.sortedTasks;
    }

    addTask(): void {
        const newChecklistTask: ChecklistTaskUpsertDto = {
            TaskName: "",
            Description: "",
            AssignedTo: "",
            OwnerUserID: null,
            DueDate: "",
            ChecklistItemID: this._checklistItemID,
        };

        const dialogRef = this.dialog.open(ChecklistTaskDialogComponent, {
            width: "800px",
            data: { checklistTask: newChecklistTask },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                result.DueDate = new Date(result.DueDate).toUTCString();
                this.checklistTaskService
                    .componentsComponentIDChecklistsChecklistItemsChecklistItemIDTasksPost(this._componentID, this._checklistItemID, result)
                    .subscribe((r) => {
                        if (r) {
                            this.loadChecklistTasks();
                        }
                    });
            }
        });
    }

    editTask(checklistTask: ChecklistTaskDto) {
        const dialogRef = this.dialog.open(ChecklistTaskDialogComponent, {
            width: "700px",
            data: { checklistTask: this.convertoToUpsertDto(checklistTask) },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.checklistTaskService
                    .componentsComponentIDChecklistsChecklistItemsChecklistItemIDTasksChecklistTaskIDPut(
                        this._componentID,
                        this._checklistItemID,
                        checklistTask.ChecklistTaskID,
                        result
                    )
                    .subscribe((result) => {
                        if (result) {
                            this.loadChecklistTasks();
                        }
                    });
            }
        });
    }

    deleteTask(checklistTask: ChecklistTaskDto) {
        this.confirmService.confirm({ color: "warn", header: `Delete Task`, text: `Are you sure you want to delete this task?` }).subscribe((result) => {
            if (!result) {
                return;
            }
            this.checklistTaskService
                .componentsComponentIDChecklistsChecklistItemsChecklistItemIDTasksChecklistTaskIDDelete(
                    this._componentID,
                    this._checklistItemID,
                    checklistTask.ChecklistTaskID
                )
                .subscribe((result) => {
                    this.loadChecklistTasks();
                });
        });
    }
}
