/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SourceDto } from '././source-dto';
import { ProjectRoutingDto } from '././project-routing-dto';
import { CommitmentTypeDto } from '././commitment-type-dto';
import { CommitmentVersionStatusDto } from '././commitment-version-status-dto';
import { UserDto } from '././user-dto';
import { CommitmentFileDto } from '././commitment-file-dto';
import { ResourceCategoryDto } from '././resource-category-dto';
import { UserSimpleDto } from '././user-simple-dto';

export class CommitmentDto { 
    CommitmentID?: string;
    CommitmentVersionChainID?: string;
    ParentCommitmentID?: string;
    ComplianceRequirementCount?: number;
    ComplianceTriggerCount?: number;
    RelatedCommitmentCount?: number;
    CommitmentFiles?: Array<CommitmentFileDto>;
    CommitmentVersionStatus?: CommitmentVersionStatusDto;
    VersionNumber?: number;
    IsLatestVersion?: boolean;
    ApprovedUser?: UserDto;
    ApprovedDate?: string;
    ClientCommitmentID?: string;
    Title?: string;
    CommitmentType?: CommitmentTypeDto;
    ResourceCategory?: ResourceCategoryDto;
    Source?: SourceDto;
    ProjectRoutingData?: ProjectRoutingDto;
    TechnicalLeadUsers?: Array<UserSimpleDto>;
    FullCommitmentText?: string;
    Seasonality?: string;
    WorkActivities?: string;
    LocationDescription?: string;
    GeneralGuidance?: string;
    SpeciesCode?: string;
    OriginatorOfChange?: string;
    SummaryOfChange?: string;
    CreateUser?: UserDto;
    UpdateUser?: UserDto;
    CreateDate?: string;
    UpdateDate?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
