import { Component, OnInit, HostListener, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { environment } from "src/environments/environment";
import { CustomPageService } from "../../generated/api/custom-page.service";
import { UserDto } from "../../generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { Observable, Subscription, combineLatest, filter, map, merge, shareReplay, startWith, switchMap, tap } from "rxjs";
import { TenantService } from "../../services/tenant/tenant-service.service";
import { TenantDto } from "../../generated/model/tenant-dto";
import { CustomPageWithRolesDto, ProjectDto, TenantModuleDto } from "../../generated/model/models";
import { ModuleEnum, TenantModuleService } from "../../services/tenant-module/tenant-module.service";
import { SystemInfoDialogComponent } from "./system-info-dialog/system-info-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { DropdownToggleDirective } from "../../directives/dropdown-toggle.directive";
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { NgIf, NgFor, NgClass, AsyncPipe } from "@angular/common";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { WithPermissionPipe } from "../../pipes/with-permission/with-permission.pipe";
import { UserIsImpersonatedPipe } from "../../pipes/user-is-impersonated/user-is-impersonated.pipe";
import { MenuItemEnum } from "../../generated/enum/menu-item-enum";
import { RefreshCustomPagesService } from "../../services/refresh-custom-pages/refresh-custom-pages.service";
import { ModuleIconComponent } from "../module-icon/module-icon.component";
import { MatButton, MatButtonModule, MatFabButton, MatMiniFabButton } from "@angular/material/button";
import { BeaconEntityIconComponent, BeaconEntityTypeEnum } from "../beacon-entity-icon/beacon-entity-icon.component";
import { MatTooltip } from "@angular/material/tooltip";
import { ProjectService } from "../../generated/api/project.service";
import { routeParams } from "src/app/app.routes";
import { RouteHelpers } from "../../services/router-helper/router-helper";
import { Alert } from "../../models/alert";
import { AlertContext } from "../../models/enums/alert-context.enum";
import {
    ProjectUpsertDialogComponent,
    IProjectUpsertDialogComponentData,
    IProjectUpsertDialogComponentResponse,
} from "../dialogs/project-upsert-dialog/project-upsert-dialog.component";
import { AlertService } from "../../services/alert.service";

@Component({
    selector: "header-nav",
    templateUrl: "./header-nav.component.html",
    styleUrls: ["./header-nav.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        RouterLinkActive,
        DropdownToggleDirective,
        NgFor,
        NgClass,
        MatIcon,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        AsyncPipe,
        WithPermissionPipe,
        UserIsImpersonatedPipe,
        ModuleIconComponent,
        MatButtonModule,
        MatMiniFabButton,
        MatFabButton,
        MatButton,
        BeaconEntityIconComponent,
        MatTooltip,
    ],
})
export class HeaderNavComponent implements OnInit, OnDestroy {
    PermissionEnum = PermissionEnum;
    RightsEnum = RightsEnum;
    ModuleEnum = ModuleEnum;
    BeaconEntityTypeEnum = BeaconEntityTypeEnum;
    public windowWidth: number;
    public environment = environment;

    public currentUser$: Observable<UserDto> = this.authenticationService.currentUser$.pipe(shareReplay(1));
    public allowedTenants$: Observable<TenantDto[]> = this.tenantService.allowedTenants$.pipe(shareReplay(1));
    public currentTenant$: Observable<TenantDto> = this.tenantService.currentTenant$.pipe(shareReplay(1));
    public allowedModules$: Observable<TenantModuleDto[]> = this.moduleService.allowedModules$.pipe(shareReplay(1));
    public customPages$: Observable<{ viewPages: CustomPageWithRolesDto[]; learnMorePages: CustomPageWithRolesDto[] }>;
    public projects$: Observable<ProjectDto[]>;
    public currentProject$: Observable<ProjectDto>;
    public systemInfo: any;
    public currentUser: UserDto;
    userSubscription: Subscription;

    @HostListener("window:resize", ["$event"])
    resize() {
        this.windowWidth = window.innerWidth;
    }

    constructor(
        private authenticationService: AuthenticationService,
        private tenantService: TenantService,
        private moduleService: TenantModuleService,
        private customPageService: CustomPageService,
        private dialog: MatDialog,
        private refreshCustomPagesService: RefreshCustomPagesService,
        private projectService: ProjectService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private alertService: AlertService
    ) {}

    ngOnInit() {
        // custom pages need to be updated when switching tenants or when a custom page has been updated/created/deleted
        const observables = combineLatest([this.tenantService.currentTenant$.asObservable(), this.refreshCustomPagesService.refreshCustomPagesEvent$]);
        this.customPages$ = observables.pipe(
            switchMap((x) => this.customPageService.customPagesWithRolesGet()),
            map((customPages) => {
                return {
                    viewPages: customPages.filter((x) => x.MenuItem.MenuItemID == MenuItemEnum.View),
                    learnMorePages: customPages.filter((x) => x.MenuItem.MenuItemID == MenuItemEnum.LearnMore),
                };
            })
        );
        this.projects$ = observables.pipe(
            switchMap((x) =>
                this.projectService.projectsGet().pipe(
                    tap((projects) => {
                        this.currentProject$ = this.router.events.pipe(
                            filter((event) => event instanceof NavigationEnd),
                            startWith(null),
                            map(() => {
                                const fullRoute = RouteHelpers.getCurrentRouteFromActivatedRouteSnapshot(this.route.snapshot);
                                if (Object.keys(fullRoute.params).includes(routeParams.projectID)) {
                                    const projectID = fullRoute.params[routeParams.projectID];
                                    return projects.find((project) => project.ProjectID === projectID);
                                }
                                return null;
                            })
                        );
                    })
                )
            )
        );
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {}

    public login(): void {
        this.authenticationService.login();
    }

    public logout(): void {
        this.authenticationService.logout();
    }

    public editProfile(): void {
        this.authenticationService.editProfile();
    }

    public changeTenant(tenant: TenantDto) {
        this.tenantService.setCurrentTenant(tenant);
    }

    public viewSystemInfo(tenant: TenantDto) {
        this.dialog.open(SystemInfoDialogComponent, {
            width: "fit-content",
            disableClose: false,
        });
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }

    createProject(project: ProjectDto = null) {
        const dialogRef = this.dialog.open<ProjectUpsertDialogComponent, IProjectUpsertDialogComponentData, IProjectUpsertDialogComponentResponse>(
            ProjectUpsertDialogComponent,
            {
                width: "1000px",
                data: {
                    Project: project,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigateByUrl(`/compliance-tracking/projects/${result.ProjectID}`).then(() => {
                    this.alertService.pushAlert(new Alert("The project was successfully created.", AlertContext.Success), 5000);
                });
            }
        });
    }
}
