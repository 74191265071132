import { AsyncPipe, NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { AgGridEvent } from "ag-grid-community";
import { Observable, merge } from "rxjs";
import { map } from "rxjs/operators";
import { ClearGridFiltersButtonComponent } from "../../clear-grid-filters-button/clear-grid-filters-button.component";
import { CsvDownloadButtonComponent } from "../../csv-download-button/csv-download-button.component";
import { FormsModule, NgModel } from "@angular/forms";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { MatFormField } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";

@Component({
    selector: "beacon-grid-actions",
    templateUrl: "./grid-actions.component.html",
    styleUrls: ["./grid-actions.component.scss"],
    standalone: true,
    imports: [
        NgTemplateOutlet,
        MatInput,
        ClearGridFiltersButtonComponent,
        CsvDownloadButtonComponent,
        AgGridAngular,
        AsyncPipe,
        NgIf,
        FormsModule,
        MatFormField,
    ],
})
export class GridActionsComponent implements OnInit {
    private _grid: AgGridAngular;
    public quickFilterText: string;

    @Input() set grid(grid: AgGridAngular) {
        this._grid = grid;
        this.initRowCountObservable();
    }

    get grid(): AgGridAngular {
        return this._grid;
    }

    @Input() enableDownload: boolean = true;
    @Input() downloadFileName: string = "grid-download";
    @Input() downloadColIDsToExclude: Array<string> = [];
    @Input() disableGlobalFilter: boolean = false;

    @Input() templatePrepend: TemplateRef<any>;

    rowCount$: Observable<GridActionsRecordCount>;

    constructor() {}

    ngOnInit(): void {}

    initRowCountObservable(): void {
        if (this.grid == undefined) return;

        this.rowCount$ = merge(this.grid?.filterChanged.asObservable(), this.grid?.rowDataUpdated.asObservable()).pipe(
            map((x: AgGridEvent) => {
                const gridCount = {
                    total: this.grid?.rowData?.length ?? 0,
                    visible: x.api.getDisplayedRowCount(),
                } as GridActionsRecordCount;
                return gridCount;
            })
        );
    }

    onQuickFilterTextChanged() {
        this.grid.api.setGridOption("quickFilterText", this.quickFilterText);
    }

    filtersCleared() {
        this.quickFilterText = "";
        this.grid.api.setGridOption("quickFilterText", this.quickFilterText);
    }
}

export interface GridActionsRecordCount {
    total: number;
    visible: number;
}
