<h1 mat-dialog-title>Select from Full Text</h1>
<div mat-dialog-content>
    <div [innerHTML]="data.FullCommitmentText | bypassHtml" class="value-display ck-content" (mouseup)="bindSelection($event)"></div>
</div>

<div mat-dialog-actions>
    <button mat-button color="primary" [disabled]="!selection" [mat-dialog-close]="selection" style="margin-right: 1rem">
        <mat-icon>done</mat-icon> Select
    </button>
    <button mat-button mat-dialog-close cdkFocusInitial><mat-icon>cancel</mat-icon> Cancel</button>
</div>
