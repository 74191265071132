import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatIcon } from "@angular/material/icon";

export const MONTH_DAY_FORMATS = {
    parse: {
        dateInput: "MMM - DD",
    },
    display: {
        dateInput: "MMM - DD",
        monthYearLabel: "MMM",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY",
    },
};

/** Custom header component for datepicker. */
@Component({
    selector: "example-header",
    styles: [
        `
            .example-header {
                display: flex;
                align-items: center;
                padding: 0.5em;
            }

            .example-header-label {
                flex: 1;
                height: 1em;
                font-weight: 500;
                text-align: center;
            }

            .example-double-arrow .mat-icon {
                margin: -22%;
            }
        `,
    ],
    template: `
        <div class="example-header">
            <button mat-icon-button (click)="previousClicked('month')">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <span class="example-header-label">{{ periodLabel }}</span>
            <button mat-icon-button (click)="nextClicked('month')">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    `,
    standalone: true,
    imports: [MatIcon],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeader<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy() {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._dateAdapter.format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel).toLocaleUpperCase();
    }

    previousClicked(mode: any) {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
    }

    nextClicked(mode: any) {
        this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
    }
}
