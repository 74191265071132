import { AsyncPipe, NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";
import { EsaMaterialFormFieldComponent, EsaValueDisplayComponent } from "esa-material-form-field";
import { Observable, share, shareReplay, tap } from "rxjs";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CustomFormLabelComponent } from "../../custom-form-label/custom-form-label.component";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";

@Component({
    selector: "commitment-summary-dialog",
    standalone: true,
    templateUrl: "./commitment-summary-dialog.component.html",
    styleUrl: "./commitment-summary-dialog.component.scss",
    imports: [
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        MatDialogTitle,
        NgIf,
        AsyncPipe,
        MatButton,
        EsaValueDisplayComponent,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        RouterLink,
        BypassHtmlPipe,
    ],
})
export class CommitmentSummaryDialogComponent {
    public commitment$: Observable<CommitmentDto>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CommitmentSummaryDialogData,
        private commitmentService: CommitmentService
    ) {}

    ngOnInit(): void {
        this.commitment$ = this.commitmentService.commitmentsCommitmentIDGet(this.data.CommitmentID);
    }
}

export interface CommitmentSummaryDialogData {
    CommitmentID: string;
}
