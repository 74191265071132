import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { EvidenceOfComplianceService } from "../../generated/api/evidence-of-compliance.service";
import { EvidenceOfComplianceDto } from "../../generated/model/evidence-of-compliance-dto";
import { map, tap } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import {
    EvidenceOfComplianceUpsertDialogComponent,
    IEvidenceOfComplianceUpsertDialogComponentData,
    IEvidenceOfComplianceUpsertDialogComponentResponse,
} from "../dialogs/evidence-of-compliance-upsert-dialog/evidence-of-compliance-upsert-dialog.component";
import { FileDropUploadComponent } from "../file-drop-upload/file-drop-upload.component";
import { CommonModule } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { EvidenceOfComplianceFileDto } from "../../generated/model/evidence-of-compliance-file-dto";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { EvidenceOfComplianceSelectExistingDialogComponent } from "../dialogs/evidence-of-compliance-select-existing-dialog/evidence-of-compliance-select-existing-dialog.component";
import { SimpleFileDisplayComponent } from "../simple-file-display/simple-file-display.component";
import { KvPairComponent } from "../kv-pair/kv-pair.component";
import { MatTooltip } from "@angular/material/tooltip";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { IButtonRendererClickEvent } from "../ag-grid/button-renderer/button-renderer.component";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { EvidenceOfComplianceFileService } from "../../generated/api/evidence-of-compliance-file.service";
import { AlertService } from "../../services/alert.service";
import { Alert } from "../../models/alert";
import { AlertContext } from "../../models/enums/alert-context.enum";
import { LoadingButtonDirective } from "../../directives/loading-button/loading-button.directive";

@Component({
    selector: "evidence-of-compliance-upsert",
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        FileDropUploadComponent,
        EvidenceOfComplianceUpsertDialogComponent,
        SimpleFileDisplayComponent,
        KvPairComponent,
        MatTooltip,
        LoadingSpinnerComponent,
        RouterLink,
        LoadingButtonDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./evidence-of-compliance-upsert.component.html",
    styleUrls: ["./evidence-of-compliance-upsert.component.scss"],
})
export class EvidenceOfComplianceUpsertComponent implements OnInit {
    existingEvidencesOnChecklistItem$: Observable<EvidenceOfComplianceDto[]>;
    isLoading = false;
    public _componentID: string;
    public _checklistItemID: string;
    public hasEvidence: boolean = false;
    public projectID: string;
    componentExistingEvidence$: Observable<EvidenceOfComplianceDto[]>;

    @Input() set componentID(value: string) {
        if (value) {
            this._componentID = value;
        }
    }
    @Input() set checklistItemID(value: string) {
        if (value) {
            this._checklistItemID = value;
        }
    }

    @Output() closeEvent = new EventEmitter<void>();
    @Output() saveEvent = new EventEmitter<void>();
    @Output() fileInputChangedEvent = new EventEmitter<any>();

    constructor(
        private fb: FormBuilder,
        private evidenceOfComplianceService: EvidenceOfComplianceService,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private evidenceOfComplianceFileService: EvidenceOfComplianceFileService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.fetchExistingEvidences();
        this.projectID = this.route.snapshot.params["projectID"];
    }

    fetchExistingEvidences(): void {
        this.hasEvidence = false;
        this.existingEvidencesOnChecklistItem$ = this.evidenceOfComplianceService
            .componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceGet(this._componentID, this._checklistItemID)
            .pipe(
                tap((evidences) => {
                    this.componentExistingEvidence$ = this.evidenceOfComplianceService.componentsComponentIDEvidenceOfComplianceGet(this._componentID).pipe(
                        map((componentEvidences) => {
                            const existingEvidenceOfComplianceIDs = evidences.map((existingEvidence) => existingEvidence.EvidenceOfComplianceID);
                            return componentEvidences.filter((componentEvidence) => {
                                return !existingEvidenceOfComplianceIDs.includes(componentEvidence.EvidenceOfComplianceID);
                            });
                        })
                    );
                    if (evidences.length > 0) {
                        this.hasEvidence = true;
                    }
                })
            );
    }

    addExistingEvidence(availableEvidence: EvidenceOfComplianceDto[]) {
        const dialogRef = this.dialog.open(EvidenceOfComplianceSelectExistingDialogComponent, {
            width: "700px",
            data: {
                componentID: this._componentID,
                availableEvidencesOnComponent: availableEvidence,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.evidenceOfComplianceService
                    .componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfCompliancePost(this._componentID, this._checklistItemID, result)
                    .subscribe((response) => {
                        this.fetchExistingEvidences();
                        this.cdr.markForCheck();
                    });
            }
        });
    }

    addEvidence() {
        const dialogRef = this.dialog.open<
            EvidenceOfComplianceUpsertDialogComponent,
            IEvidenceOfComplianceUpsertDialogComponentData,
            IEvidenceOfComplianceUpsertDialogComponentResponse
        >(EvidenceOfComplianceUpsertDialogComponent, {
            width: "700px",
            data: {
                EvidenceOfCompliance: new EvidenceOfComplianceDto({ EvidenceOfComplianceID: "" }),
                ComponentID: this._componentID,
                ChecklistItemID: this._checklistItemID,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.fetchExistingEvidences();
                this.cdr.markForCheck();
            }
        });
    }

    editEvidence(evidence: EvidenceOfComplianceDto) {
        const dialogRef = this.dialog.open<
            EvidenceOfComplianceUpsertDialogComponent,
            IEvidenceOfComplianceUpsertDialogComponentData,
            IEvidenceOfComplianceUpsertDialogComponentResponse
        >(EvidenceOfComplianceUpsertDialogComponent, {
            width: "700px",
            data: {
                ChecklistItemID: this._checklistItemID,
                EvidenceOfCompliance: evidence,
                ComponentID: this._componentID,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.fetchExistingEvidences();
                this.cdr.markForCheck();
            }
        });
    }

    deleteEvidence(evidence: EvidenceOfComplianceDto) {
        this.confirmService
            .confirm({ color: "warn", header: `Delete Evidence`, text: `Are you sure you want to delete this evidence?` })
            .subscribe((result) => {
                if (!result) {
                    return;
                }
                this.evidenceOfComplianceService.evidenceOfComplianceEvidenceOfComplianceIDDelete(evidence.EvidenceOfComplianceID).subscribe(() => {
                    this.fetchExistingEvidences();
                    this.cdr.markForCheck();
                });
            });
    }

    public isDownloadingZipForSingleEvidence = false;

    downloadZipForSingleEvidence(evidenceOfComplianceID: string) {
        this.isDownloadingZipForSingleEvidence = true;
        combineLatest([
            this.evidenceOfComplianceFileService.evidenceOfComplianceFileEvidenceOfComplianceIDAllFilesDownloadGet(evidenceOfComplianceID),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.isDownloadingZipForSingleEvidence = false;
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
            this.cdr.markForCheck();
        });
    }

    public isDownloadingAllFilesForChecklistItem = false;

    downloadZipForAllFilesForChecklistItem() {
        this.isDownloadingAllFilesForChecklistItem = true;
        combineLatest([
            this.evidenceOfComplianceFileService.evidenceOfComplianceFileChecklistItemChecklistItemIDAllFilesDownloadGet(this._checklistItemID),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.isDownloadingAllFilesForChecklistItem = false;
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
        });
    }
}
