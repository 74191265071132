<div class="dashboard">
    @if (currentUser$ | async; as currentUser) {
        <aside class="sidebar">
            <ul class="sidebar-nav">
                @for (item of sideNavItems; track item) {
                    <!-- If the item has a permission and the current user has that permission, or if the item has no permission requirements, display the item. -->
                    @if ((item.Permission && currentUser | withPermission: item.Permission) || !item.Permission) {
                        <li class="sidebar-item">
                            <a [routerLink]="item.RouterLink" routerLinkActive="active" class="sidebar-link">
                                {{ item.Title }}
                                <span style="float: right" *ngIf="item.ItemCount || item.ItemCount == 0">({{ item.ItemCount }})</span>
                            </a>
                        </li>
                    }
                }
            </ul>
        </aside>
    }
    <div class="main">
        <router-outlet #manageOutlet="outlet"></router-outlet>
    </div>
</div>
