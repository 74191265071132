@if (!loadedLogs) {
    <loading-spinner [height]="'400px'" />
}

<div class="observation-details-wrapper" *ngIf="observationLogs$ | async">
    <mat-card-content>
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab class="observation-logs" label="OBSERVATION LOGS & DETAIL">
                <div class="grid-12">
                    <div class="g-col-6">
                        <mat-card appearance="outlined" class="g-col-6" *ngFor="let observationLog of observationLogs">
                            <mat-card-header style="align-items: center; justify-content: space-between; height: 100%">
                                <mat-card-title matTooltipShowDelay="500" matTooltipPosition="above">
                                    <div class="grid-12" style="font-weight: 100; font-size: 12px">
                                        <div clas="g-col-2">
                                            {{ observationLog["time"] }}
                                        </div>
                                        <div class="g-col-1" style="text-align: end">
                                            <mat-icon style="color: #04608d">visibility</mat-icon>
                                        </div>
                                        <div class="g-col-9" style="text-wrap: wrap">
                                            {{ observationLog["narrative"] }}
                                        </div>
                                    </div>
                                </mat-card-title>
                            </mat-card-header>
                        </mat-card>

                        <div *ngIf="observationLogs.length < 1">No Observation Logs found.</div>
                    </div>
                    <div class="g-col-6">
                        <form class="form grid-12" #observationDetailsForm="ngForm">
                            <div *ngIf="observation['follow_up_yes_no'] === 'no'" class="g-col-12">
                                <esa-material-ff name="Activity" [editMode]="false" type="text" [(ngModel)]="observation['_title']" label="Activity">
                                </esa-material-ff>
                            </div>
                            <div *ngIf="observation['follow_up_yes_no'] === 'yes'" class="g-col-12">
                                <esa-material-ff
                                    name="TrackableID"
                                    [editMode]="false"
                                    type="text"
                                    [(ngModel)]="observation['trackable_id']"
                                    label="Trackable ID">
                                </esa-material-ff>
                            </div>
                            <div class="g-col-6">
                                <esa-material-ff
                                    name="InitialDate"
                                    [editMode]="false"
                                    type="date-time"
                                    [(ngModel)]="observation['initial_date_of_trackable']"
                                    label="Initial Date of Trackable">
                                </esa-material-ff>
                            </div>
                            <div class="g-col-6">
                                <esa-material-ff
                                    name="Location"
                                    [editMode]="false"
                                    type="text"
                                    [(ngModel)]="observation['location_of_observation']"
                                    label="Location">
                                </esa-material-ff>
                            </div>
                            <div class="g-col-6">
                                <esa-material-ff name="Category" [editMode]="false" type="text" [(ngModel)]="observation['category']" label="Category">
                                </esa-material-ff>
                            </div>
                            <div class="g-col-6">
                                <esa-material-ff
                                    name="CategoryType"
                                    [editMode]="false"
                                    type="text"
                                    [(ngModel)]="observation['category_type']"
                                    label="Category Type">
                                </esa-material-ff>
                            </div>
                            <div class="g-col-6">
                                <esa-material-ff name="Condition" [editMode]="false" type="text" [(ngModel)]="observation['condition']" label="Condition">
                                </esa-material-ff>
                            </div>
                            <div class="g-col-6">
                                <esa-material-ff name="Notes" [editMode]="false" type="text" [(ngModel)]="observation['notes_internal_obs']" label="Notes">
                                </esa-material-ff>
                            </div>
                        </form>
                    </div>
                </div>
            </mat-tab>
            <mat-tab class="observation-details-information" label="PHOTOS & MAP">
                <ng-template matTabContent>
                    @if (!loadedPhotos) {
                        <loading-spinner [height]="'400px'" />
                    }
                    <div class="grid-12" *ngIf="observationLogPhotos$ | async">
                        <div class="g-col-6">
                            <div class="photo-tab-gallery-container grid-12">
                                <img
                                    class="g-col-2 image-thumbnail"
                                    [ngClass]="{ 'selected-thumbnail': image == selectedPhoto }"
                                    *ngFor="let image of observationLogPhotos; let i = index"
                                    [ngStyle]="{ 'margin-left': i === 0 ? '.25rem' : '0' }"
                                    [src]="image.photo.thumbnail"
                                    [title]="image.photo.$Caption"
                                    [alt]="image.photo.$Caption"
                                    (click)="imageClicked(image)" />

                                <div class="g-col-12" *ngIf="loadedPhotos && observationLogPhotos.length == 0">No photos to display.</div>
                            </div>
                            <div class="g-col-12" *ngIf="loadedPhotos && observationLogPhotos.length > 0" style="padding: 1rem 0 1rem 0">
                                <button mat-flat-button type="button" color="accent" (click)="viewPhotos()">
                                    <mat-icon>photo_camera</mat-icon> View Photo Gallery
                                </button>
                            </div>
                        </div>
                        <div class="g-col-6">
                            <div class="map-wrapper">
                                <map
                                    [allObjectsWithFeature]="allObservationLogFeatures"
                                    [filteredObjectsWithFeature]="filteredObservationLogFeatures"
                                    [selectedObject]="selectedFeature"
                                    [featureKey]="'_geometry'"
                                    [uniqueKey]="'_record_id'"
                                    [filterToExtent]="false"
                                    [displayFilterButtons]="false"
                                    (onFeatureSelected)="observationLogFeatureClicked($event)">
                                </map>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</div>

<ng-template #customItemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
    <div class="custom-gallery-caption">
        {{ data.alt }}
    </div>
</ng-template>
