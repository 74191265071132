import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from "@angular/core";
import { UserService } from "src/app/shared/generated/api/user.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ColDef } from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { CustomPinnedRowRendererComponent } from "src/app/shared/components/ag-grid/custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import { CustomDropdownFilterComponent } from "src/app/shared/components/custom-dropdown-filter/custom-dropdown-filter.component";
import { DateColumnCreatorService } from "src/app/shared/services/date-column-creator/date-column-creator.service";
import { Observable, Subscription } from "rxjs";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { Router } from "@angular/router";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { CsvDownloadButtonComponent } from "../../shared/components/csv-download-button/csv-download-button.component";
import { ClearGridFiltersButtonComponent } from "../../shared/components/clear-grid-filters-button/clear-grid-filters-button.component";

import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatButton } from "@angular/material/button";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

declare var $: any;

@Component({
    selector: "beacon-user-list",
    templateUrl: "./user-list.component.html",
    styleUrls: ["./user-list.component.scss"],
    standalone: true,
    imports: [NgIf, ClearGridFiltersButtonComponent, CsvDownloadButtonComponent, AgGridAngular, MatIcon, MatButton, GridActionsComponent],
})
export class UserListComponent implements OnInit, OnDestroy {
    @ViewChild("usersGrid") usersGrid: AgGridAngular;
    @ViewChild("unassignedUsersGrid") unassignedUsersGrid: AgGridAngular;
    public canCreateUser: boolean = false;
    public rowData = [];
    columnDefs: ColDef[];
    columnDefsUnassigned: ColDef[];
    users: UserDto[];
    frameworkComponents: any;

    user$: Observable<UserDto>;
    getUsersSubscription: Subscription;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private dateColumnCreator: DateColumnCreatorService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "ID",
                valueGetter: function (params: any) {
                    return params.node.rowPinned ? "Total: " + params.data.UserIDTotal : params.data.UserID;
                },
                cellRendererSelector: (params) => {
                    return {
                        component: CustomPinnedRowRendererComponent,
                        params: { filter: false },
                    };
                },
                type: "rightAligned",
                width: 90,
                filter: false,
            },
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return { LinkValue: params.data.UserID, LinkDisplay: params.data.FullName };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/manage/users/"),
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.FullName;
                },
                comparator: function (id1: any, id2: any) {
                    let link1 = id1.LinkDisplay;
                    let link2 = id2.LinkDisplay;
                    if (link1 < link2) {
                        return -1;
                    }
                    if (link1 > link2) {
                        return 1;
                    }
                    return 0;
                },
                flex: 4,
                tooltipField: "Name",
            },
            {
                headerName: "Email",
                field: "Email",
                flex: 4,
                tooltipField: "Email",
            },
            {
                headerName: "Role",
                field: "Role.RoleName",
                flex: 1,
                filter: CustomDropdownFilterComponent,
                filterParams: { field: "Role.RoleName" },
            },
            {
                headerName: "System Support?",
                headerTooltip: "Receives System Communications?",
                field: "ReceiveSupportEmails",
                valueGetter: function (params) {
                    if (params.node != null && params.node.rowPinned) {
                        return null;
                    }
                    return params.data.ReceiveSupportEmails ? "Yes" : "No";
                },
                filter: CustomDropdownFilterComponent,
                filterParams: { field: "ReceiveSupportEmails" },
                flex: 1,
            },
            this.dateColumnCreator.createDateColumnDef("Created", "CreateDate", "M/d/yyyy"),
        ];
    }

    ngOnInit() {
        this.user$ = this.authenticationService.getCurrentUser();
        this.user$.subscribe((user) => {
            this.canCreateUser = user && this.authenticationService.hasPermission(user, PermissionEnum.UserRights, RightsEnum.Create);
        });
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.getUsersSubscription?.unsubscribe();
    }

    onUsersGridReady(gridEvent: any) {
        this.usersGrid?.api.showLoadingOverlay();

        this.getUsersSubscription = this.userService.usersGet().subscribe((users) => {
            this.rowData = users;
            this.users = users;
            this.usersGrid.api.sizeColumnsToFit();
            this.usersGrid.api.hideOverlay();
        });
    }

    navigateToInviteUser() {
        this.router.navigateByUrl("/manage/users/invite");
    }
}
