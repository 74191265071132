@if (evidenceOfCompliance$ | async; as evidenceOfCompliance) {
    <page-body>
        <page-header [pageTitle]="evidenceOfCompliance.Title" [preTitle]="'Evidence of Compliance'" [templateRight]="templateRight">
            <ng-template #templateRight>
                <div class="header-actions">
                    <button
                        matTooltip="Download All Files as Zip"
                        [loadingButton]="downloading"
                        mat-icon-button
                        (click)="downloadFiles(evidenceOfCompliance.EvidenceOfComplianceID)">
                        <mat-icon>download</mat-icon>
                    </button>
                    <button mat-icon-button (click)="editEvidence(evidenceOfCompliance)"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button (click)="delete()" *ngIf="canDelete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-template>
            <div class="metadata">
                <kv-pair key="Created Date" [value]="evidenceOfCompliance.CreateDate | date"></kv-pair>
                <kv-pair key="Created By" [value]="evidenceOfCompliance.CreateUser?.FullName"></kv-pair>
            </div>
        </page-header>

        <div class="fields">
            <div class="grid-12">
                <div class="g-col-6"><kv-pair key="Notes" [value]="evidenceOfCompliance.Notes"></kv-pair></div>
                <div class="g-col-6">
                    <kv-pair key="Files" [value]="filesTemplate"></kv-pair>
                    <ng-template #filesTemplate>
                        @if (evidenceOfCompliance.EvidenceOfComplianceFiles.length > 0) {
                            <ul>
                                <li *ngFor="let file of evidenceOfCompliance.EvidenceOfComplianceFiles">
                                    <simple-file-display
                                        [fileName]="file.Name"
                                        [displayDelete]="false"
                                        [downloadUrl]="'evidence-of-compliance-file/' + file.EvidenceOfComplianceFileID"></simple-file-display>
                                </li>
                            </ul>
                        } @else {
                            <em>No Files</em>
                        }
                    </ng-template>
                </div>
            </div>
        </div>

        <hr />
        <div class="associatedChecklistItems">
            <kv-pair key="Associated Checklist Items" [value]="associatedChecklistItemsTemplate" />
            <ng-template #associatedChecklistItemsTemplate>
                @if (evidenceOfCompliance.EvidenceOfComplianceChecklistItems.length > 0) {
                    <ul>
                        <li *ngFor="let checklistItem of evidenceOfCompliance.EvidenceOfComplianceChecklistItems">
                            <button
                                matTooltip="Unassociate This Checklist Item From This Evidence Of Compliance"
                                mat-icon-button
                                (click)="unassociateChecklistItem(checklistItem.ChecklistItemID)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <a [routerLink]="['../../checklists/checklist-items', checklistItem.ChecklistItemID]">
                                <span class="ellipsis" [title]="checklistItem.ComplianceRequirementName">{{ checklistItem.ComplianceRequirementName }}</span>
                            </a>
                        </li>
                    </ul>
                } @else {
                    <em>No Associated Checklist Items</em>
                }
            </ng-template>
        </div>
        <button mat-flat-button color="primary" (click)="associateChecklistItems(evidenceOfCompliance.EvidenceOfComplianceChecklistItems)">
            Associate To Checklist Items
        </button>
    </page-body>
} @else {
    <loading-spinner></loading-spinner>
}
