import { Directive, ElementRef, HostBinding, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";

@Directive({
    selector: "[loadingButton]",
    standalone: true,
})
export class LoadingButtonDirective {
    private _loading: boolean = false;

    @Input() set loadingButton(loading: boolean) {
        this._loading = loading;
        this.handleLoadingState();
    }

    @HostBinding("disabled") get disabled() {
        return this._loading;
    }

    private spinner: HTMLElement;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) {
        this.spinner = this.renderer.createElement("span");
        this.renderer.addClass(this.spinner, "loading-spinner");
    }

    handleLoadingState(): void {
        if (this._loading) {
            this.renderer.addClass(this.el.nativeElement, "loading-spinner-active");
            this.renderer.insertBefore(this.el.nativeElement, this.spinner, this.el.nativeElement.firstChild);
        } else {
            this.renderer.removeClass(this.el.nativeElement, "loading-spinner-active");
            if (this.spinner.parentNode) {
                this.renderer.removeChild(this.el.nativeElement, this.spinner);
            }
        }
    }
}
