import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform<T>(items: T[], propertyPath: string, value: any): T[] {
    if (!items || !propertyPath || value === undefined) return items;

    return items.filter(item => {
      const propValue = propertyPath.split('.').reduce((acc, key) => acc && acc[key], item);
      return propValue === value;
    });
  }

}
