import { NgIf, AsyncPipe, NgFor, CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, FormsModule, Validators } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterLink } from "@angular/router";
import { EsaValueDisplayComponent, EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";
import { CustomFormLabelComponent } from "../../custom-form-label/custom-form-label.component";
import { EvidenceOfComplianceDto, EvidenceOfComplianceFileDto } from "src/app/shared/generated/model/models";
import { ComponentChecklistFormComponent } from "src/app/pages/component-checklist-form/component-checklist-form.component";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { FileDropUploadComponent } from "../../file-drop-upload/file-drop-upload.component";
import { EvidenceOfComplianceUpsertComponent } from "../../evidence-of-compliance-upsert/evidence-of-compliance-upsert.component";
import { SimpleFileDisplayComponent } from "../../simple-file-display/simple-file-display.component";
import { KvPairComponent } from "../../kv-pair/kv-pair.component";
import { MatSelectModule } from "@angular/material/select";
import { EvidenceOfComplianceService } from "src/app/shared/generated/api/evidence-of-compliance.service";
import { BeaconLoadingOverlayComponent } from "../../beacon-loading-overlay/beacon-loading-overlay.component";
import { LoadingSpinnerComponent } from "../../loading-spinner/loading-spinner.component";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertBoxComponent } from "../../alert-box/alert-box.component";

@Component({
    selector: "evidence-of-compliance-upsert-dialog",
    standalone: true,
    imports: [
        ComponentChecklistFormComponent,
        MatTooltip,
        KvPairComponent,
        EvidenceOfComplianceUpsertComponent,
        MatDialogContent,
        MatIconModule,
        MatDialogActions,
        MatDialogClose,
        FormsModule,
        MatDialogTitle,
        NgIf,
        MatSelectModule,
        NgFor,
        AsyncPipe,
        MatButton,
        EsaValueDisplayComponent,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        RouterLink,
        BypassHtmlPipe,
        ReactiveFormsModule,
        FileDropUploadComponent,
        SimpleFileDisplayComponent,
        CommonModule,
        MatDialogModule,
        BeaconLoadingOverlayComponent,
        LoadingSpinnerComponent,
        AlertBoxComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./evidence-of-compliance-upsert-dialog.component.html",
    styleUrl: "./evidence-of-compliance-upsert-dialog.component.scss",
})
export class EvidenceOfComplianceUpsertDialogComponent {
    formGroup: FormGroup;
    public duplicateFileNames: string[] = [];
    public currentRoute = "";
    public isLoading: boolean = false;
    public newEvidenceOfComplianceID: string;
    AlertContext = AlertContext;

    constructor(
        public dialogRef: MatDialogRef<EvidenceOfComplianceUpsertDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IEvidenceOfComplianceUpsertDialogComponentData,
        private fb: FormBuilder,
        private router: Router,
        private evidenceOfComplianceService: EvidenceOfComplianceService,
        private cdr: ChangeDetectorRef
    ) {
        this.formGroup = this.fb.group({
            Title: [data.EvidenceOfCompliance?.Title || "", Validators.required],
            Notes: [data.EvidenceOfCompliance?.Notes || ""],
            NewEvidenceOfComplianceFiles: this.fb.array([]),
            EvidenceOfComplianceFiles: [data.EvidenceOfCompliance?.EvidenceOfComplianceFiles || []],
        });
        const currentRouteParts = this.router.url.split("/");
        currentRouteParts.pop();
        this.currentRoute = currentRouteParts.join("/");
    }

    onFileUpload(files: File[]) {
        const newFilesArray = this.formGroup.get("NewEvidenceOfComplianceFiles") as FormArray;
        files.forEach((file) => {
            newFilesArray.push(this.fb.control(file));
        });
        this.checkForDuplicates();
    }

    checkForDuplicates() {
        const newFilesArray = this.formGroup.get("NewEvidenceOfComplianceFiles") as FormArray;
        const existingFiles = this.formGroup.controls.EvidenceOfComplianceFiles.value;

        const newFileNames = newFilesArray.value.map((f: File) => `"${f.name}"`);
        const existingFileNames = existingFiles.map((f: EvidenceOfComplianceFileDto) => `"${f.Name}"`);
        const duplicateFileNames = newFileNames.filter((x) => existingFileNames.includes(x));

        if (duplicateFileNames.length > 0) {
            this.duplicateFileNames = duplicateFileNames;
        } else {
            this.duplicateFileNames = [];
        }
    }

    deleteNewFile(file: File) {
        const newFilesArray = this.formGroup.get("NewEvidenceOfComplianceFiles") as FormArray;
        const index = newFilesArray.value.findIndex((f: File) => f === file);
        if (index !== -1) {
            newFilesArray.removeAt(index);
        }
    }

    deleteExistingFile(file: EvidenceOfComplianceFileDto) {
        const files = this.formGroup.controls.EvidenceOfComplianceFiles.value.filter((f: EvidenceOfComplianceFileDto) => f !== file);
        this.formGroup.controls.EvidenceOfComplianceFiles.patchValue(files);
    }

    onSave() {
        const response = {
            ...this.formGroup.value,
            EvidenceOfComplianceID: this.data.EvidenceOfCompliance.EvidenceOfComplianceID,
        } as IEvidenceOfComplianceUpsertDialogComponentRequest;
        this.saveEvidence(response);
    }

    saveEvidence(evidenceData: IEvidenceOfComplianceUpsertDialogComponentRequest) {
        this.isLoading = true;
        this.cdr.markForCheck();

        const filesJson = evidenceData.EvidenceOfComplianceFiles.map((f: EvidenceOfComplianceFileDto) => JSON.stringify(f));
        const newFiles = evidenceData.NewEvidenceOfComplianceFiles;
        this.evidenceOfComplianceService
            .componentsComponentIDEvidenceOfCompliancePut(
                this.data.ComponentID,
                evidenceData.EvidenceOfComplianceID,
                this.data.ChecklistItemID,
                evidenceData.Notes,
                evidenceData.Title,
                filesJson,
                newFiles
            )
            .subscribe({
                next: (response) => {
                    const dialogResponse: IEvidenceOfComplianceUpsertDialogComponentResponse = {
                        EvidenceOfComplianceID: response.EvidenceOfComplianceID,
                        Success: true,
                    };
                    this.isLoading = false;
                    this.dialogRef.close(dialogResponse);
                },
                error: () => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                },
            });
    }
}

export interface IEvidenceOfComplianceUpsertDialogComponentData {
    EvidenceOfCompliance?: EvidenceOfComplianceDto;
    ChecklistItemID?: string;
    ComponentID?: string;
}

export interface IEvidenceOfComplianceUpsertDialogComponentRequest {
    EvidenceOfComplianceID?: string;
    Title: string;
    Notes: string;
    NewEvidenceOfComplianceFiles: File[];
    EvidenceOfComplianceFiles: EvidenceOfComplianceFileDto[];
}

export interface IEvidenceOfComplianceUpsertDialogComponentResponse {
    EvidenceOfComplianceID: string;
    Success: boolean;
}
