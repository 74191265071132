<div class="detail-view" *ngIf="dailyMonitoringReport$ | async">
    <div class="details-wrapper">
        <div class="details-title-wrapper">
            <div class="details-title">
                <div style="flex: 1">
                    Monitoring Report - {{ dailyMonitoringReport["field_person"] }} - {{ dailyMonitoringReport["date_general"] | date: "longDate" : "UTC" }}
                </div>
                <div>
                    <button mat-button matTooltip="Copy Link" color="accent" icon="link" (click)="copyLink()">
                        <mat-icon>link</mat-icon>
                    </button>
                    <button mat-flat-button matTooltip="Back" style="margin-left: 0.25rem" color="primary" label="Back" (click)="returnToList()">
                        <mat-icon>arrow_back</mat-icon> Back
                    </button>
                </div>
            </div>
            <div class="details-sub-title">Last Updated: {{ dailyMonitoringReport["_updated_at"] | date: "short" }}</div>
        </div>
        <div class="details-body-wrapper">
            <div class="daily-monitoring-report-information" style="width: 60%">
                <div style="margin: 2rem 0rem">
                    <span class="status-box"><strong>Status:</strong> {{ dailyMonitoringReport["_status"] }}</span>
                    <span class="status-box"><strong>Discipline:</strong> {{ dailyMonitoringReport["discipline"] }}</span>
                </div>
                <form class="form grid-12" #dailyMonitoringReportForm="ngForm">
                    <div class="g-col-4">
                        <esa-material-ff name="StartTime" [editMode]="false" type="text" [(ngModel)]="dailyMonitoringReport['start_time']" label="Start Time">
                        </esa-material-ff>
                    </div>
                    <div class="g-col-4">
                        <esa-material-ff name="EndTime" [editMode]="false" type="text" [(ngModel)]="dailyMonitoringReport['end_time']" label="End Time">
                        </esa-material-ff>
                    </div>
                    <div class="g-col-4"></div>
                    <div class="g-col-4">
                        <esa-material-ff
                            name="Weather"
                            [editMode]="false"
                            type="text"
                            [(ngModel)]="dailyMonitoringReport['weather_conditions']"
                            label="Weather">
                        </esa-material-ff>
                    </div>
                    <div class="g-col-4">
                        <esa-material-ff name="WindSpeed" [editMode]="false" type="text" [(ngModel)]="dailyMonitoringReport['$WindSpeed']" label="Wind Speed">
                        </esa-material-ff>
                    </div>
                    <div class="g-col-4">
                        <esa-material-ff
                            name="AirTemperature"
                            [editMode]="false"
                            type="text"
                            [(ngModel)]="dailyMonitoringReport['$AirTemperature']"
                            label="Temperature">
                        </esa-material-ff>
                    </div>
                </form>
                <button mat-flat-button color="accent" type="button" (click)="viewPhotos()"><mat-icon>photo_camera</mat-icon> View Photo Gallery</button>
                <hr />
            </div>
            <div class="map-wrapper">
                <map
                    [allObjectsWithFeature]="observations"
                    [filteredObjectsWithFeature]="filteredObservations"
                    [featureKey]="'_geometry'"
                    [uniqueKey]="'_record_id'"
                    [filterToExtent]="extentFilterToggle"
                    [selectedObject]="selectedObservation"
                    (onFeatureSelected)="mapFeatureClicked($event)"
                    [displayFilterButtons]="false">
                </map>
            </div>
        </div>

        <daily-monitoring-report-observations
            [dailyMonitoringReport]="dailyMonitoringReport"
            [observations]="observations"
            (observationAccordionToggled)="panelToggled($event)"
            *ngIf="observations$ | async">
        </daily-monitoring-report-observations>

        <daily-monitoring-report-communications [dailyMonitoringReport]="dailyMonitoringReport"></daily-monitoring-report-communications>
    </div>

    <beacon-back-to-top></beacon-back-to-top>
</div>

@if (showLoadingOverlay) {
    <loading-spinner class="loading-overlay" />
}

<ng-template #customItemTemplate let-index="index" let-type="type" let-data="data" let-currIndex="currIndex">
    <div class="custom-gallery-caption">
        {{ data.alt }}
    </div>
</ng-template>
