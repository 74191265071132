<div>
    <h1 mat-dialog-title>Add Existing Evidence of Compliance</h1>
    <div mat-dialog-content>
        <form>
            @if (data.availableEvidencesOnComponent) {
                <esa-material-ff
                    name="Existing Evidence"
                    [editMode]="true"
                    [required]="false"
                    type="dropdown"
                    [options]="data.availableEvidencesOnComponent"
                    optionLabel="Title"
                    label="Select Evidence"
                    optionValue="EvidenceOfComplianceID"
                    [dropdownMultiple]="true"
                    [(ngModel)]="selectedEvidenceOfComplianceIDs">
                </esa-material-ff>
            }
        </form>
    </div>

    <div mat-dialog-actions align="end">
        <span
            [matTooltip]="
                !selectedEvidenceOfComplianceIDs || selectedEvidenceOfComplianceIDs.length == 0
                    ? 'Select at least one existing Evidence of Compliance to add it to this Checklist Item.'
                    : ''
            ">
            <button
                mat-flat-button
                color="primary"
                style="margin-right: 1rem"
                (click)="onSave()"
                cdkFocusInitial
                [disabled]="!selectedEvidenceOfComplianceIDs || selectedEvidenceOfComplianceIDs.length == 0">
                Save
            </button>
        </span>
        <button mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
