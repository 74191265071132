import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RoleDto } from "src/app/shared/generated/model/role-dto";
import { RoleUpsertDto } from "src/app/shared/generated/model/role-upsert-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { RoleFormComponent } from "../role-form/role-form.component";
import { Observable, Subscription } from "rxjs";
import { map, tap } from "rxjs/operators";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf, AsyncPipe } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ConfirmService } from "src/app/services/confirm.service";

@Component({
    selector: "role-detail",
    templateUrl: "./role-detail.component.html",
    styleUrls: ["./role-detail.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, RoleFormComponent, BackToTopComponent, AsyncPipe, MatButton, MatIcon, MatIconButton],
})
export class RoleDetailComponent implements OnInit, OnDestroy {
    @ViewChild("updateRoleForm", { read: RoleFormComponent }) form: RoleFormComponent;

    public role$: Observable<any>;
    public role: RoleDto;
    public currentUser: UserDto;
    public roleUpsertDto: RoleUpsertDto;
    public editMode: boolean = false;

    userSubscription: Subscription;
    updateRoleSubscription: Subscription;
    deleteRoleSubscription: Subscription;

    constructor(
        private roleService: RoleService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
        const roleID = parseInt(this.route.snapshot.paramMap.get("id"));
        if (!roleID) return;

        this.role$ = this.roleService.rolesRoleIDGet(roleID).pipe(
            map((data) => {
                this.role = data;
                this.setRoleDto();

                return data;
            })
        );

        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
        this.updateRoleSubscription?.unsubscribe();
        this.deleteRoleSubscription?.unsubscribe();
    }

    setRoleDto() {
        this.roleUpsertDto = new RoleUpsertDto({
            RoleName: this.role.RoleName,
            RoleDescription: this.role.RoleDescription,
            Rights: JSON.parse(this.role.Rights),
            Flags: JSON.parse(this.role.Flags),
        });
        this.cdr.markForCheck();
    }

    save() {
        this.role$ = this.roleService.rolesRoleIDPut(this.role.RoleID, this.form.roleUpsertDto).pipe(
            map((result) => {
                this.alertService.pushAlert(new Alert("The role was successfully updated.", AlertContext.Success), 5000);
                this.role = result;
                this.setRoleDto();
                if (this.currentUser.Role.RoleID == this.role.RoleID) {
                    this.authenticationService.refreshUser();
                }
                this.editMode = false;
                this.cdr.detectChanges();
                return result;
            })
        );
    }

    canEdit() {
        return (
            this.authenticationService.hasPermission(this?.currentUser, PermissionEnum.RoleRights, RightsEnum.Update) &&
            this.role &&
            !this.role.IsSystemRole &&
            !this.editMode
        );
    }

    enableEditMode() {
        this.editMode = true;
        this.cdr.markForCheck();
    }

    cancelEdit() {
        this.editMode = false;
        this.cdr.markForCheck();
    }

    canDelete() {
        return (
            this.currentUser &&
            this.authenticationService.hasPermission(this.currentUser, PermissionEnum.RoleRights, RightsEnum.Delete) &&
            this.role &&
            !this.role.IsSystemRole &&
            !this.editMode
        );
    }

    deleteRole() {
        this.confirmService
            .confirm({ color: "warn", header: `Delete Role`, text: `Are you sure you want to delete this role? This action can not be undone.` })
            .subscribe((result) => {
                if (!result) return;
                this.deleteRoleSubscription = this.roleService.rolesRoleIDDelete(this.role.RoleID).subscribe({
                    next: (result) => {
                        this.alertService.pushAlert(new Alert("The role was successfully deleted.", AlertContext.Success), 5000);
                        this.router.navigate(["/manage/roles"]);
                    },
                    error: (error) => {
                        this.alertService.pushAlert(new Alert("There was an error deleting the role.", AlertContext.Danger), 5000);
                    },
                });
            });
    }

    canExit() {
        if (this.editMode) {
            return JSON.stringify(this.roleUpsertDto) === JSON.stringify(this.form.roleUpsertDto);
        } else {
            return true;
        }
    }
}
