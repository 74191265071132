import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Platform } from "@angular/cdk/platform";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { FormControl, NgForm, FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete";
import { MatChipInputEvent, MatChipGrid, MatChipRow, MatChipRemove, MatChipInput } from "@angular/material/chips";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, share, startWith } from "rxjs/operators";
import { CurrentComplianceRequirementService } from "src/app/services/current-compliance-requirement";
import { EditViewEventService } from "src/app/services/edit-view-event.service";
import { SelectFromFullTextDialogComponent } from "src/app/shared/components/select-from-full-text-dialog/select-from-full-text-dialog.component";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { FrequencyService } from "src/app/shared/generated/api/frequency.service";
import { ComplianceRequirementTypeService } from "src/app/shared/generated/api/compliance-requirement-type.service";
import { ImplementationResponsibilityService } from "src/app/shared/generated/api/implementation-responsibility.service";
import { PhaseService } from "src/app/shared/generated/api/phase.service";
import { ScopeService } from "src/app/shared/generated/api/scope.service";
import { UserService } from "src/app/shared/generated/api/user.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { ComplianceRequirementDto } from "src/app/shared/generated/model/compliance-requirement-dto";
import { ComplianceRequirementUpsertDto } from "src/app/shared/generated/model/compliance-requirement-upsert-dto";
import { FrequencyDto, ComplianceRequirementTypeDto, ImplementationResponsibilityDto, ScopeDto } from "src/app/shared/generated/model/models";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { VComplianceRequirementTagDto } from "src/app/shared/generated/model/v-compliance-requirement-tag-dto";
import { BypassHtmlPipe } from "src/app/shared/pipes/bypass-html/bypass-html.pipe";
import { MatExpansionPanelActionRow } from "@angular/material/expansion";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../../shared/components/tinymce-editor/tinymce-editor.component";
import { MatOption } from "@angular/material/core";
import { MatIcon } from "@angular/material/icon";
import { MatFormField } from "@angular/material/form-field";
import { NgIf, NgFor, NgClass, AsyncPipe } from "@angular/common";
import { CustomFormLabelComponent } from "../../../shared/components/custom-form-label/custom-form-label.component";
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent } from "esa-material-form-field";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { ComplianceRequirementService } from "src/app/shared/generated/api/compliance-requirement.service";
import { BeaconSelectComponent, IBeaconSelectOption } from "src/app/shared/components/beacon-select/beacon-select.component";

@Component({
    selector: "compliance-requirement-form",
    templateUrl: "./compliance-requirement-form.component.html",
    styleUrls: ["./compliance-requirement-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        FormsModule,
        EsaMaterialFormFieldComponent,
        CustomFormLabelComponent,
        NgIf,
        MatFormField,
        MatChipGrid,
        NgFor,
        MatChipRow,
        MatChipRemove,
        MatIcon,
        MatChipInput,
        MatAutocompleteTrigger,
        ReactiveFormsModule,
        MatAutocomplete,
        MatOption,
        NgClass,
        TinymceEditorComponent_1,
        MatExpansionPanelActionRow,
        MatButton,
        MatIconButton,
        AsyncPipe,
        MatTooltip,
        BypassHtmlPipe,
        BeaconSelectComponent,
        CustomFormLabelComponent,
    ],
})
export class ComplianceRequirementFormComponent implements OnInit, OnChanges {
    @ViewChild("complianceRequirementForm", { read: NgForm }) form: NgForm;
    @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;

    @Output() formSubmitted = new EventEmitter<NgForm>();
    @Output() formUpdated = new EventEmitter<{
        currentComplianceReqID: string;
        updatedForm: ComplianceRequirementUpsertDto;
    }>();
    @Output() formDeleted = new EventEmitter<any>();
    @Output() formToEdit = new EventEmitter<any>();
    @Output() cancelEditModeChange = new EventEmitter<boolean>();

    @Input() commitment: CommitmentDto;
    @Input() complianceRequirement: ComplianceRequirementDto;
    @Input() allComplianceRequirementTags: VComplianceRequirementTagDto[];
    @Input() editMode: boolean;
    @Input() hasEditBtn: boolean = false;
    @Input() hasDeleteBtn: boolean = false;
    @Input() currentUser: UserDto;
    @Input() isSelected: any;

    public users$: Observable<any>;
    public phases$: Observable<any>;
    public scopes$: Observable<ScopeDto[]>;
    public frequencies$: Observable<FrequencyDto[]>;
    public implementationResponsibilities$: Observable<ImplementationResponsibilityDto[]>;
    public complianceRequirementTypes$: Observable<ComplianceRequirementTypeDto[]>;
    public filteredComplianceRequirementTags: Observable<VComplianceRequirementTagDto[]>;
    private _formBuilder = inject(FormBuilder);
    public milestoneFormGroup: FormGroup;
    public allMilestones$: Observable<IBeaconSelectOption[]>;

    tagControl = new FormControl();

    public model: ComplianceRequirementUpsertDto;
    public selectedComplianceRequirementTags: VComplianceRequirementTagDto[];

    hideButton = false;
    hasComplianceReq: boolean;
    removable = true;
    addOnBlur = false;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    constructor(
        private userService: UserService,
        private phaseService: PhaseService,
        private dialog: MatDialog,
        private platform: Platform,
        private cdr: ChangeDetectorRef,
        private editViewEventService: EditViewEventService,
        private currentComplianceReqService: CurrentComplianceRequirementService,
        private implementationResponsibilityService: ImplementationResponsibilityService,
        private complianceRequirementTypeService: ComplianceRequirementTypeService,
        private complianceRequirementService: ComplianceRequirementService,
        private scopeService: ScopeService,
        private frequencyService: FrequencyService
    ) {
        this.filteredComplianceRequirementTags = this.tagControl.valueChanges.pipe(
            startWith(null),
            map((tagName: string | null) => (tagName ? this._filter(tagName) : this.allComplianceRequirementTags.slice()))
        );
    }

    ngOnInit(): void {
        this.setForm();

        if (this.complianceRequirement?.Tags) {
            this.selectedComplianceRequirementTags = JSON.parse(this.complianceRequirement.Tags) || [];
        } else {
            this.selectedComplianceRequirementTags = [];
        }

        this.users$ = this.userService.usersGet().pipe(map((data) => data.filter((u) => !u.IsClientUser)));
        this.phases$ = this.phaseService.phasesGet();
        this.scopes$ = this.scopeService.scopesGet();
        this.frequencies$ = this.frequencyService.frequenciesGet();
        this.implementationResponsibilities$ = this.implementationResponsibilityService.implementationResponsibilitiesGet();
        this.complianceRequirementTypes$ = this.complianceRequirementTypeService.complianceRequirementTypesGet();
        this.allMilestones$ = this.complianceRequirementService.complianceRequirementsMilestonesGet().pipe(
            map((milestones) => {
                return milestones.map((crType) => {
                    return {
                        value: crType,
                        displayValue: crType,
                    };
                });
            }),
            share()
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isSelected?.currentValue && changes.isSelected?.currentValue !== this.complianceRequirement.ComplianceRequirementID) {
            this.hideButton = true;
        }
    }

    setForm() {
        this.model = this.currentComplianceReqService.createComplianceRequirementDto(this.complianceRequirement);
        if (this.complianceRequirement) {
            this.hasComplianceReq = true;
        } else {
            this.hasComplianceReq = false;
        }
        this.milestoneFormGroup = this._formBuilder.group({
            Milestone: this._formBuilder.control({ value: this.model.Milestone, displayValue: this.model.Milestone } as IBeaconSelectOption),
        });

        this.cdr.markForCheck();
    }

    editForm() {
        this.formToEdit.emit(this.complianceRequirement);
        this.editMode = true;
        this.editViewEventService.editButtonClicked.next(this.editMode);
        this.cdr.markForCheck();
    }

    cancelEditMode() {
        this.setForm();
        this.cancelEditModeChange.emit(true);
    }

    saveForm(form: NgForm) {
        this.formSubmitted.emit(form);
    }

    updateForm() {
        this.model.Milestone = this.milestoneFormGroup.get("Milestone").value;
        const updatedData = {
            currentComplianceReqID: this.complianceRequirement.ComplianceRequirementID,
            updatedForm: this.model,
        };

        this.formUpdated.emit(updatedData);
    }

    deleteForm() {
        this.formDeleted.emit(this.complianceRequirement);
    }

    public isFirefox(): boolean {
        return this.platform.FIREFOX;
    }

    openSelectFromFullTextDialog() {
        const dialogRef = this.dialog.open(SelectFromFullTextDialogComponent, {
            data: { FullCommitmentText: this.commitment.FullCommitmentText },
            minWidth: "50vw",
            maxWidth: "50vw",
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.model.ApplicableCommitmentText = result;
                this.cdr.markForCheck();
            }
        });
    }

    addTag(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();

        if (value) {
            this.selectedComplianceRequirementTags.push({ Name: value });
        }

        event.chipInput!.clear();
        this.tagControl.setValue(null);
        this.model.Tags = JSON.stringify(this.selectedComplianceRequirementTags.sort((tagA, tagB) => tagA.Name.localeCompare(tagB.Name)));
        this.cdr.detectChanges();
    }

    selectTag(event: MatAutocompleteSelectedEvent): void {
        this.selectedComplianceRequirementTags.push(new VComplianceRequirementTagDto({ Name: event.option.viewValue }));
        this.tagInput.nativeElement.value = "";
        this.tagControl.setValue(null);
        this.model.Tags = JSON.stringify(this.selectedComplianceRequirementTags.sort((tagA, tagB) => tagA.Name.localeCompare(tagB.Name)));
        this.cdr.detectChanges();
    }

    removeTag(workActivities: VComplianceRequirementTagDto): void {
        const index = this.selectedComplianceRequirementTags.indexOf(workActivities);

        if (index >= 0) {
            this.selectedComplianceRequirementTags.splice(index, 1);
        }

        this.model.Tags = JSON.stringify(this.selectedComplianceRequirementTags.sort((tagA, tagB) => tagA.Name.localeCompare(tagB.Name)));
        this.cdr.detectChanges();
    }

    private _filter(value: string): VComplianceRequirementTagDto[] {
        const filterValue = value.hasOwnProperty("Name") ? (value as any).Name.toLowerCase() : value.toLowerCase();
        return this.allComplianceRequirementTags.filter((tag) => tag.Name.toLowerCase().includes(filterValue));
    }
}
