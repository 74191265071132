import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { RoleUpsertDto } from "src/app/shared/generated/model/role-upsert-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { RoleFormComponent } from "../role-form/role-form.component";
import { Subscription } from "rxjs";
import { BackToTopComponent } from "../../shared/components/back-to-top/back-to-top.component";

@Component({
    selector: "role-create",
    templateUrl: "./role-create.component.html",
    styleUrls: ["./role-create.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RoleFormComponent, BackToTopComponent],
})
export class RoleCreateComponent implements OnDestroy {
    @ViewChild("createRoleForm", { read: RoleFormComponent }) form: RoleFormComponent;

    public roleUpsertDto: RoleUpsertDto = new RoleUpsertDto({
        RoleName: null,
        RoleDescription: null,
        Rights: {
            CustomPageRights: 0,
            CustomRichTextRights: 0,
            FieldDefinitionRights: 0,
            UserRights: 0,
            SourceDocumentRights: 0,
            CommitmentRights: 0,
            RoleRights: 0,
        },
        Flags: {
            CanImpersonateUsers: false,
            CanFinalizeCommitments: false,
            CanEditFinalizedCommitments: false,
        },
    });

    public originalData: RoleUpsertDto = new RoleUpsertDto({
        RoleName: null,
        RoleDescription: null,
        Rights: {
            CustomPageRights: 0,
            CustomRichTextRights: 0,
            FieldDefinitionRights: 0,
            UserRights: 0,
            SourceDocumentRights: 0,
            CommitmentRights: 0,
            RoleRights: 0,
        },
        Flags: {
            CanImpersonateUsers: false,
            CanFinalizeCommitments: false,
            CanEditFinalizedCommitments: false,
        },
    });

    roleResponse: any;
    editMode = true;

    addRoleSubscription: Subscription;

    constructor(
        private roleService: RoleService,
        private router: Router,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnDestroy(): void {
        this.addRoleSubscription?.unsubscribe();
    }

    save() {
        this.addRoleSubscription = this.roleService.rolesPost(this.form.roleUpsertDto).subscribe(
            (result) => {
                this.alertService.pushAlert(new Alert("The role was successfully created.", AlertContext.Success), 5000);
                this.roleResponse = result;
            },
            (error) => {
                this.alertService.pushAlert(new Alert("There was an error trying to create the role.", AlertContext.Danger), 5000);
                this.cdr.markForCheck();
            },
            () => {
                this.router.navigate(["manage", "roles", this.roleResponse.RoleID]);
            }
        );
    }

    cancelEdit() {
        this.router.navigateByUrl("manage/roles");
    }

    canExit() {
        if (this.roleResponse) {
            return true;
        } else {
            return JSON.stringify(this.roleUpsertDto) === JSON.stringify(this.form.roleUpsertDto);
        }
    }
}
