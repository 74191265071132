<div class="list-view">
    <div class="g-col-12">
        <div class="table-responsive" style="height: 800px">
            <div class="grid-header">
                <button
                    [disabled]="!hasEvidence"
                    [loadingButton]="isDownloadingZipForAllFilesForComponent"
                    mat-button
                    matTooltip="Download All Evidence Of Compliance Items As .zip"
                    (click)="downloadZipForAllFilesForComponent()">
                    <mat-icon>file_save</mat-icon>
                </button>
                <beacon-grid-actions
                    [grid]="evidenceOfComplianceGrid"
                    downloadFileName="component-related-evidence-of-compliance"
                    [downloadColIDsToExclude]="['0']">
                </beacon-grid-actions>
                <button mat-flat-button color="primary" type="button" (click)="addEvidence()"><mat-icon>add</mat-icon>Add New Evidence</button>
            </div>

            <ag-grid-angular
                #evidenceOfComplianceGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onEvidencesOfComplianceGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
