<div class="main">
    <div class="page-header pb-3">
        <h1 class="page-title">New Component</h1>
        <div class="copy copy-2">
            <p>This page allows you to create a new Component.</p>
        </div>
    </div>

    <component-form
        #createNewComponentForm
        [component]="model"
        [projectID]="projectID"
        [projectName]="projectName"
        [editMode]="true"
        (formSubmitted)="save($event)"
        (cancelEditModeChange)="cancelEdit()"></component-form>
</div>
