<button
    [matTooltip]="tooltip"
    mat-icon-button
    [color]="type"
    class="small-icon-button"
    [loadingButton]="isLoading"
    [disabled]="isLoading"
    (click)="onClick($event)">
    <mat-icon>{{ icon }}</mat-icon> {{ label }}
</button>
