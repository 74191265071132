import { BehaviorSubject, Observable, race, ReplaySubject } from "rxjs";
import { filter, first, map, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { RoleEnum } from "src/app/shared/models/enums/roles.enum";
import { AlertService } from "../shared/services/alert.service";
import { Alert } from "../shared/models/alert";
import { AlertContext } from "../shared/models/enums/alert-context.enum";
import { ImpersonationService } from "../shared/generated/api/impersonation.service";
import { Inject, Injectable } from "@angular/core";
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { b2cPolicies } from "../auth.config";
import { UserClaimsService } from "../shared/generated/api/user-claims.service";
import { UserDto } from "../shared/generated/model/user-dto";
import { PermissionEnum } from "../shared/generated/enum/permission-enum";
import { RightsEnum } from "../shared/models/enums/rights.enum";
import { FlagEnum } from "../shared/generated/enum/flag-enum";
import { TenantService as TenantService } from "../shared/services/tenant/tenant-service.service";
import { TenantDto } from "../shared/generated/model/tenant-dto";

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    private currentUser: UserDto;
    private claimsUser: any;

    private _currentUserSetSubject = new BehaviorSubject<UserDto>(null);
    public currentUser$ = this._currentUserSetSubject.asObservable();

    private lastTenantChecked: TenantDto;

    private userHasTenantRolesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public userHasTenantRoles$ = this.userHasTenantRolesSubject.asObservable();

    constructor(
        private router: Router,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private userClaimsService: UserClaimsService,
        private tenantService: TenantService,
        private impersonationService: ImpersonationService,
        private alertService: AlertService
    ) {
        // When the current tenant changes, we need to go back to the server with the tenant context, to get the correct tenant role.

        this.tenantService.currentTenant$
            .pipe(
                filter((currentTenant) => currentTenant != null),
                take(1)
            )
            .subscribe((currentTenant) => {
                if (this.lastTenantChecked == null || this.lastTenantChecked.TenantID != currentTenant.TenantID) {
                    this.refreshUser();
                    this.lastTenantChecked = currentTenant;
                }
            });
    }

    setClaimsUser(claimsUser: any) {
        this.claimsUser = claimsUser;
        this.postUser();
    }

    public refreshUser() {
        this.getUser(this.claimsUser);
    }

    private getUser(claims: any) {
        if (!claims) {
            return;
        }

        var globalID = claims["sub"];
        this.userClaimsService.userClaimsGlobalIDGet(globalID).subscribe(
            (result) => {
                this.updateUser(result);
            },
            () => {
                this.onGetUserError();
            }
        );
    }

    private postUser() {
        this.userClaimsService.userClaimsPost().subscribe(
            (result) => {
                this.updateUser(result);
            },
            () => {
                this.onGetUserError();
            }
        );
    }

    public updateUser(user: UserDto) {
        this.currentUser = user;
        this.tenantService.setAllowedTenants(user.Tenants);

        if (this.currentUser.Rights && Object.keys(this.currentUser.Rights).length > 0) {
            this.userHasTenantRolesSubject.next(true);
        }
        this._currentUserSetSubject.next(this.currentUser);
    }

    private onGetUserError() {
        this.alertService.pushAlert(new Alert("There was an error logging into the application.", AlertContext.Danger));
        this.router.navigate(["/"]);
    }

    public refreshUserInfo(user: UserDto) {
        this.updateUser(user);
    }

    public isAuthenticated(): boolean {
        return this.claimsUser != null;
    }

    public handleUnauthorized(): void {
        this.forcedLogout();
    }

    public forcedLogout() {
        if (!this.isCurrentUserBeingImpersonated(this.currentUser)) {
            sessionStorage["authRedirectUrl"] = window.location.href;
        }
        this.logout();
    }

    public loginRedirct() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    public login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService
                    .loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService.loginPopup(userFlowRequest).subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }

    public logout() {
        if (this.isCurrentUserBeingImpersonated(this.currentUser)) {
            this.impersonationService.impersonateStopImpersonationPost().subscribe((response) => {
                this.refreshUserInfo(response);
                this.router.navigateByUrl("/").then((x) => {
                    this.alertService.pushAlert(new Alert(`Finished impersonating`, AlertContext.Success));
                });
            });
        } else {
            this.authService.logoutRedirect();
        }
    }

    editProfile() {
        let editProfileFlowRequest = {
            scopes: ["openid", ...b2cPolicies.apiScopes],
            authority: b2cPolicies.authorities.editProfile.authority,
        };

        this.login(editProfileFlowRequest);
    }

    public isCurrentUserBeingImpersonated(user: UserDto): boolean {
        if (user) {
            var globalID = this.claimsUser["sub"];
            return globalID != user.UserGuid;
        }
        return false;
    }

    public getAuthRedirectUrl() {
        return sessionStorage["authRedirectUrl"];
    }

    public setAuthRedirectUrl(url: string) {
        sessionStorage["authRedirectUrl"] = url;
    }

    public clearAuthRedirectUrl() {
        this.setAuthRedirectUrl("");
    }

    public isUserUnassigned(user: UserDto): boolean {
        const role = user && user.Role ? user.Role.RoleID : null;
        return role === RoleEnum.NoAccess && user.IsActive;
    }

    public isUserRoleDisabled(user: UserDto): boolean {
        const role = user && user.Role ? user.Role.RoleID : null;
        return role === RoleEnum.NoAccess && !user.IsActive;
    }

    public isCurrentUserNullOrUndefined(): boolean {
        return !this.currentUser;
    }

    public doesCurrentUserHaveOneOfTheseRoles(roleIDs: Array<number>): boolean {
        if (roleIDs.length === 0) {
            return false;
        }
        const roleID = this.currentUser && this.currentUser.Role ? this.currentUser.Role.RoleID : null;
        return roleIDs.includes(roleID);
    }

    public getCurrentUser(): Observable<UserDto> {
        return this.currentUser$;
    }

    public getCurrentUserForLogging(): Observable<UserDto> {
        return race(
            new Observable((subscriber) => {
                if (this.currentUser) {
                    subscriber.next(this.currentUser);
                    subscriber.complete();
                }
            }),
            this.currentUser$.pipe(first())
        );
    }

    public getCurrentUserID(): Observable<number> {
        return race(
            new Observable<number>((subscriber) => {
                if (this.currentUser) {
                    subscriber.next(this.currentUser.UserID);
                    subscriber.complete();
                }
            }),
            this.currentUser$.pipe(
                first(),
                map((user) => user.UserID)
            )
        );
    }

    public hasPermission(user: UserDto, permission: PermissionEnum, rights: RightsEnum): boolean {
        const permissionName = PermissionEnum[permission];

        const hasPermission = user && user.Rights && user.Rights[permissionName] ? user.Rights[permissionName][rights] : false;

        return hasPermission;
    }

    public hasFlag(user: UserDto, flag: FlagEnum): boolean {
        const flagName = FlagEnum[flag];

        const hasFlag = user && user.Flags ? user.Flags[flagName] : false;

        return hasFlag;
    }

    public getUserDto(): UserDto {
        return this.currentUser;
    }
}
