@if (sourceDocument$ | async; as sourceDocument) {
    <page-body>
        <page-header [pageTitle]="sourceDocument.Name" [preTitle]="'Source Document'" [templateRight]="templateRight">
            <ng-template #templateRight>
                <div class="header-actions">
                    <button mat-icon-button (click)="editSourceDocument(sourceDocument)"><mat-icon>edit</mat-icon></button>
                    <button mat-icon-button (click)="delete()" *ngIf="canDelete()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-template>
            <div class="metadata">
                <kv-pair key="Date Of Latest Amendment" [value]="sourceDocument.DateOfLatestAmendment | date"></kv-pair>
            </div>
        </page-header>

        <div>
            <div class="grid-12">
                <div class="g-col-8 fields">
                    <div class="grid-12">
                        <div class="g-col-6 fields">
                            <mat-label label="Project">
                                <span class="project-label">Project</span>
                                <a class="project-display" [routerLink]="['/commitment-library/projects/', sourceDocument.ProjectID]">{{
                                    sourceDocument.ProjectName
                                }}</a>
                            </mat-label>

                            <kv-pair key="Reference Number" [value]="sourceDocument.ReferenceNumber"></kv-pair>

                            <kv-pair key="Agreement Terms" [value]="agreementTermsValue">
                                <ng-template #agreementTermsValue>
                                    <div [innerHTML]="sourceDocument.AgreementTerms === null ? '-' : (sourceDocument.AgreementTerms | bypassHtml)"></div>
                                </ng-template>
                            </kv-pair>
                        </div>
                        <div class="g-col-6 fields">
                            <kv-pair key="Approving Agency" [value]="sourceDocument.ApprovingAgency"></kv-pair>
                            <kv-pair key="Agency Contact" [value]="sourceDocument.AgencyContact"></kv-pair>
                            <kv-pair key="Agency Signatory" [value]="sourceDocument.AgencySignatory"></kv-pair>
                        </div>
                        <div class="g-col-12 fields">
                            <kv-pair key="Description" [value]="descriptionValue">
                                <ng-template #descriptionValue>
                                    <div [innerHTML]="sourceDocument.Description === null ? '-' : (sourceDocument.Description | bypassHtml)"></div>
                                </ng-template>
                            </kv-pair>
                        </div>
                    </div>

                    <div class="grid-12 mt-6">
                        <div class="g-col-6">
                            <a class="summary-item" [routerLink]="['./related-commitments']">
                                <div class="summary-item__icon">
                                    <beacon-entity-icon [inline]="true" entity="Commitment" />
                                </div>
                                <div class="summary-item__content">
                                    <div class="summary-item__count">
                                        {{ sourceDocument.RelatedCommitmentCount }}
                                    </div>
                                    <div class="summary-item__title">Commitments</div>
                                </div>
                                <div class="summar-item__footer">(click to view all)</div>
                                <a
                                    mat-raised-button
                                    color="primary"
                                    [routerLink]="['/commitment-library/commitments/create']"
                                    [queryParams]="{ sourceDocumentID: sourceDocument.SourceID, sourceDocumentName: sourceDocument.Name }">
                                    <mat-icon>add</mat-icon> Add New
                                </a>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="g-col-4 fields">
                    <kv-pair key="Files" [value]="filesTemplate"></kv-pair>
                    <ng-template #filesTemplate>
                        @if (sourceDocument.SourceDocumentFiles?.length > 0) {
                            <ul>
                                <li *ngFor="let file of sourceDocument.SourceDocumentFiles">
                                    <simple-file-display
                                        [fileName]="file.Name"
                                        [displayDelete]="false"
                                        [downloadUrl]="'sources/' + sourceDocument.SourceID + '/files/' + file.SourceFileID">
                                    </simple-file-display>
                                </li>
                            </ul>
                        } @else {
                            <em>No Files</em>
                        }
                    </ng-template>
                </div>
            </div>
        </div>
    </page-body>
} @else {
    <loading-spinner></loading-spinner>
}
