import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialog } from "../components/confirm-dialog/confirm-dialog.component";
import { map } from "rxjs/operators";

export interface IDeactivateComponent {
    canExit: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: "root",
})
export class UnsavedChangesGuard {
    userResponse: any;
    constructor(public dialog: MatDialog) {}

    public canDeactivate(component: IDeactivateComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.canExit && component.canExit()) {
            return of(true);
        }

        const dialogRef = this.dialog.open(ConfirmDialog, {
            data: {
                header: "Are you sure?",
                text: "You have unsaved changes! If you leave, your changes will be lost.",
            },
        });
        return dialogRef.afterClosed().pipe(map((result) => result === true));
    }
}
