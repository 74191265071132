import { Injectable, OnDestroy } from "@angular/core";
import { TenantService } from "../tenant/tenant-service.service";
import { filter } from "rxjs/operators";
import { TenantModuleDto } from "../../generated/model/tenant-module-dto";
import { BehaviorSubject, Subscription } from "rxjs";
import { ArrayHelper } from "../tenant/array-helper";
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from "@angular/router";
import { RouteHelpers } from "../router-helper/router-helper";

@Injectable({
    providedIn: "root",
})
export class TenantModuleService implements OnDestroy {
    private currentModuleSubject = new BehaviorSubject<TenantModuleDto>(null);
    public currentModule$ = this.currentModuleSubject.asObservable();
    private currentModule: TenantModuleDto;

    public allowedModules$ = new BehaviorSubject<TenantModuleDto[]>([]);
    private allowedModules: TenantModuleDto[] = [];

    private navigationEndSubscription: Subscription = Subscription.EMPTY;

    constructor(
        private tenantService: TenantService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.tenantService.currentTenant$.pipe(filter((tenant) => tenant != null)).subscribe((tenant) => {
            this.setAllowedModules(tenant.TenantModules);
        });

        this.navigationEndSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => this.navigationEndHandler());
    }

    ngOnDestroy(): void {
        this.navigationEndSubscription.unsubscribe();
    }

    public setAllowedModules(modules: TenantModuleDto[]) {
        let needToSetAllowedModules =
            !this.allowedModules || !ArrayHelper.areArraysEqual(this.allowedModules, modules, (a, b) => a.Module.ModuleID === b.Module.ModuleID);
        if (needToSetAllowedModules) {
            this.allowedModules = modules.filter((m) => m.DisplayOrder != null);
            this.allowedModules$.next(this.allowedModules);
            let currentModuleID = parseInt(localStorage.getItem("currentModuleID"));
            if (this.currentModule != null) {
                if (this.allowedModules.find((m) => m.Module.ModuleID == this.currentModule.Module.ModuleID) == null) {
                    this.setCurrentModule(this.allowedModules[0]);
                }
            } else if (currentModuleID != null && !isNaN(currentModuleID)) {
                let module = this.allowedModules.find((m) => m.Module.ModuleID == currentModuleID);
                if (module != null) {
                    this.setCurrentModule(module);
                } else {
                    this.setCurrentModule(this.allowedModules[0]);
                }
            } else {
                this.setCurrentModule(this.allowedModules[0]);
            }
        }
    }

    public getCurrentModule(): TenantModuleDto {
        return this.currentModule;
    }

    public setCurrentModule(module: TenantModuleDto) {
        let needToSetCurrentModule = !this.currentModule || module.Module.ModuleID != this.currentModule.Module.ModuleID;
        if (needToSetCurrentModule) {
            this.currentModule = module;
            this.currentModuleSubject.next(module);
            localStorage.setItem("currentModuleID", module.Module.ModuleID.toString());
        }
    }

    public setCurrentModuleByID(moduleID: number) {
        let module = this.allowedModules.find((m) => m.Module.ModuleID == moduleID);
        if (module) {
            this.setCurrentModule(module);
        }
    }

    private navigationEndHandler() {
        if (this.allowedModules.length < 1) return;
        let currentModuleID = this.getCurrentModule()?.Module.ModuleID;
        let routeModuleIDs = RouteHelpers.getRouteDataFromActivatedRoute(this.route).moduleIDs;
        if (
            currentModuleID != null &&
            routeModuleIDs != undefined &&
            !routeModuleIDs.includes(currentModuleID) &&
            routeModuleIDs != null &&
            routeModuleIDs.length > 0
        ) {
            let desiredModule = this.allowedModules.find((m) => m.Module.ModuleID == routeModuleIDs[0]);
            if (desiredModule != null) {
                this.setCurrentModule(desiredModule);
            }
        }
    }
}

export enum ModuleEnum {
    Core = 1,
    CommitmentLibrary = 2,
    MonitoringDashboard = 3,
    Scheduling = 4,
    ComplianceTracking = 5,
}
