import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "pillbox",
    standalone: true,
    imports: [NgClass, MatIcon],
    templateUrl: "./pillbox.component.html",
    styleUrl: "./pillbox.component.scss",
})
export class PillboxComponent {
    @Input() theme: typeof pillboxTheme = "Primary";
}

export let pillboxTheme: "ResourceCategory" | "Phase" | "Primary" | "Scope" | "Frequency" | "ComplianceRequirementType" | "Status";
