import { Component } from "@angular/core";

@Component({
    selector: "page-body",
    standalone: true,
    imports: [],
    templateUrl: "./page-body.component.html",
    styleUrl: "./page-body.component.scss",
})
export class PageBodyComponent {}
