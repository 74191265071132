import { Component, OnInit } from "@angular/core";
import { RouterLinkActive, RouterLink, RouterOutlet } from "@angular/router";

@Component({
    selector: "beacon-dashboard-manage",
    templateUrl: "./dashboard-manage.component.html",
    styleUrls: ["./dashboard-manage.component.scss"],
    standalone: true,
    imports: [RouterLinkActive, RouterLink, RouterOutlet],
})
export class DashboardManageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
