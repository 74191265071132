import { DatePipe } from "@angular/common";
import { inject, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "seasonalityTriggerDate",
    standalone: true,
})
export class SeasonalityTriggerDatePipe implements PipeTransform {
    datePipe = inject(DatePipe);
    // todo: move me
    transform(value: { month: number; day: number }, datePipeValue: string): string {
        const date = new Date();
        date.setMonth(value.month - 1);
        date.setDate(value.day);
        return this.datePipe.transform(date, datePipeValue);
    }
}
