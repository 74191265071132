import { Component, inject, Input } from "@angular/core";
import { ProjectSummaryDto } from "../../generated/model/project-summary-dto";
import { ComponentSummaryComponent } from "../component-summary/component-summary.component";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { PillComponent } from "../pill/pill.component";
import { DatePipe } from "@angular/common";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { MatButtonModule } from "@angular/material/button";
import { ProjectService } from "../../generated/api/project.service";
import { FileService } from "src/app/services/file/file.service";
import { LoadingButtonDirective } from "../../directives/loading-button/loading-button.directive";
import { SourceSummaryComponent } from "../source-summary/source-summary.component";

@Component({
    selector: "project-summary",
    standalone: true,
    imports: [
        ComponentSummaryComponent,
        LoadingButtonDirective,
        BeaconEntityIconComponent,
        MatTooltip,
        RouterModule,
        PillComponent,
        DatePipe,
        MatIcon,
        MatButtonModule,
        SourceSummaryComponent,
    ],
    templateUrl: "./project-summary.component.html",
    styleUrl: "./project-summary.component.scss",
})
export class ProjectSummaryComponent {
    private projectService: ProjectService = inject(ProjectService);
    private fileService: FileService = inject(FileService);
    @Input() project: ProjectSummaryDto;
    @Input() moduleType: typeof ProjectSummaryType;
    public isLoading: boolean = false;

    downloadCoversheet() {
        this.isLoading = true;
        this.projectService.projectsProjectIDCoverSheetGet(this.project.ProjectID).subscribe((response) => {
            this.isLoading = false;
            this.fileService.handleResponse(response, `Project-Coversheet_${this.project.ProjectID}.pdf`);
        });
    }
}

export let ProjectSummaryType: "commitment-library" | "compliance-tracking";
