<form class="form grid-12" [formGroup]="checklistFormGroup">
    <div class="g-col-12">
        <esa-material-ff [disabled]="isLoading" name="Name" [editMode]="editMode" type="text" formControlName="Name" [customLabel]="nameCustomLabel">
            <ng-template #nameCustomLabel>
                <custom-form-label label="Name" fieldDefinitionType="Name"></custom-form-label>
            </ng-template>
        </esa-material-ff>
        <esa-material-ff
            [disabled]="isLoading"
            name="Description"
            [editMode]="editMode"
            type="text"
            formControlName="Description"
            [customLabel]="descriptionCustomLabel">
            <ng-template #descriptionCustomLabel>
                <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
            </ng-template>
        </esa-material-ff>
    </div>
</form>
