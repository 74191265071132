import { Component } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams, IAfterGuiAttachedParams } from "ag-grid-community";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
    standalone: true,
    imports: [MatIcon, MatTooltip],
    selector: "check-box-renderer-renderer",
    templateUrl: "./check-box-renderer-renderer.component.html",
    styleUrls: ["./check-box-renderer-renderer.component.scss"],
})
export class CheckBoxRendererComponent implements ICellRendererAngularComp {
    params: any;
    editMode: boolean;
    selected: boolean;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.setProps();
    }

    private setProps() {
        this.editMode = this.params.editMode;
        this.selected = this.params.selected;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        this.setProps();
        return true;
    }
}

export interface IButtonRendererClickEvent {
    rowData: any;
    params: ICellRendererParams;
}
