<div class="main">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Commitments</h1>

            <button mat-flat-button *ngIf="canCreateCommitment" color="primary" (click)="createCommitment()"><mat-icon>add</mat-icon> Commitment</button>
        </div>
        <div class="g-col-6">
            <p>View all environmental commitments for {{ currentTenant?.Name }} projects.</p>
        </div>
    </div>

    <div>
        <div class="table-responsive">
            <beacon-grid-actions [grid]="commitmentsGrid" downloadFileName="commitments"></beacon-grid-actions>
            <ag-grid-angular
                #commitmentsGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onCommitmentsGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
