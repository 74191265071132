import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SourceDto } from "src/app/shared/generated/model/source-dto";

@Injectable({
    providedIn: "root",
})
export class CurrentSourceDocumentService {
    currentSourceDocument$ = new BehaviorSubject<SourceDto>(null);

    constructor() {}

    setCurrentSourceDocument(sourceDocument: SourceDto): void {
        this.currentSourceDocument$.next(sourceDocument);
    }

    getCurrentSourceDocument(): Observable<SourceDto> {
        return this.currentSourceDocument$.asObservable();
    }
}
