import { Component, Input } from "@angular/core";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { PillComponent } from "../pill/pill.component";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
    selector: "pill-evidence-of-compliance-file-count",
    standalone: true,
    imports: [BeaconEntityIconComponent, PillComponent, MatTooltip],
    templateUrl: "./pill-evidence-of-compliance-file-count.component.html",
    styleUrl: "./pill-evidence-of-compliance-file-count.component.scss",
})
export class PillEvidenceOfComplianceFileCountComponent {
    @Input() fileCount: number;
    @Input() evidenceNames: string[];

    private hasNoEvidence(): boolean {
        return this.evidenceNames.length === 0;
    }

    private hasNoFiles(): boolean {
        return this.fileCount === 0;
    }

    private getFileText(): string {
        return this.fileCount > 1 ? "files" : "file";
    }

    private getEvidenceText(): string {
        return this.evidenceNames.length > 1 ? "these Evidence of Compliance" : "this Evidence of Compliance";
    }

    getTooltipText(): string {
        if (this.hasNoEvidence()) {
            return "No Evidence of Compliance available.";
        }

        if (this.hasNoFiles()) {
            return "No files available.";
        }

        return `${this.fileCount} ${this.getFileText()} on ${this.getEvidenceText()}: ${this.evidenceNames.join(", ")}`;
    }
}
