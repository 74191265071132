<main class="main">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Evidences Of Compliance</h1>
        </div>
        <div class="g-col-6">
            <p>View all evidence of compliance for {{ currentTenant?.Name }}.</p>
        </div>
    </div>

    <div class="table-responsive">
        <beacon-grid-actions [grid]="evidenceOfComplianceGrid" downloadFileName="evidence-of-compliance"></beacon-grid-actions>

        <ag-grid-angular
            #evidenceOfComplianceGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [suppressMenuHide]="true"
            (gridReady)="onEvidencesOfComplianceGridReady($event)">
        </ag-grid-angular>
    </div>
</main>
