import { Component, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "beacon-entity-icon",
    standalone: true,
    imports: [MatIcon],
    templateUrl: "./beacon-entity-icon.component.html",
    styleUrl: "./beacon-entity-icon.component.scss",
})
export class BeaconEntityIconComponent {
    @Input() entity: typeof BeaconEntityType;

    /**
     * If set to true the icon will be sized with the font-size of the element
     */
    @Input() inline: boolean = false;
    BeaconEntityTypeEnum = BeaconEntityTypeEnum;
}

export enum BeaconEntityTypeEnum {
    Project = "view_timeline",
    SourceDocument = "source",
    Commitment = "handshake",
    ComplianceRequirement = "fact_check",
    Component = "dataset",
    ComplianceTracking = "assignment_turned_in",
    CommitmentLibrary = "library_books",
    EvidenceOfCompliance = "search_check",
    MonitoringDashboard = "monitor_heart",
    Scheduling = "assignment_ind",
}

export let BeaconEntityType:
    | "Project"
    | "SourceDocument"
    | "Commitment"
    | "ComplianceRequirement"
    | "Component"
    | "ComplianceTracking"
    | "CommitmentLibrary"
    | "EvidenceOfCompliance"
    | "MonitoringDashboard"
    | "Scheduling";
