import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButton, MatButtonModule } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { AgGridAngular } from "ag-grid-angular";
import { GridActionsComponent } from "../ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "beacon-clear-grid-filters-button",
    templateUrl: "./clear-grid-filters-button.component.html",
    styleUrls: ["./clear-grid-filters-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatTooltip, MatIcon],
})
export class ClearGridFiltersButtonComponent {
    @Input() grid: AgGridAngular;
    @Output() filtersCleared = new EventEmitter();

    constructor(private gridActions: GridActionsComponent) {}

    public clearFilters() {
        this.grid.api.setFilterModel(null);
        this.gridActions.filtersCleared();
        this.filtersCleared.emit();
    }
}
