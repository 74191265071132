/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ModuleEnum = 1 | 2 | 3 | 4 | 5;

export const ModuleEnum = {
    Core: 1 as ModuleEnum,
    CommitmentLibrary: 2 as ModuleEnum,
    MonitoringDashboard: 3 as ModuleEnum,
    Scheduling: 4 as ModuleEnum,
    ComplianceTracking: 5 as ModuleEnum
};
