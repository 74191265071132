import { Component } from "@angular/core";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams, IAfterGuiAttachedParams } from "ag-grid-community";
import { LoadingSpinnerComponent } from "../../loading-spinner/loading-spinner.component";
import { MatTooltip } from "@angular/material/tooltip";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";

@Component({
    standalone: true,
    imports: [MatButton, MatIcon, MatIconButton, MatTooltip, LoadingButtonDirective],
    selector: "button-renderer",
    templateUrl: "./button-renderer.component.html",
    styleUrls: ["./button-renderer.component.scss"],
})
export class ButtonRendererComponent implements ICellRendererAngularComp {
    params: any;
    icon: string;
    label: string;
    type: string;
    tooltip: string;
    isLoading: boolean = false;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.setProps();
    }

    private setProps() {
        this.icon = this.params.icon || null;
        this.label = this.params.label || null;
        this.type = this.params.type || "primary";
        this.tooltip = this.params.tooltip || null;
        this.isLoading = this.params.isLoading || false;
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        this.setProps();
        return true;
    }

    onClick($event: MouseEvent) {
        if (this.params.onClick instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node.data,
                params: this.params,
            };

            this.params.onClick(params);
        }
    }
}

export interface IButtonRendererClickEvent {
    event: MouseEvent;
    rowData: any;
    params: ICellRendererParams;
}
