/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ChecklistItemStatusEnum = 1 | 2 | 3;

export const ChecklistItemStatusEnum = {
    NotStarted: 1 as ChecklistItemStatusEnum,
    InProgress: 2 as ChecklistItemStatusEnum,
    Complete: 3 as ChecklistItemStatusEnum
};
