export class ArrayHelper {
    public static areArraysEqual<T extends { [key: string]: any }>(arr1: T[], arr2: T[], compareFunction: (a: T, b: T) => boolean): boolean {
        if ((arr1 || []).length !== (arr2 || []).length) return false;

        for (let obj of arr1) {
            if (!arr2.some((o) => compareFunction(obj, o))) {
                return false;
            }
        }

        return true;
    }
}
