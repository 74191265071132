import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { combineLatest, Observable, Subscription, switchMap } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { MatIcon } from "@angular/material/icon";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { EvidenceOfComplianceDto } from "src/app/shared/generated/model/evidence-of-compliance-dto";
import { EvidenceOfComplianceService } from "src/app/shared/generated/api/evidence-of-compliance.service";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { CommonModule } from "@angular/common";
import { KvPairComponent } from "../../shared/components/kv-pair/kv-pair.component";
import { SimpleFileDisplayComponent } from "../../shared/components/simple-file-display/simple-file-display.component";
import {
    EvidenceOfComplianceUpsertDialogComponent,
    IEvidenceOfComplianceUpsertDialogComponentData,
    IEvidenceOfComplianceUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/evidence-of-compliance-upsert-dialog/evidence-of-compliance-upsert-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { PageHeaderComponent } from "../../shared/components/page-header/page-header.component";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { FileService } from "src/app/services/file/file.service";
import {
    EvidenceOfComplianceAssociateToChecklistItemsDialogComponent,
    IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentData,
    IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentResponse,
} from "src/app/shared/components/dialogs/evidence-of-compliance-associate-to-checklist-items-dialog/evidence-of-compliance-associate-to-checklist-items-dialog.component";
import { EvidenceOfComplianceChecklistItemDto } from "src/app/shared/generated/model/evidence-of-compliance-checklist-item-dto";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";
import { EvidenceOfComplianceFileService } from "src/app/shared/generated/api/evidence-of-compliance-file.service";

@Component({
    selector: "evidence-of-compliance-details",
    templateUrl: "./evidence-of-compliance-details.component.html",
    styleUrls: ["./evidence-of-compliance-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgIf,
        MatTooltip,
        MatButton,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuItem,
        LoadingSpinnerComponent,
        CommonModule,
        KvPairComponent,
        SimpleFileDisplayComponent,
        RouterLink,
        PageHeaderComponent,
        PageBodyComponent,
        MatIcon,
        MatIconButton,
        LoadingButtonDirective,
    ],
})
export class EvidenceOfComplianceDetailsComponent implements OnInit, OnDestroy {
    public evidenceOfCompliance$: Observable<EvidenceOfComplianceDto>;
    public evidenceOfComplianceID: string;
    public projectID: string;
    public componentID: string;
    public currentUser: UserDto;
    public editMode: boolean;
    public downloading: boolean = false;

    editViewButtonClicked: Subscription;
    user: Subscription;
    deleteSubscription: Subscription;

    constructor(
        private evidenceOfComplianceService: EvidenceOfComplianceService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private dialog: MatDialog,
        private fileService: FileService,
        private evidenceOfComplianceFileService: EvidenceOfComplianceFileService
    ) {}

    ngOnInit(): void {
        this.user = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });

        this.fetchEvidenceOfCompliance();
    }

    fetchEvidenceOfCompliance(): void {
        this.evidenceOfCompliance$ = this.activatedRoute.paramMap.pipe(
            switchMap((paramMap) => {
                this.projectID = paramMap.get("projectID");
                this.componentID = paramMap.get("componentID");
                this.evidenceOfComplianceID = paramMap.get("evidenceOfComplianceID");
                return this.evidenceOfComplianceService.evidenceOfComplianceEvidenceOfComplianceIDGet(this.evidenceOfComplianceID);
            })
        );
    }

    ngOnDestroy(): void {
        this.user?.unsubscribe();
        this.editViewButtonClicked?.unsubscribe();
        this.deleteSubscription?.unsubscribe();
    }

    canEdit(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }

    editEvidence(evidence: EvidenceOfComplianceDto) {
        const dialogRef = this.dialog.open<
            EvidenceOfComplianceUpsertDialogComponent,
            IEvidenceOfComplianceUpsertDialogComponentData,
            IEvidenceOfComplianceUpsertDialogComponentResponse
        >(EvidenceOfComplianceUpsertDialogComponent, {
            width: "700px",
            data: {
                ChecklistItemID: evidence.EvidenceOfComplianceChecklistItems[0]?.ChecklistItemID,
                EvidenceOfCompliance: evidence,
                ComponentID: this.componentID,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.fetchEvidenceOfCompliance();
                this.cdr.markForCheck();
            }
        });
    }

    associateChecklistItems(existingEvidence: EvidenceOfComplianceChecklistItemDto[]) {
        const dialogRef = this.dialog.open<
            EvidenceOfComplianceAssociateToChecklistItemsDialogComponent,
            IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentData,
            IEvidenceOfComplianceAssociateToChecklistItemsDialogComponentResponse
        >(EvidenceOfComplianceAssociateToChecklistItemsDialogComponent, {
            width: "80vw",
            height: "80vh",
            data: {
                ComponentID: this.componentID,
                EvidenceOfComplianceID: this.evidenceOfComplianceID,
                ExistingChecklistItems: existingEvidence,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.fetchEvidenceOfCompliance();
                this.cdr.markForCheck();
            }
        });
    }

    unassociateChecklistItem(checklistItemID: string) {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Unassociate Checklist Item`,
                text: `Are you sure you want to unassociate this Checklist Item from this Evidence Of Compliance?`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.evidenceOfComplianceService
                    .componentsComponentIDChecklistItemsChecklistItemIDEvidenceOfComplianceEvidenceOfComplianceIDPost(
                        this.componentID,
                        checklistItemID,
                        this.evidenceOfComplianceID
                    )
                    .subscribe((response) => {
                        this.fetchEvidenceOfCompliance();
                        this.cdr.markForCheck();
                    });
            });
    }

    canDelete(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Delete);
    }

    delete(): void {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Delete Evidence Of Compliance`,
                text: `Are you sure you want to delete this Evidence Of Compliance? This action cannot be undone.`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.deleteSubscription = this.evidenceOfComplianceService
                    .evidenceOfComplianceEvidenceOfComplianceIDDelete(this.evidenceOfComplianceID)
                    .subscribe((result) => {
                        this.alertService.pushAlert(new Alert("The Evidence Of Compliance was successfully deleted.", AlertContext.Success), 5000);
                        this.router.navigate(["/compliance-tracking"]);
                    });
            });
    }

    downloadFiles(evidenceOfComplianceID: string) {
        this.downloading = true;

        combineLatest([
            this.evidenceOfComplianceFileService.evidenceOfComplianceFileEvidenceOfComplianceIDAllFilesDownloadGet(evidenceOfComplianceID),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
            this.downloading = false;
            this.cdr.markForCheck();
        });
    }
}
