<div>
    <div div class="home-login">
        <div class="site-name">
            <h1 class="site-title">Beacon</h1>
            <h2 class="site-sub-title">
                <span class="accent-sub-title">Equipping compliance managers with tools</span> to navigate the complexities of environmental regulatory
                compliance in the modern world.
            </h2>
        </div>
    </div>

    <mat-card class="p-3" appearance="outlined" style="width: 25%; z-index: 5; float: right; top: -140px; left: -90px">
        <h2>@if (currentUser$ | async; as currentUser) { Welcome, {{ currentUser.FullName }}. } @else { Welcome. }</h2>
        <mat-divider></mat-divider>
        <div class="welcome-btn-wrapper">
            @if (currentUser$ | async) {
            <button mat-flat-button color="primary" (click)="logout()">Sign Out</button>
            } @else {
            <button mat-flat-button color="primary" (click)="login()">Sign In</button>
            }
        </div>
    </mat-card>

    <div class="home-details-wrapper">
        <svg width="16" height="20" viewBox="0 0 16 20" fill="#F9A134" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.09067 2.24933C4.312 1.69467 6.048 1.33333 8 1.33333C9.952 1.33333 11.688 1.69333 12.9093 2.24933C14.192 2.83333 14.6667 3.50267 14.6667 4C14.6667 4.49867 14.192 5.16667 12.9093 5.75067C11.688 6.30533 9.952 6.66667 8 6.66667C6.048 6.66667 4.312 6.30667 3.09067 5.75067C1.808 5.16667 1.33333 4.49733 1.33333 4C1.33333 3.50133 1.808 2.83333 3.09067 2.24933ZM14.6667 6.264V8C14.6667 8.49867 14.192 9.16667 12.9093 9.75067C11.688 10.3053 9.952 10.6667 8 10.6667C6.048 10.6667 4.312 10.3067 3.09067 9.75067C1.808 9.16667 1.33333 8.49733 1.33333 8V6.264C1.69467 6.53333 2.10667 6.768 2.53867 6.964C3.97067 7.61467 5.90267 8 8 8C10.0973 8 12.0293 7.61467 13.4613 6.964C13.8865 6.77342 14.2905 6.53881 14.6667 6.264ZM16 4C16 2.65733 14.8333 1.66 13.4613 1.036C12.0293 0.385333 10.0973 0 8 0C5.90267 0 3.97067 0.385333 2.53867 1.036C1.16667 1.66 0 2.65733 0 4V16C0 17.3427 1.16667 18.34 2.53867 18.964C3.97067 19.6133 5.90267 20 8 20C10.0973 20 12.0293 19.6147 13.4613 18.964C14.8333 18.34 16 17.3427 16 16V4ZM14.6667 10.264V12C14.6667 12.4987 14.192 13.1667 12.9093 13.7507C11.688 14.3053 9.952 14.6667 8 14.6667C6.048 14.6667 4.312 14.3067 3.09067 13.7507C1.808 13.1667 1.33333 12.4973 1.33333 12V10.264C1.69467 10.5333 2.10667 10.768 2.53867 10.964C3.97067 11.6133 5.90267 12 8 12C10.0973 12 12.0293 11.6147 13.4613 10.964C13.8865 10.7734 14.2905 10.5388 14.6667 10.264ZM14.6667 14.264V16C14.6667 16.4987 14.192 17.1667 12.9093 17.7507C11.688 18.3053 9.952 18.6667 8 18.6667C6.048 18.6667 4.312 18.3067 3.09067 17.7507C1.808 17.1667 1.33333 16.4973 1.33333 16V14.264C1.69467 14.5333 2.10667 14.768 2.53867 14.964C3.97067 15.6133 5.90267 16 8 16C10.0973 16 12.0293 15.6147 13.4613 14.964C13.8933 14.768 14.3053 14.5333 14.6667 14.264Z"
                fill="#FCD28B" />
        </svg>
        <h2>The Core Problem</h2>
        <p>
            <strong>Compliance with environmental regulations has become increasingly complex and fragmented.</strong> As environmental regulations evolve, they
            require clients to invest more time and resources into compliance efforts. Resource constraints such as limited budgets and outdated tools further
            compound challenges for organizations tasked with ensuring compliance, affecting their ability to navigate the modern regulatory environment
            effectively and efficiently.
        </p>

        <img src="../../../../assets/main/images/home-page-image2.jpg" width="100%" style="margin-top: 2rem" />
    </div>

    <div class="grid-12 solution-wrapper">
        <div class="g-col-6">
            <svg width="22" height="20" viewBox="0 0 22 20" fill="#F9A134" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 2C10.337 2 9.778 2.548 9.778 3.25C9.778 3.952 10.337 4.5 11 4.5C11.663 4.5 12.222 3.952 12.222 3.25C12.222 2.548 11.663 2 11 2ZM7.778 3.25C7.778 1.467 9.208 0 11 0C12.791 0 14.222 1.467 14.222 3.25C14.2234 3.93324 14.0095 4.59956 13.6106 5.15428C13.2117 5.70899 12.6482 6.12389 12 6.34V7.176L15.849 9.426V13.883L16.667 14.294C17.2502 13.7813 18.0005 13.4989 18.777 13.5C20.57 13.5 22 14.967 22 16.75C22 18.533 20.569 20 18.778 20C16.987 20 15.556 18.533 15.556 16.75C15.556 16.498 15.584 16.252 15.638 16.015L14.885 15.637L11 17.908L7.115 15.638L6.362 16.016C6.416 16.252 6.444 16.498 6.444 16.75C6.444 18.533 5.014 20 3.222 20C1.431 20 0 18.533 0 16.75C0 14.967 1.431 13.5 3.222 13.5C4.032 13.5 4.77 13.8 5.334 14.295L6.151 13.884V9.426L10 7.176V6.341C9.35167 6.12483 8.78799 5.70979 8.38908 5.15487C7.99017 4.59996 7.77635 3.93341 7.778 3.25ZM11 8.908L8.151 10.574V13.926L11 15.592L13.849 13.926V10.574L11 8.908ZM4.225 16.034C4.11381 15.8702 3.96436 15.7359 3.78959 15.6429C3.61482 15.5498 3.42 15.5008 3.222 15.5C2.56 15.5 2 16.048 2 16.75C2 17.452 2.559 18 3.222 18C3.886 18 4.444 17.452 4.444 16.75C4.444 16.604 4.42 16.464 4.376 16.334L4.225 16.034ZM17.624 16.337C17.5787 16.47 17.5557 16.6095 17.556 16.75C17.556 17.452 18.114 18 18.778 18C19.441 18 20 17.452 20 16.75C20 16.048 19.441 15.5 18.778 15.5C18.368 15.5 17.999 15.709 17.776 16.033L17.624 16.337Z"
                    fill="#FCD28B" />
            </svg>
            <h2>Our Solution</h2>
            <p>
                <strong>Beacon</strong> offers a centralized solution by providing access to current project data, streamlining compliance processes, and
                effectively managing requirements across project phases. This not only promotes efficiency and accountability but also empowers users to make
                informed decisions, mitigate risks, and avoid disruptions that could potentially cause project delays.
            </p>
        </div>

        <div class="g-col-6">
            <h3>Centralized Data Management</h3>
            <p>
                Our platform centralizes project data and streamlines compliance by storing information on all mitigation measures required for the project,
                including descriptions, specifications, deadlines, and responsible parties. It also manages documents like reports, permits, and certifications
                related to compliance efforts.
            </p>

            <h3>Monitoring &#38; Compliance Reporting</h3>
            <p>
                Our platform will enable ongoing monitoring, data collection, and compliance reporting, offering real-time insights into mitigation compliance
                status. Stakeholders can track progress, milestones, and upcoming deadlines to stay informed and proactive.
            </p>

            <h3>Auditability &#38; Accountability</h3>
            <p>
                Our platform emphasizes transparency and accountability, providing robust auditability features for demonstrating compliance and building trust
                with regulatory agencies, communities, and sponsors.
            </p>
        </div>
    </div>
</div>
