import { Component, inject, Input, OnInit } from "@angular/core";
import { ComponentSummaryDto } from "../../generated/model/component-summary-dto";
import { MatIcon } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { PillComponent } from "../pill/pill.component";
import { DatePipe } from "@angular/common";
import { PillComponentStatusComponent } from "../pill-component-status/pill-component-status.component";
import { MatTooltip } from "@angular/material/tooltip";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { ComponentService } from "../../generated/api/component.service";
import { FileService } from "src/app/services/file/file.service";
import { MatButtonModule } from "@angular/material/button";
import { LoadingButtonDirective } from "../../directives/loading-button/loading-button.directive";
import { AlertService } from "../../services/alert.service";
import { A } from "@angular/cdk/keycodes";
import { Alert } from "../../models/alert";
import { AlertContext } from "../../models/enums/alert-context.enum";
import { combineLatest, forkJoin, switchMap } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
    selector: "component-summary",
    standalone: true,
    imports: [
        MatIcon,
        RouterModule,
        PillComponent,
        DatePipe,
        PillComponentStatusComponent,
        MatTooltip,
        BeaconEntityIconComponent,
        MatButtonModule,
        LoadingButtonDirective,
    ],
    templateUrl: "./component-summary.component.html",
    styleUrl: "./component-summary.component.scss",
})
export class ComponentSummaryComponent implements OnInit {
    private componentService: ComponentService = inject(ComponentService);
    private authenticationService: AuthenticationService = inject(AuthenticationService);
    private fileService: FileService = inject(FileService);
    private alertService: AlertService = inject(AlertService);
    public isLoadingOne: boolean = false;
    public isLoadingTwo: boolean = false;
    public hasFiles: boolean = false;
    @Input() component: ComponentSummaryDto;

    ngOnInit(): void {
        this.componentService.componentsComponentIDFileCheckGet(this.component.ComponentID).subscribe((response) => {
            if (response == true) {
                this.hasFiles = true;
            }
        });
    }

    downloadCoversheetWithFiles() {
        this.isLoadingOne = true;

        combineLatest([
            this.componentService.componentsComponentIDCoverSheetWithFilesGet(this.component.ComponentID),
            this.authenticationService.getCurrentUser(),
        ]).subscribe(([response, user]) => {
            this.isLoadingOne = false;
            this.alertService.pushAlert(
                new Alert(
                    `Processing of your download has been added to the queue. You will receive an email at '${user.Email}' when the link to download your file is ready.`,
                    AlertContext.Success
                )
            );
        });
    }

    downloadCoversheet() {
        this.isLoadingTwo = true;
        this.componentService.componentsComponentIDCoverSheetGet(this.component.ComponentID).subscribe((response) => {
            this.isLoadingTwo = false;
            this.fileService.handleResponse(response, `Component-Coversheet_${this.component.ComponentID}.pdf`);
        });
    }
}
