<div [ngClass]="['pillbox', 'pillbox-' + theme]">
    @switch (theme) {
        @case ("Scope") {
            <mat-icon>travel_explore</mat-icon>
        }
        @case ("Frequency") {
            <mat-icon>timer</mat-icon>
        }
        @case ("ComplianceRequirementType") {
            <mat-icon>directions_run</mat-icon>
        }
        @case ("Phase") {
            <mat-icon>view_timeline</mat-icon>
        }
        @case ("ResourceCategory") {
            <mat-icon>category</mat-icon>
        }
        @case ("Status") {
            <span style="font-size: small">Status: &nbsp;</span>
        }
    }
    <div class="pillbox-content">
        <ng-content></ng-content>
    </div>
</div>
