<div class="detail-view" *ngIf="user$ | async as user">
    <div class="page-header">
        <h1 class="page-title">
            {{ user?.FullName || (user?.FirstName || user?.LastName ? user?.FirstName + " " + user?.LastName : null) }}
        </h1>
    </div>

    <div class="card card-55 mt-3">
        <div class="card-header flex-justify-between">
            <h3 class="card-title">Basics</h3>

            <div class="card-actions">
                <button mat-flat-button color="primary" *ngIf="canUpdateUser()" (click)="editUser()"><mat-icon>edit</mat-icon> Edit</button>
                <button mat-flat-button color="primary" *ngIf="canImpersonate()" (click)="impersonateUser(user?.UserID)">
                    <mat-icon>person</mat-icon> Impersonate
                </button>
            </div>
        </div>

        <div class="grid-12">
            <div class="g-col-12">
                <div class="kv-pair">
                    <div class="key"><label>First Name</label></div>
                    <div class="value">
                        {{ user?.FirstName }}
                        <em *ngIf="!user?.FirstName" class="text-muted">Not Available</em>
                    </div>
                </div>

                <div class="kv-pair">
                    <div class="key"><label>Last Name</label></div>
                    <div class="value">
                        {{ user?.LastName }}
                        <em *ngIf="!user?.LastName" class="text-muted">Not Available</em>
                    </div>
                </div>

                <div class="kv-pair">
                    <div class="key"><label>Full Name</label></div>
                    <div class="value">
                        {{ user?.FullName }}
                        <em *ngIf="!user?.FullName" class="text-muted">Not Available</em>
                    </div>
                </div>

                <div class="kv-pair">
                    <div class="key"><label>Phone</label></div>
                    <div class="value">
                        {{ user?.Phone }}
                        <em *ngIf="!user?.Phone" class="text-muted">Not Available</em>
                    </div>
                </div>

                <div class="kv-pair">
                    <div class="key"><label>Email</label></div>
                    <div class="value">
                        {{ user?.Email }}
                        <em *ngIf="!user?.Email" class="text-muted">Not Available</em>
                    </div>
                </div>

                <div class="kv-pair">
                    <div class="key"><label>Role</label></div>
                    <div class="value">
                        {{ user?.Role?.RoleName }}
                        <em *ngIf="!user?.Role?.RoleName" class="text-muted">Not Available</em>
                    </div>
                </div>

                <div class="kv-pair">
                    <div class="key" *ngIf="user.HasAdminRole"><label>Receives System Communications</label></div>
                    <div class="value" *ngIf="user.HasAdminRole">
                        {{ user?.ReceiveSupportEmails == true ? "Yes" : "No" }}
                        <em *ngIf="!user" class="text-muted">Not Available</em>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
