import { AsyncPipe } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    computed,
    ElementRef,
    forwardRef,
    Host,
    input,
    InputSignal,
    OnInit,
    Optional,
    Self,
    Signal,
    SkipSelf,
    ViewChild,
} from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, NgControl, ReactiveFormsModule } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatSelectModule } from "@angular/material/select";
import { combineLatest, map, Observable, of, startWith, tap } from "rxjs";
import { toObservable } from "@angular/core/rxjs-interop";

@Component({
    selector: "beacon-select",
    standalone: true,
    imports: [MatSelectModule, ReactiveFormsModule, FormsModule, MatAutocompleteModule, AsyncPipe, MatInput],
    templateUrl: "./beacon-select.component.html",
    styleUrl: "./beacon-select.component.scss",
})
export class BeaconSelectComponent implements ControlValueAccessor, OnInit {
    @ViewChild("searchInput") searchInput: ElementRef<HTMLInputElement>;
    optionSelected($event: MatAutocompleteSelectedEvent) {
        this.value = $event.option.value.value;
        this.onChange(this.value);
    }
    options: InputSignal<IBeaconSelectOption[]> = input.required();
    label: InputSignal<string> = input.required();
    searchControl = new FormControl<string>("");
    filteredOptions: IBeaconSelectOption[];
    requireSelection: InputSignal<boolean> = input(true);

    displayFn(option: IBeaconSelectOption) {
        return option?.displayValue;
    }

    ngOnInit(): void {
        this.searchControl.setValidators(this.control.control.validator);
    }

    filter(): void {
        const filterValue = this.searchInput.nativeElement.value;
        this.filteredOptions = this.options().filter((o) => o.displayValue.toLowerCase().includes(filterValue.toLowerCase()));
        if (!this.requireSelection()) {
            this.value = filterValue;
            this.onChange(this.value);
        }
    }

    // BEGIN ControlValueAccessor implementation
    constructor(@Self() @Optional() private control: NgControl) {
        this.control.valueAccessor = this;
    }
    value: any;
    disabled: boolean = false;
    onChange = (value) => {};

    onTouched = () => {};

    writeValue(value: any) {
        this.value = value;
        this.searchControl.setValue(value);
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    // END ControlValueAccessor implementation
}

export interface IBeaconSelectOption {
    value: any;
    displayValue: string;
}
