import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from "@angular/core";
import { FieldDefinitionService } from "src/app/shared/generated/api/field-definition.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { FieldDefinitionDto } from "src/app/shared/generated/model/models";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { TinymceEditorComponent } from "src/app/shared/components/tinymce-editor/tinymce-editor.component";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { TinymceEditorComponent as TinymceEditorComponent_1 } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { FormsModule } from "@angular/forms";
import { NgIf, AsyncPipe } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: "beacon-field-definition-edit",
    templateUrl: "./field-definition-edit.component.html",
    styleUrls: ["./field-definition-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, FormsModule, TinymceEditorComponent_1, AsyncPipe, MatButton, MatIcon],
})
export class FieldDefinitionEditComponent implements OnInit {
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;
    public fieldDefinition: FieldDefinitionDto;
    public fieldDefinition$: Observable<FieldDefinitionDto>;
    public orignalFieldDefinitionValue: string;

    updatedFieldSubscription: Subscription;
    public isLoading: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private alertService: AlertService,
        private fieldDefinitionService: FieldDefinitionService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const id = parseInt(this.route.snapshot.paramMap.get("id"));
        if (!id) return;

        this.fieldDefinition$ = this.fieldDefinitionService.fieldDefinitionsFieldDefinitionTypeIDGet(id).pipe(
            map((fieldDefinition) => {
                this.fieldDefinition = fieldDefinition;
                this.orignalFieldDefinitionValue = fieldDefinition.FieldDefinitionValue;
                return fieldDefinition;
            })
        );
    }

    ngOnDestroy() {
        this.cdr.detach();
        this.updatedFieldSubscription?.unsubscribe();
    }

    cancelEditMode() {
        this.router.navigateByUrl("manage/labels-and-definitions");
    }

    saveDefinition(): void {
        this.isLoading = true;
        this.updatedFieldSubscription = this.fieldDefinitionService
            .fieldDefinitionsFieldDefinitionTypeIDPut(this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeID, this.fieldDefinition)
            .subscribe({
                next: (response) => {
                    this.fieldDefinition = response;
                    this.orignalFieldDefinitionValue = this.fieldDefinition.FieldDefinitionValue;
                    this.router.navigateByUrl("/manage/labels-and-definitions").then((x) => {
                        this.alertService.pushAlert(
                            new Alert(
                                `The definition for ${this.fieldDefinition.FieldDefinitionType.FieldDefinitionTypeDisplayName} was successfully updated.`,
                                AlertContext.Success
                            )
                        );
                    });
                },
                error: (error) => {
                    this.cdr.detectChanges();
                },
                complete: () => {
                    this.isLoading = false;
                },
            });
    }

    canExit(): boolean {
        return this.fieldDefinition.FieldDefinitionValue == this.orignalFieldDefinitionValue;
    }
}
