import { DialogModule } from "@angular/cdk/dialog";
import {
    CdkDrag,
    CdkDragDrop,
    CdkDragHandle,
    CdkDragPlaceholder,
    CdkDragPreview,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray,
    transferArrayItem,
} from "@angular/cdk/drag-drop";
import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelHeader, MatExpansionPanelTitle } from "@angular/material/expansion";
import { MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatOption, MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { debounceTime, forkJoin, map, merge, mergeAll, mergeMap, Observable, share, shareReplay, Subscription, switchMap, tap } from "rxjs";
import { ConfirmService } from "src/app/services/confirm.service";
import { PageHeaderComponent } from "src/app/shared/components/page-header/page-header.component";
import { ChecklistService } from "src/app/shared/generated/api/checklist.service";
import { ComplianceRequirementService } from "src/app/shared/generated/api/compliance-requirement.service";
import { FrequencyService } from "src/app/shared/generated/api/frequency.service";
import { ComplianceRequirementTypeService } from "src/app/shared/generated/api/compliance-requirement-type.service";
import { ScopeService } from "src/app/shared/generated/api/scope.service";
import {
    ChecklistDto,
    ChecklistItemDto,
    ComplianceRequirementDto,
    ComponentChecklistsUpsertDto,
    ComponentUpsertDto,
    FrequencyDto,
    ComplianceRequirementTypeDto,
    PhaseDto,
    ResourceCategoryDto,
    ScopeDto,
    ChecklistItemStatusDto,
} from "src/app/shared/generated/model/models";
import { Alert } from "src/app/shared/models/alert";
import { ChecklistItemFilterVisiblePipe } from "src/app/shared/pipes/checklist-item-filtered/checklist-item-filtered.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { PhaseService } from "src/app/shared/generated/api/phase.service";
import { ResourceCategoryService } from "src/app/shared/generated/api/resource-category.service";
import {
    ChecklistUpsertDialogComponent,
    ChecklistUpsertDialogData,
    ChecklistUpsertDialogResponse,
} from "src/app/shared/components/dialogs/checklist-upsert-dialog/checklist-upsert-dialog.component";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";
import { IDeactivateComponent } from "src/app/shared/guards/unsaved-changes-guard";
import { BeaconLoadingOverlayComponent } from "src/app/shared/components/beacon-loading-overlay/beacon-loading-overlay.component";
import { ChecklistItemComponent } from "src/app/shared/components/checklist-item/checklist-item.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { ObjectInArrayPipe } from "src/app/shared/pipes/object-in-array/object-in-array.pipe";
import {
    SelectedChecklistItemsAssignDialogComponent,
    SelectedChecklistItemsAssignDialogData,
} from "src/app/shared/components/dialogs/selected-checklist-items-assign-dialog/selected-checklist-items-assign-dialog.component";
import { ChecklistItemVisibleCountPipe } from "src/app/shared/pipes/checklist-item-visible-count/checklist-item-visible-count.pipe";
import { routeParams } from "src/app/app.routes";
import { FileService } from "src/app/services/file/file.service";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";

@Component({
    selector: "component-checklists-manage",
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatSuffix,
        BeaconLoadingOverlayComponent,
        MatCheckboxModule,
        ChecklistItemComponent,
        LoadingSpinnerComponent,
        MatIconButton,
        MatInput,
        MatAutocomplete,
        MatAutocompleteModule,
        ChecklistItemFilterVisiblePipe,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        MatTooltip,
        ReactiveFormsModule,
        MatSelect,
        MatOption,
        MatLabel,
        MatFormField,
        MatButton,
        MatIcon,
        RouterLink,
        AsyncPipe,
        NgIf,
        NgFor,
        CdkDragHandle,
        CdkDropList,
        CdkDropListGroup,
        CdkDrag,
        CdkDragPreview,
        CdkDragPlaceholder,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatExpansionPanelDescription,
        ObjectInArrayPipe,
        ChecklistItemVisibleCountPipe,
        PageBodyComponent,
    ],
    templateUrl: "./component-checklists-manage.component.html",
    styleUrl: "./component-checklists-manage.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ChecklistItemFilterVisiblePipe],
})
export class ComponentChecklistsManageComponent implements OnInit, OnDestroy, IDeactivateComponent {
    public isEditing: boolean = false;
    public componentID: string;
    public componentData$: Observable<[ChecklistDto[], ChecklistItemDto[]]>;

    public checklists: ChecklistDto[] = [];
    public unassignedComplianceRequirements: ChecklistItemDto[] = [];

    public filterOptions$: Observable<{
        scopes: ScopeDto[];
        frequencies: FrequencyDto[];
        complianceRequirementTypes: ComplianceRequirementTypeDto[];
        phases: PhaseDto[];
        resourceCategories: ResourceCategoryDto[];
        statuses: ChecklistItemStatusDto[];
        speciesCodes: string[];
        milestones: string[];
    }>;

    public filterFormGroup: FormGroup = new FormGroup({
        scope: new FormControl([], { nonNullable: true }),
        frequency: new FormControl([], { nonNullable: true }),
        complianceRequirementType: new FormControl([], { nonNullable: true }),
        phase: new FormControl([], { nonNullable: true }),
        resourceCategory: new FormControl([], { nonNullable: true }),
        status: new FormControl([], { nonNullable: true }),
        speciesCode: new FormControl([], { nonNullable: true }),
        milestone: new FormControl([], { nonNullable: true }),
        search: new FormControl("", { nonNullable: true }),
    });

    public isLoading: boolean = false;
    public filterActive: boolean = false;
    private filterSubscription: Subscription = Subscription.EMPTY;
    public isDirty: boolean = false;
    public initialModel: string;

    private saveChecklistsEvent: EventEmitter<void> = new EventEmitter<void>();
    private saveChecklists$ = this.saveChecklistsEvent.asObservable().pipe(debounceTime(300));
    private savechecklistsSubscription: Subscription = this.saveChecklists$.subscribe(() => {
        this.saveChecklists();
    });
    private fileDownloadSubscription: Subscription;

    constructor(
        private matDialog: MatDialog,
        private checklistService: ChecklistService,
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private complianceRequirementService: ComplianceRequirementService,
        private scopeService: ScopeService,
        private frequencyService: FrequencyService,
        private complianceRequirementTypeService: ComplianceRequirementTypeService,
        private phaseService: PhaseService,
        private resourceCategoryService: ResourceCategoryService,
        private checklistItemFilterVisiblePipe: ChecklistItemFilterVisiblePipe,
        private fileService: FileService,
        private commitmentService: CommitmentService
    ) {}

    canExit: () => Observable<boolean> | Promise<boolean> | boolean = () => {
        return !this.isDirty && !this.isLoading;
    };

    ngOnDestroy(): void {
        this.filterSubscription.unsubscribe();
        this.savechecklistsSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.componentData$ = this.activatedRoute.paramMap.pipe(
            map((params) => params.get(routeParams.componentID)),
            switchMap((componentID) => {
                this.componentID = componentID;
                return forkJoin([
                    this.checklistService.componentsComponentIDChecklistsGet(componentID),
                    this.complianceRequirementService.componentComponentIDUnassignedComplianceRequirementsGet(componentID),
                ]);
            }),
            tap(([checklists, unassignedComplianceRequirements]) => {
                this.checklists = checklists;
                this.unassignedComplianceRequirements = unassignedComplianceRequirements;
                this.initialModel = JSON.stringify(this.compileModel());
            })
        );

        this.filterOptions$ = forkJoin([
            this.phaseService.phasesGet(),
            this.scopeService.scopesGet(),
            this.frequencyService.frequenciesGet(),
            this.complianceRequirementTypeService.complianceRequirementTypesGet(),
            this.resourceCategoryService.resourceCategoriesGet(),
            this.checklistService.checklistItemStatusesGet(),
            this.commitmentService.commitmentsSpeciesCodesGet(),
            this.complianceRequirementService.complianceRequirementsMilestonesGet(),
        ]).pipe(
            map(([phases, scopes, frequencies, complianceRequirementTypes, resourceCategories, statuses, speciesCodes, milestones]) => {
                return { phases, scopes, frequencies, complianceRequirementTypes, resourceCategories, statuses, speciesCodes, milestones };
            })
        );

        this.filterSubscription = this.filterFormGroup.valueChanges.subscribe((event) => {
            this.filterActive = Object.values(event).some((x) => (x !== null && x !== undefined && x !== "" && Array.isArray(x) ? x.length > 0 : false));
        });
    }

    deleteChecklist(checklistDto: ChecklistDto): void {
        this.confirmService
            .confirm({
                color: "warn",
                icon: "warning",
                header: `Delete checklist "${checklistDto.Name}"`,
                text: `Are you sure you want to delete this checklist?`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.checklistService.componentsComponentIDChecklistsChecklistIDDelete(checklistDto.ComponentID, checklistDto.ChecklistID).subscribe({
                    next: (value) => {
                        this.alertService.pushAlert(new Alert("Checklist deleted successfully"));
                        const checklistItemsToOrphan = this.checklists.find((checklist) => checklist.ChecklistID == checklistDto.ChecklistID).ChecklistItems;
                        this.unassignedComplianceRequirements = [...this.unassignedComplianceRequirements, ...checklistItemsToOrphan];
                        this.checklists = [...this.checklists.filter((checklist) => checklist.ChecklistID !== checklistDto.ChecklistID)];
                        this.cdr.markForCheck();
                    },
                });
            });
    }

    drop(
        event:
            | CdkDragDrop<ChecklistItemDto[]>
            | {
                  item: { data: ChecklistItemDto };
                  previousContainer: { data: ChecklistItemDto[] };
                  container: { data: ChecklistItemDto[] };
                  previousIndex: number;
                  currentIndex: number;
              }
    ) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.item.data.SortOrder, event.currentIndex);
        }

        // set sort orders from the front-end
        event.container.data.forEach((item, index) => {
            item.SortOrder = index;
        });
        event.previousContainer.data.forEach((item, index) => {
            item.SortOrder = index;
        });

        this.isDirty = JSON.stringify(this.compileModel()) !== this.initialModel;
        this.saveChecklistsEvent.emit();
    }

    saveChecklists() {
        if (!this.isDirty) return;
        this.isLoading = true;
        this.cdr.markForCheck();
        const model = this.compileModel();

        this.checklistService.componentsComponentIDChecklistItemsPut(this.componentID, model).subscribe({
            next: (value) => {
                this.isDirty = false;
                this.isLoading = false;
                this.initialModel = JSON.stringify(this.compileModel());
                this.alertService.pushAlert(new Alert("Checklist(s) saved successfully", AlertContext.Success));

                this.handleSaveResponse(value);

                this.selectedChecklistItems = [];
                this.filterFormGroup.patchValue({});
                this.cdr.markForCheck();
            },
            error: (error) => {
                this.isLoading = false;
                this.alertService.pushAlert(new Alert("There was an error updating your Checklist(s)", AlertContext.Danger));
                this.cdr.markForCheck();
            },
        });
    }

    handleSaveResponse(responseChecklistItems: ChecklistItemDto[]) {
        this.checklists.forEach((checklist) => {
            checklist.ChecklistItems.forEach((checklistItem) => {
                const updatedChecklistItem = responseChecklistItems.find((x) => x.ChecklistItemID == checklistItem.ChecklistItemID);
                checklistItem.ChecklistID = updatedChecklistItem.ChecklistID;
            });
        });

        this.unassignedComplianceRequirements.forEach((checklistItem) => {
            const updatedChecklistItem = responseChecklistItems.find((x) => x.ChecklistItemID == checklistItem.ChecklistItemID);
            checklistItem.ChecklistID = updatedChecklistItem.ChecklistID;
        });
    }

    private compileModel(): ComponentChecklistsUpsertDto {
        const mappedChecklistItems = this.checklists.map((x) => x.ChecklistItems.map((y) => ({ ...y, ChecklistID: x.ChecklistID }))).flat();
        const allChecklistItems = [...mappedChecklistItems, ...this.unassignedComplianceRequirements.map((y) => ({ ...y, ChecklistID: null }))];
        const model = {
            ComponentID: this.componentID,
            ChecklistItems: allChecklistItems,
        } as ComponentChecklistsUpsertDto;
        return model;
    }

    filterDisplayFn(value: ScopeDto | FrequencyDto | ComplianceRequirementTypeDto): string {
        return value?.Name;
    }

    openUpsertModal(checklistID: string = null) {
        const dialogRef = this.matDialog.open<ChecklistUpsertDialogComponent, ChecklistUpsertDialogData>(ChecklistUpsertDialogComponent, {
            width: "800px",
            data: { ComponentID: this.componentID, ChecklistID: checklistID },
        });

        dialogRef.afterClosed().subscribe((result: ChecklistUpsertDialogResponse) => {
            if (result) {
                if (result.Created) {
                    this.checklists = [...this.checklists, result.Checklist];
                } else {
                    const updatedChecklistIndex = this.checklists.findIndex((x) => x.ChecklistID == result.Checklist.ChecklistID);
                    this.checklists[updatedChecklistIndex] = result.Checklist;
                }
                this.cdr.markForCheck();
            }
        });
    }

    // Selected checklist items for editing
    public selectedChecklistItems: ChecklistItemDto[] = [];

    enableEditing() {
        this.isEditing = true;
    }

    finishEditing() {
        this.isEditing = false;
        this.selectedChecklistItems = [];
    }

    changedChecklistItemSelection(checked: boolean, checklistItem: ChecklistItemDto) {
        if (checked) {
            this.selectedChecklistItems = [...this.selectedChecklistItems, checklistItem];
        } else {
            this.selectedChecklistItems = this.selectedChecklistItems.filter((x) => x.ChecklistItemID !== checklistItem.ChecklistItemID);
        }
    }

    openAssignModal() {
        const dialogRef = this.matDialog.open<SelectedChecklistItemsAssignDialogComponent, SelectedChecklistItemsAssignDialogData>(
            SelectedChecklistItemsAssignDialogComponent,
            { width: "600px", data: { SelectedChecklistItems: this.selectedChecklistItems, Checklists: this.checklists } }
        );

        dialogRef.afterClosed().subscribe((checklistID: string) => {
            if (checklistID) {
                // move the selected checklist items to the selected checklist
                const targetContainer =
                    checklistID == SelectedChecklistItemsAssignDialogComponent.UnassignedOption
                        ? this.unassignedComplianceRequirements
                        : this.checklists.find((x) => x.ChecklistID === checklistID).ChecklistItems;

                const selectedChecklistItemsNotAlreadyInTargetContainer = this.selectedChecklistItems.filter(
                    (x) => !targetContainer.some((y) => y.ChecklistItemID === x.ChecklistItemID)
                );
                let previousIndex;
                let previousContainer;
                let targetIndex = targetContainer.length;
                selectedChecklistItemsNotAlreadyInTargetContainer.forEach((x) => {
                    if (x.ChecklistID) {
                        var previousChecklist = this.checklists.find((y) => y.ChecklistID === x.ChecklistID);
                        previousContainer = previousChecklist.ChecklistItems;
                        previousIndex = previousContainer.findIndex((y) => y.ChecklistItemID === x.ChecklistItemID);
                    } else {
                        previousIndex = this.unassignedComplianceRequirements.findIndex((y) => y.ChecklistItemID === x.ChecklistItemID);
                        previousContainer = this.unassignedComplianceRequirements;
                    }
                    this.drop({
                        item: { data: x },
                        previousContainer: { data: previousContainer },
                        container: { data: targetContainer },
                        previousIndex: previousIndex,
                        currentIndex: targetIndex,
                    });
                    targetIndex++;
                });

                this.cdr.markForCheck();
            }
        });
    }

    selectAllChecklistItems(event, inChecklistItems: ChecklistDto[] = null) {
        event.stopPropagation();

        let visibleChecklistItems: ChecklistItemDto[];
        if (inChecklistItems) {
            visibleChecklistItems = inChecklistItems.filter((x) => this.checklistItemFilterVisiblePipe.transform(x, this.filterFormGroup.value));
        } else {
            let allChecklistItems = this.checklists.map((x) => x.ChecklistItems).flat();
            allChecklistItems = [...allChecklistItems, ...this.unassignedComplianceRequirements];
            visibleChecklistItems = allChecklistItems.filter((x) => this.checklistItemFilterVisiblePipe.transform(x, this.filterFormGroup.value));
        }

        let notAlreadyPresentItems = visibleChecklistItems.filter((x) => !this.selectedChecklistItems.some((y) => y.ChecklistItemID === x.ChecklistItemID));

        this.selectedChecklistItems = [...this.selectedChecklistItems, ...notAlreadyPresentItems];
        this.cdr.markForCheck();
    }

    deselectAllChecklistItems() {
        this.selectedChecklistItems = [];
        this.cdr.markForCheck();
    }

    downloadChecklistItemsByChecklist(event: Event, checklistID: string, checklistName: string) {
        event.stopPropagation();
        this.fileDownloadSubscription = this.checklistService
            .componentsComponentIDChecklistsChecklistIDStatusReportDownloadGet(this.componentID, checklistID)
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.fileService.handleResponse(response, `Checklist_${checklistName}_status-report.csv`);
                    }
                },
                error: () => {
                    this.alertService.pushAlert(new Alert("Error getting report.", AlertContext.Danger));
                },
                complete: () => {
                    this.cdr.markForCheck();
                },
            });
    }

    downloadChecklistItemsForComponent(event: Event) {
        event.stopPropagation();
        this.fileDownloadSubscription = this.checklistService.componentsComponentIDChecklistsStatusReportsDownloadGet(this.componentID).subscribe({
            next: (response) => {
                if (response) {
                    this.fileService.handleResponse(response, `Component_${this.componentID}_status-reports.csv`);
                }
            },
            error: () => {
                this.alertService.pushAlert(new Alert("Error getting report.", AlertContext.Danger));
            },
            complete: () => {
                this.cdr.markForCheck();
            },
        });
    }

    getEvidenceNames(checklistItem: ChecklistItemDto): string[] {
        if (!checklistItem.EvidencesOfCompliance) return [];
        return checklistItem.EvidencesOfCompliance.map((e) => " " + e.Title);
    }

    getNumberOfEvidenceFiles(checklistItem: ChecklistItemDto): number {
        if (!checklistItem.EvidencesOfCompliance) return 0;
        return checklistItem.EvidencesOfCompliance.reduce((total, evidence) => {
            return total + (evidence.EvidenceOfComplianceFiles ? evidence.EvidenceOfComplianceFiles.length : 0);
        }, 0);
    }
}
