/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type CRSType = 1 | 2 | 3;

export const CRSType = {
    Unspecified: 1 as CRSType,
    Name: 2 as CRSType,
    Link: 3 as CRSType
};
