/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CommitmentDto } from '../model/commitment-dto';
import { CommitmentGridDto } from '../model/commitment-grid-dto';
import { CommitmentStatisticsDto } from '../model/commitment-statistics-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ApiService } from '../../services';
import { TenantService } from '../../services/tenant/tenant-service.service';


@Injectable({
  providedIn: 'root'
})
export class CommitmentService {

    protected basePath = 'http://localhost';
    protected unmodifiedBasePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService, private tenantService: TenantService) {
        if (basePath) {
            this.basePath = basePath;
            this.unmodifiedBasePath = basePath;
        }

        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
            this.unmodifiedBasePath = basePath || configuration.basePath || this.basePath;
        }

        tenantService.currentTenant$.subscribe(tenant => {
            this.setBasePath(tenant);
        });
    }

    private setBasePath(tenant) {
        if (tenant && tenant.SubDomain) {
            let schemalessBasePath = this.unmodifiedBasePath.replace("https://", "").replace("http://", "");
            this.basePath = `https://${tenant.SubDomain}.${schemalessBasePath}`;
        } else {                
            this.basePath = this.unmodifiedBasePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param commitmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDDelete(commitmentID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public commitmentsCommitmentIDDelete(commitmentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public commitmentsCommitmentIDDelete(commitmentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public commitmentsCommitmentIDDelete(commitmentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("CommitmentService")){
            let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.delete<any>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDGet(commitmentID: string, observe?: 'body', reportProgress?: boolean): Observable<CommitmentDto>;
    public commitmentsCommitmentIDGet(commitmentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommitmentDto>>;
    public commitmentsCommitmentIDGet(commitmentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommitmentDto>>;
    public commitmentsCommitmentIDGet(commitmentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("CommitmentService")){
            let serviceRequest = this.httpClient.get<CommitmentDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<CommitmentDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param commitmentID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsCommitmentIDStatisticsGet(commitmentID: string, observe?: 'body', reportProgress?: boolean): Observable<CommitmentStatisticsDto>;
    public commitmentsCommitmentIDStatisticsGet(commitmentID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommitmentStatisticsDto>>;
    public commitmentsCommitmentIDStatisticsGet(commitmentID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommitmentStatisticsDto>>;
    public commitmentsCommitmentIDStatisticsGet(commitmentID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commitmentID === null || commitmentID === undefined) {
            throw new Error('Required parameter commitmentID was null or undefined when calling commitmentsCommitmentIDStatisticsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("CommitmentService")){
            let serviceRequest = this.httpClient.get<CommitmentStatisticsDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/statistics`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<CommitmentStatisticsDto>(`${this.basePath}/commitments/${encodeURIComponent(String(commitmentID))}/statistics`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CommitmentDto>>;
    public commitmentsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CommitmentDto>>>;
    public commitmentsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CommitmentDto>>>;
    public commitmentsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("CommitmentService")){
            let serviceRequest = this.httpClient.get<Array<CommitmentDto>>(`${this.basePath}/commitments`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<CommitmentDto>>(`${this.basePath}/commitments`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param clientCommitmentID 
     * @param title 
     * @param commitmentTypeID 
     * @param sourceID 
     * @param fullCommitmentText 
     * @param commitmentID 
     * @param resourceCategoryID 
     * @param technicalLeadIDs 
     * @param seasonality 
     * @param workActivities 
     * @param locationDescription 
     * @param generalGuidance 
     * @param speciesCode 
     * @param originatorOfChange 
     * @param summaryOfChange 
     * @param commitmentFilesJsonStrings 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsPut(clientCommitmentID: string, title: string, commitmentTypeID: string, sourceID: string, fullCommitmentText: string, commitmentID?: string, resourceCategoryID?: string, technicalLeadIDs?: Array<number>, seasonality?: string, workActivities?: string, locationDescription?: string, generalGuidance?: string, speciesCode?: string, originatorOfChange?: string, summaryOfChange?: string, commitmentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<CommitmentDto>;
    public commitmentsPut(clientCommitmentID: string, title: string, commitmentTypeID: string, sourceID: string, fullCommitmentText: string, commitmentID?: string, resourceCategoryID?: string, technicalLeadIDs?: Array<number>, seasonality?: string, workActivities?: string, locationDescription?: string, generalGuidance?: string, speciesCode?: string, originatorOfChange?: string, summaryOfChange?: string, commitmentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommitmentDto>>;
    public commitmentsPut(clientCommitmentID: string, title: string, commitmentTypeID: string, sourceID: string, fullCommitmentText: string, commitmentID?: string, resourceCategoryID?: string, technicalLeadIDs?: Array<number>, seasonality?: string, workActivities?: string, locationDescription?: string, generalGuidance?: string, speciesCode?: string, originatorOfChange?: string, summaryOfChange?: string, commitmentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommitmentDto>>;
    public commitmentsPut(clientCommitmentID: string, title: string, commitmentTypeID: string, sourceID: string, fullCommitmentText: string, commitmentID?: string, resourceCategoryID?: string, technicalLeadIDs?: Array<number>, seasonality?: string, workActivities?: string, locationDescription?: string, generalGuidance?: string, speciesCode?: string, originatorOfChange?: string, summaryOfChange?: string, commitmentFilesJsonStrings?: Array<string>, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (clientCommitmentID === null || clientCommitmentID === undefined) {
            throw new Error('Required parameter clientCommitmentID was null or undefined when calling commitmentsPut.');
        }

        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling commitmentsPut.');
        }

        if (commitmentTypeID === null || commitmentTypeID === undefined) {
            throw new Error('Required parameter commitmentTypeID was null or undefined when calling commitmentsPut.');
        }

        if (sourceID === null || sourceID === undefined) {
            throw new Error('Required parameter sourceID was null or undefined when calling commitmentsPut.');
        }

        if (fullCommitmentText === null || fullCommitmentText === undefined) {
            throw new Error('Required parameter fullCommitmentText was null or undefined when calling commitmentsPut.');
        }













        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (clientCommitmentID !== undefined) {
            formParams = formParams.append('ClientCommitmentID', <any>clientCommitmentID) || formParams;
            
        }
        if (title !== undefined) {
            formParams = formParams.append('Title', <any>title) || formParams;
            
        }
        if (commitmentTypeID !== undefined) {
            formParams = formParams.append('CommitmentTypeID', <any>commitmentTypeID) || formParams;
            
        }
        if (sourceID !== undefined) {
            formParams = formParams.append('SourceID', <any>sourceID) || formParams;
            
        }
        if (commitmentID !== undefined) {
            formParams = formParams.append('CommitmentID', <any>commitmentID) || formParams;
            
        }
        if (fullCommitmentText !== undefined) {
            formParams = formParams.append('FullCommitmentText', <any>fullCommitmentText) || formParams;
            
        }
        if (resourceCategoryID !== undefined) {
            formParams = formParams.append('ResourceCategoryID', <any>resourceCategoryID) || formParams;
            
        }
        if (technicalLeadIDs !== undefined) {
            for (let i = 0; i < technicalLeadIDs.length; i++) {
                formParams = formParams.append('TechnicalLeadIDs[]', <any>technicalLeadIDs[i]) || formParams;
            }
            
            
        }
        if (seasonality !== undefined) {
            formParams = formParams.append('Seasonality', <any>seasonality) || formParams;
            
        }
        if (workActivities !== undefined) {
            formParams = formParams.append('WorkActivities', <any>workActivities) || formParams;
            
        }
        if (locationDescription !== undefined) {
            formParams = formParams.append('LocationDescription', <any>locationDescription) || formParams;
            
        }
        if (generalGuidance !== undefined) {
            formParams = formParams.append('GeneralGuidance', <any>generalGuidance) || formParams;
            
        }
        if (speciesCode !== undefined) {
            formParams = formParams.append('SpeciesCode', <any>speciesCode) || formParams;
            
        }
        if (originatorOfChange !== undefined) {
            formParams = formParams.append('OriginatorOfChange', <any>originatorOfChange) || formParams;
            
        }
        if (summaryOfChange !== undefined) {
            formParams = formParams.append('SummaryOfChange', <any>summaryOfChange) || formParams;
            
        }
        if (commitmentFilesJsonStrings !== undefined) {
            for (let i = 0; i < commitmentFilesJsonStrings.length; i++) {
                formParams = formParams.append('CommitmentFilesJsonStrings[]', <any>commitmentFilesJsonStrings[i]) || formParams;
            }
            
            
        }
        if (files !== undefined) {
            for (let i = 0; i < files.length; i++) {
                formParams = formParams.append('files', <any>files[i]) || formParams;
            }
        }

        if(this.tenantService.excludedServiceNames.includes("CommitmentService")){
            let serviceRequest = this.httpClient.put<CommitmentDto>(`${this.basePath}/commitments`,
                convertFormParamsToString ? formParams.toString() : formParams,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.put<CommitmentDto>(`${this.basePath}/commitments`,
                    convertFormParamsToString ? formParams.toString() : formParams,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsSimpleGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CommitmentGridDto>>;
    public commitmentsSimpleGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CommitmentGridDto>>>;
    public commitmentsSimpleGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CommitmentGridDto>>>;
    public commitmentsSimpleGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("CommitmentService")){
            let serviceRequest = this.httpClient.get<Array<CommitmentGridDto>>(`${this.basePath}/commitments-simple`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<CommitmentGridDto>>(`${this.basePath}/commitments-simple`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commitmentsSpeciesCodesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public commitmentsSpeciesCodesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public commitmentsSpeciesCodesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public commitmentsSpeciesCodesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if(this.tenantService.excludedServiceNames.includes("CommitmentService")){
            let serviceRequest = this.httpClient.get<Array<string>>(`${this.basePath}/commitments/species-codes`,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
            return serviceRequest;
        }
    
        return this.tenantService.currentTenant$.pipe(
            filter(currentTenant => currentTenant != null),
            take(1),
            switchMap((t) => {
                // After the first emission of currentTenant, switch to the serviceRequest observable, and ensure the basepath is correct.
                this.setBasePath(t);
                let serviceRequest = this.httpClient.get<Array<string>>(`${this.basePath}/commitments/species-codes`,
                    {
                        withCredentials: this.configuration.withCredentials,
                        headers: headers,
                        observe: observe,
                        reportProgress: reportProgress
                    }
                ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));

                return serviceRequest;
            })
        );
    }

}
