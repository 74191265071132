import { Component, computed, inject, input, InputSignal, output } from "@angular/core";
import { ComplianceRequirementDto } from "../../generated/model/compliance-requirement-dto";
import { KvPairComponent } from "../kv-pair/kv-pair.component";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule } from "@angular/material/chips";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CommonModule } from "@angular/common";
import { WithPermissionPipe } from "../../pipes/with-permission/with-permission.pipe";
import { WithFlagPipe } from "../../pipes/with-flag/with-flag.pipe";
import { FlagEnum } from "../../generated/enum/flag-enum";
import { map, Observable, share, tap } from "rxjs";
import { CommitmentVersionStatusEnum } from "../../generated/enum/commitment-version-status-enum";
import { PermissionEnum } from "../../generated/enum/permission-enum";
import { RightsEnum } from "../../models/enums/rights.enum";
import { ComplianceRequirementFormComponent } from "../../../pages/compliance-requirement-for-commitment-list/form/compliance-requirement-form.component";
import { ComplianceRequirementTagService } from "../../generated/api/compliance-requirement-tag.service";
import { VComplianceRequirementTagDto } from "../../generated/model/v-compliance-requirement-tag-dto";
import { NgForm } from "@angular/forms";
import { ComplianceRequirementUpsertDto } from "../../generated/model/compliance-requirement-upsert-dto";

@Component({
    selector: "compliance-requirement-builder-bar",
    standalone: true,
    imports: [KvPairComponent, MatButtonModule, MatIconModule, MatChipsModule, CommonModule, WithPermissionPipe, WithFlagPipe, ComplianceRequirementFormComponent],
    templateUrl: "./compliance-requirement-builder-bar.component.html",
    styleUrl: "./compliance-requirement-builder-bar.component.scss",
})
export class ComplianceRequirementBuilderBarComponent {
    
    public isEditing: boolean = false;
    authenticationService = inject(AuthenticationService);
    private complianceRequirementTagService: ComplianceRequirementTagService = inject(ComplianceRequirementTagService);
    public allComplianceRequirementTags$: Observable<VComplianceRequirementTagDto[]> = this.complianceRequirementTagService.complianceRequirementTagsGet().pipe(share());
    
    complianceRequirement = input.required<ComplianceRequirementDto>();
    disableButtons = input<boolean>(false);
    tags = computed<string[]>(() => {
        if (!this.complianceRequirement().Tags) return [];
        let tags = JSON.parse(this.complianceRequirement().Tags);
        return tags.map((x) => x.Name);
    });
    viewingMoreDetails: boolean = false;

    clickedDelete = output<ComplianceRequirementDto>();
    clickedEdit = output<ComplianceRequirementDto>();
    canceledEdit = output<boolean>();
    editFormSubmitted = output<{
        currentComplianceReqID: string;
        currentCommitmentID: string;
        updatedForm: ComplianceRequirementUpsertDto;
    }>();
    

    canDelete$ = this.authenticationService.currentUser$.pipe(
        map((user) => {
            if (this.complianceRequirement().Commitment.CommitmentVersionStatus.CommitmentVersionStatusID != CommitmentVersionStatusEnum.Draft) {
                return (
                    this.authenticationService.hasFlag(user, FlagEnum.CanEditFinalizedCommitments) &&
                    this.authenticationService.hasPermission(user, PermissionEnum.CommitmentRights, RightsEnum.Delete)
                );
            }
    
            return (
                this.complianceRequirement().Commitment.CommitmentVersionStatus.CommitmentVersionStatusID == CommitmentVersionStatusEnum.Draft &&
                this.authenticationService.hasPermission(user, PermissionEnum.CommitmentRights, RightsEnum.Delete)
            );
        }),
    )

    clickedDeleteButton() {
        this.clickedDelete.emit(this.complianceRequirement());
    }

    clickedEditButton() {
        this.clickedEdit.emit(this.complianceRequirement());
        this.isEditing = true;
    }

    cancelEdit($event: boolean) {
        this.isEditing = !$event;
        this.canceledEdit.emit($event);
    }
    formUpdated($event: {
        currentComplianceReqID: string;
        updatedForm: ComplianceRequirementUpsertDto;
    }) {
        this.editFormSubmitted.emit({...$event, currentCommitmentID: this.complianceRequirement().Commitment.CommitmentID});
        this.isEditing = false;
    }
}
