<form class="form grid-12" #componentForm="ngForm">
    <div class="g-col-6">
        <esa-material-ff name="Name" [editMode]="editMode" [required]="true" type="text" [(ngModel)]="componentUpsertDto.Name" [customLabel]="nameCustomLabel">
        </esa-material-ff>
        <ng-template #nameCustomLabel>
            <custom-form-label label="Name" fieldDefinitionType="Name"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="Project"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="projects$ | async"
            optionLabel="Name"
            optionValue="ProjectID"
            [(ngModel)]="componentUpsertDto.ProjectID"
            [customLabel]="projectCustomLabel"
            [customDisplay]="projectCustomDisplay">
        </esa-material-ff>
        <ng-template #projectCustomLabel>
            <custom-form-label label="Project"></custom-form-label>
        </ng-template>
        <ng-template #projectCustomDisplay>
            <a [routerLink]="['/compliance-tracking/projects/', projectID]" *ngIf="component && projectID && projectName">
                <p>{{ projectName }}</p>
            </a>
        </ng-template>

        <esa-material-ff
            name="Status"
            [editMode]="editMode"
            [required]="true"
            type="dropdown"
            [options]="componentStatuses"
            optionLabel="Name"
            optionValue="ComponentStatusID"
            [(ngModel)]="componentUpsertDto.ComponentStatusID"
            [label]="'Status'">
        </esa-material-ff>

        <esa-material-ff
            name="Start Date"
            [editMode]="editMode"
            [required]="false"
            type="date"
            [(ngModel)]="componentUpsertDto.StartDate"
            [label]="'Start Date'">
        </esa-material-ff>

        <esa-material-ff
            name="Expected End Date"
            [editMode]="editMode"
            [required]="false"
            type="date"
            [(ngModel)]="componentUpsertDto.ExpectedEndDate"
            [label]="'Expected End Date'">
        </esa-material-ff>
    </div>

    <div class="g-col-12">
        <div class="kv-pair" [ngClass]="editMode ? '' : 'form-spacing'">
            <custom-form-label label="Description" fieldDefinitionType="Description"></custom-form-label>
            <div class="value" *ngIf="editMode">
                <div class="grid-12">
                    <div class="g-col-12">
                        <tinymce-editor #tinyMceEditor name="Description" [(content)]="componentUpsertDto.Description"></tinymce-editor>
                    </div>
                </div>
            </div>
            <div
                [innerHTML]="componentUpsertDto.Description === null ? '-' : (componentUpsertDto.Description | bypassHtml)"
                class="value-display"
                *ngIf="!editMode"></div>
        </div>
    </div>

    <div class="g-col-12 actions-bar" *ngIf="editMode" style="margin-top: 1rem">
        <span [matTooltip]="!componentForm.form.valid ? 'You are missing requried fields.' : ''">
            <button mat-flat-button style="margin-right: 1rem" color="primary" (click)="saveForm(form)" [disabled]="!componentForm.form.valid">
                <mat-icon>save</mat-icon> Save
            </button>
        </span>

        <button mat-button color="primary" (click)="cancelEditMode()"><mat-icon>cancel</mat-icon> Cancel</button>
    </div>
</form>
