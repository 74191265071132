<main class="main">
    <custom-rich-text [customRichTextTypeID]="CustomRichTextTypeEnum.ComplianceTrackingModule"></custom-rich-text>

    @if (summary$ | async; as summary) {
        <div class="projects">
            @for (project of summary.Projects; track $index) {
                <project-summary moduleType="compliance-tracking" [project]="project"></project-summary>
            }
        </div>
    } @else {
        <loading-spinner></loading-spinner>
    }
</main>
