import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { AuthenticationService } from "src/app/services/authentication.service";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import { ButtonRendererComponent } from "src/app/shared/components/ag-grid/button-renderer/button-renderer.component";
import { CommitmentRelationshipService } from "src/app/shared/generated/api/commitment-relationship.service";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { CommitmentRelationshipDto } from "src/app/shared/generated/model/commitment-relationship-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { CreateRelationshipDialogComponent } from "./create-relationship-dialog/create-relationship-dialog.component";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";
import { ConfirmService } from "src/app/services/confirm.service";

@Component({
    selector: "commitment-relationship-list",
    templateUrl: "./commitment-relationship-list.component.html",
    styleUrls: ["./commitment-relationship-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, AgGridAngular, MatButton, MatIcon, GridActionsComponent],
})
export class CommitmentRelationshipListComponent implements OnInit {
    @ViewChild("commitmentRelationshipGrid") commitmentRelationshipGrid: AgGridAngular;

    commitment: CommitmentDto;
    currentUser: UserDto;

    relationships: CommitmentRelationshipDto[];

    public rowData: any[];
    public columnDefs: ColDef[];

    constructor(
        private currentCommitmentService: CurrentCommitmentService,
        private commitmentRelationshipService: CommitmentRelationshipService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private gridCommonService: GridCommonService,
        private confirmService: ConfirmService
    ) {}

    ngOnInit(): void {
        this.currentCommitmentService.currentCommitment$.subscribe((result) => {
            this.commitment = result;
            if (this.commitment) {
                this.refreshData();
            }
        });

        this.authenticationService.currentUser$.subscribe((result) => {
            this.currentUser = result;
            this.buildColumnDefs();
            this.cdr.markForCheck();
        });
    }

    buildColumnDefs() {
        this.columnDefs = [
            {
                headerName: "Relationship",
                field: "RelationshipType.RelationshipText",
                tooltipField: "Relationship Type",
                width: 200,
            },
            {
                headerName: "ID",
                headerTooltip: "ID",
                valueGetter: function (params: any) {
                    return {
                        LinkValue: params.data.RelatedCommitment.CommitmentID,
                        LinkDisplay: params.data.RelatedCommitment.ClientCommitmentID,
                    };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/commitment-library/commitments/"),
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.RelatedCommitment.ClientCommitmentID;
                },
                comparator: function (linkA, linkB, nodeA, nodeB, isDescending) {
                    let valueA = linkA.LinkDisplay.toLowerCase();
                    let valueB = linkB.LinkDisplay.toLowerCase();

                    return valueA.localeCompare(valueB, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    });
                },
                type: "rightAligned",
                flex: 1,
                sort: "asc",
            },
            {
                headerName: "Title",
                field: "RelatedCommitment.Title",
                tooltipField: "Title",
                flex: 4,
            },
            {
                headerName: "Latest Version?",
                headerTooltip: "Is Latest Version?",
                field: "$IsLatestVersionYesNo",
                flex: 1,
            },
        ];

        if (this.canUpdateCommitment()) {
            this.columnDefs.push({
                cellRenderer: ButtonRendererComponent,
                cellRendererParams: {
                    onClick: this.deleteRelationship.bind(this),
                    icon: "delete",
                    type: "clear",
                },
                cellStyle: (params) => {
                    return { "text-overflow": "unset" };
                },
                filter: null,
                sortable: false,
                width: 110,
                colId: "delete",
            });
        }
    }

    refreshData() {
        this.commitmentRelationshipService.commitmentsCommitmentIDRelationshipsGet(this.commitment.CommitmentID).subscribe((results) => {
            this.relationships = results;
            this.rowData = results;

            this.rowData.forEach((rd) => {
                rd.$IsLatestVersionYesNo = rd.RelatedCommitment.IsLatestVersion ? "Yes" : "No";
            });

            this.cdr.markForCheck();
        });
    }

    openCreateRelationshipDialog() {
        const dialogRef = this.dialog.open(CreateRelationshipDialogComponent, {
            data: { commitment: this.commitment },
            minWidth: "50vw",
            maxWidth: "50vw",
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && result.RelationshipTypeID && result.OtherCommitmentID) {
                this.commitmentRelationshipService.commitmentsCommitmentIDRelationshipsPost(this.commitment.CommitmentID, result).subscribe((result) => {
                    this.alertService.pushAlert(new Alert("The relationship was succesfully created.", AlertContext.Success), 5000);
                    this.commitment.RelatedCommitmentCount++;
                    this.currentCommitmentService.setCurrentCommitment(this.commitment);
                    this.refreshData();
                });
            }
        });
    }

    deleteRelationship($event) {
        this.confirmService
            .confirm({
                color: "warn",
                header: `Delete Relationship`,
                text: `Are you sure you want to delete this relationship? This action can not be undone.`,
            })
            .subscribe((result) => {
                if (!result) return;
                this.commitmentRelationshipService
                    .commitmentRelationshipsCommitmentRelationshipIDDelete($event.rowData.CommitmentRelationshipID)
                    .subscribe((result) => {
                        this.alertService.pushAlert(new Alert("The relationship was succesfully deleted.", AlertContext.Success), 5000);

                        this.currentCommitmentService.setCurrentCommitment(this.commitment);
                        this.refreshData();
                    });
            });
    }

    canUpdateCommitment(): boolean {
        return this.currentCommitmentService.canEditCurrentCommitment(this.currentUser, this.commitment);
    }

    onCommitmentRelationshipGridReady(gridEvent) {
        setTimeout(() => {
            this.setDefaultFilter();
            this.commitmentRelationshipGrid.api.hideOverlay();
        });
    }

    setDefaultFilter() {
        this.commitmentRelationshipGrid.api.setFilterModel({
            $IsLatestVersionYesNo: {
                filterType: "text",
                type: "equals",
                filter: "Yes",
            },
        });

        this.commitmentRelationshipGrid.api.onFilterChanged();
        this.cdr.markForCheck();
    }

    canExit() {
        return true;
    }
}
