<ng-container>
    <h2 mat-dialog-title>
        @if (createMode) {
            Create new Checklist
        } @else {
            Edit Checklist
        }
    </h2>
    <mat-dialog-content>
        <component-checklist-form [componentID]="data.ComponentID" [checklistID]="data.ChecklistID" [editMode]="true" (formValueChange)="valueChanged($event)">
        </component-checklist-form>
    </mat-dialog-content>
</ng-container>
<mat-dialog-actions align="end">
    <span [matTooltip]="!model?.valid ? 'You are missing requried fields.' : ''">
        <button mat-flat-button style="margin-right: 1rem" color="primary" type="submit" (click)="save()" [disabled]="!model?.valid || isLoading">
            <mat-icon>save</mat-icon> Save
        </button>
    </span>
    <button mat-button color="primary" mat-dialog-close><mat-icon>cancel</mat-icon> Cancel</button>
</mat-dialog-actions>
