<main class="main">
    <custom-rich-text [customRichTextTypeID]="CustomRichTextTypeEnum.CommitmentLibraryModule"></custom-rich-text>

    @if (summary$ | async; as summary) {
        @if (summary.Projects.length > 0) {
            <div class="projects">
                @for (project of summary.Projects; track $index) {
                    <project-summary moduleType="commitment-library" [project]="project"></project-summary>
                }
            </div>
        } @else {
            <empty-list entityIcon="Project">
                <p>No projects have been created.</p>
                <p>Click the button below to add your first Project.</p>
                <button mat-flat-button color="primary" (click)="createProject()">
                    <mat-icon>add</mat-icon>
                    <span>Create Your First Project</span>
                </button>
            </empty-list>
        }
    } @else {
        <loading-spinner></loading-spinner>
    }
</main>
