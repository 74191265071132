import { AsyncPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltip } from "@angular/material/tooltip";
import { RouterModule, RouterOutlet } from "@angular/router";
import { BeaconEntityIconComponent } from "../../shared/components/beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "compliance-tracking-router-outlet",
    standalone: true,
    imports: [RouterOutlet, AsyncPipe, RouterModule, MatMenuModule, MatIcon, MatTooltip, BeaconEntityIconComponent],
    templateUrl: "./compliance-tracking-router-outlet.component.html",
    styleUrl: "./compliance-tracking-router-outlet.component.scss",
})
export class ComplianceTrackingRouterOutletComponent {
    constructor() {}
}
