<div class="detail-view">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Users</h1>
            <button *ngIf="canCreateUser" mat-flat-button color="primary" matTooltip="Reset Filter" (click)="navigateToInviteUser()">
                <mat-icon>add</mat-icon> Invite User
            </button>
        </div>

        <div class="g-col-6">
            <p>A list of users is shown in the grid below.</p>
        </div>
    </div>

    <div class="all-users">
        <div class="table-responsive">
            <beacon-grid-actions [grid]="usersGrid" downloadFileName="users"></beacon-grid-actions>
            <ag-grid-angular
                #usersGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onUsersGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
