@if (checklistItemStatus) {
    @switch (checklistItemStatus.ChecklistItemStatusID) {
        @case (ChecklistItemStatusEnum.NotStarted) {
            <mat-icon [matTooltip]="checklistItemStatus.Name" class="not-started">schedule</mat-icon>
        }
        @case (ChecklistItemStatusEnum.InProgress) {
            <mat-icon [matTooltip]="checklistItemStatus.Name" color="accent" class="in-progress">published_with_changes</mat-icon>
        }
        @case (ChecklistItemStatusEnum.Complete) {
            <mat-icon [matTooltip]="checklistItemStatus.Name" class="done">check_circle_outline</mat-icon>
        }
    }
}
