<div class="seasonality-trigger-upsert-dialog">
    <h1 mat-dialog-title>
        {{ data.SeasonalityTrigger.SeasonalityTriggerID ? "Edit Seasonality Trigger" : "Add Seasonality Trigger" }}
    </h1>
    <div mat-dialog-content>
        <form [formGroup]="formGroup">
            <div class="form-group">
                <esa-material-ff name="Title" [editMode]="true" [required]="true" type="text" label="Title" formControlName="Title"> </esa-material-ff>
            </div>
            <div class="form-group">
                <esa-material-ff name="Description" [editMode]="true" [required]="false" type="textarea" label="Description" formControlName="Description">
                </esa-material-ff>
            </div>
            <div class="date-select">
                <mat-label class="date-select__label">Start Date</mat-label>
                <mat-form-field class="date-select__input">
                    <input matInput [matDatepicker]="startDatePicker" [formControl]="startDate" />
                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
                </mat-form-field>

                <mat-label class="date-select__label">End Date</mat-label>
                <mat-form-field class="date-select__input">
                    <input matInput [matDatepicker]="endDatePicker" [formControl]="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div mat-dialog-actions align="end">
        <span [matTooltip]="!formGroup.valid ? 'You are missing required fields.' : ''">
            <button
                mat-flat-button
                color="primary"
                style="margin-right: 1rem"
                type="button"
                (click)="onSave()"
                cdkFocusInitial
                [disabled]="!formGroup.valid || !startDate.valid || !endDate.valid">
                Save
            </button>
        </span>
        <button type="button" mat-button mat-dialog-close>Cancel</button>
    </div>
</div>
