@switch (moduleID) {
    @case (ModuleEnum.Core) {
        <mat-icon>home</mat-icon>
    }
    @case (ModuleEnum.CommitmentLibrary) {
        <beacon-entity-icon entity="CommitmentLibrary" />
    }
    @case (ModuleEnum.MonitoringDashboard) {
        <beacon-entity-icon entity="MonitoringDashboard" />
    }
    @case (ModuleEnum.Scheduling) {
        <beacon-entity-icon entity="Scheduling" />
    }
    @case (ModuleEnum.ComplianceTracking) {
        <beacon-entity-icon entity="ComplianceTracking" />
    }
}
