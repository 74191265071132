@switch (componentStatusID) {
    @case (ComponentStatusEnum.Active) {
        <div class="component-status active">Active</div>
    }
    @case (ComponentStatusEnum.Complete) {
        <div class="component-status complete">Complete</div>
    }
    @case (ComponentStatusEnum.Paused) {
        <div class="component-status paused">Paused</div>
    }
    @case (ComponentStatusEnum.Cancelled) {
        <div class="component-status cancelled">Cancelled</div>
    }
    @case (ComponentStatusEnum.NotStarted) {
        <div class="component-status not-started">Not Started</div>
    }
    @default {
        Unknown
    }
}
