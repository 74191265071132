import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, ViewChild } from "@angular/core";
import { PageBodyComponent } from "../../shared/components/page-body/page-body.component";
import { PageHeaderComponent } from "../../shared/components/page-header/page-header.component";
import { BehaviorSubject, combineLatest, concat, concatAll, map, merge, mergeAll, Observable, of, scan, share, Subject, switchMap, tap } from "rxjs";
import { CommitmentService } from "src/app/shared/generated/api/commitment.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CommitmentDto } from "src/app/shared/generated/model/commitment-dto";
import { routeParams } from "src/app/app.routes";
import { CommonModule } from "@angular/common";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { ComplianceRequirementDto } from "src/app/shared/generated/model/compliance-requirement-dto";
import { ComplianceRequirementService } from "src/app/shared/generated/api/compliance-requirement.service";
import { EmptyListComponent } from "../../shared/components/empty-list/empty-list.component";
import { MatButtonModule } from "@angular/material/button";
import { MatStepper, MatStepperModule } from "@angular/material/stepper";
import { MatIcon } from "@angular/material/icon";
import { MatDialog } from "@angular/material/dialog";
import { CreateComplianceRequirementComponent } from "src/app/shared/components/dialogs/create-compliance-requirement/create-compliance-requirement.component";
import { CurrentComplianceRequirementService } from "src/app/services/current-compliance-requirement";
import { FormBuilder, FormsModule, NgForm, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { PhaseDto } from "src/app/shared/generated/model/phase-dto";
import { PhaseService } from "src/app/shared/generated/api/phase.service";
import { TinymceEditorComponent } from "../../shared/components/tinymce-editor/tinymce-editor.component";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { TinyMceConfigPipe } from "../../shared/pipes/tiny-mce-config.pipe";
import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { EventObj } from "@tinymce/tinymce-angular/editor/Events";
import { ComplianceRequirementUpsertDto } from "src/app/shared/generated/model/compliance-requirement-upsert-dto";
import { ComplianceRequirementTypeService } from "src/app/shared/generated/api/compliance-requirement-type.service";
import { ComplianceRequirementTypeDto } from "src/app/shared/generated/model/compliance-requirement-type-dto";
import { MatSelectModule } from "@angular/material/select";
import { LoadingButtonDirective } from "src/app/shared/directives/loading-button/loading-button.directive";
import { ComplianceRequirementBuilderBarComponent } from "../../shared/components/compliance-requirement-builder-bar/compliance-requirement-builder-bar.component";
import { GroupByPipe } from "src/app/shared/pipes/group-by.pipe";
import { ConfirmService } from "src/app/services/confirm.service";
import { EvidenceOfComplianceFileService } from "src/app/shared/generated/api/evidence-of-compliance-file.service";
import { AlertService } from "src/app/shared/services/alert.service";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { FilterPipe } from "src/app/shared/pipes/filter.pipe";
import { ComplianceRequirementTagService } from "src/app/shared/generated/api/compliance-requirement-tag.service";
import { VComplianceRequirementTagDto } from "src/app/shared/generated/model/v-compliance-requirement-tag-dto";
import { CurrentCommitmentService } from "src/app/services/current-commitment/current-commitment.service";
import {
    CdkDrag,
    CdkDragDrop,
    CdkDragHandle,
    CdkDragPlaceholder,
    CdkDragPreview,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray,
    transferArrayItem,
} from "@angular/cdk/drag-drop";
import { IDeactivateComponent } from "src/app/shared/guards/unsaved-changes-guard";
import { FrequencyDto } from "src/app/shared/generated/model/frequency-dto";
import { FrequencyService } from "src/app/shared/generated/api/frequency.service";
import { ScopeService } from "src/app/shared/generated/api/scope.service";
import { ScopeDto } from "src/app/shared/generated/model/scope-dto";
import { KvPairComponent } from "../../shared/components/kv-pair/kv-pair.component";
import { BeaconSelectComponent, IBeaconSelectOption } from "../../shared/components/beacon-select/beacon-select.component";

@Component({
    selector: "commitment-compliance-requirement-builder",
    standalone: true,
    imports: [
        PageBodyComponent,
        MatStepperModule,
        MatFormFieldModule,
        MatIcon,
        MatButtonModule,
        PageHeaderComponent,
        CommonModule,
        LoadingSpinnerComponent,
        EmptyListComponent,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonToggleModule,
        TinymceEditorComponent,
        EditorComponent,
        TinyMceConfigPipe,
        MatSelectModule,
        LoadingButtonDirective,
        ComplianceRequirementBuilderBarComponent,
        GroupByPipe,
        FilterPipe,
        CdkDragHandle,
        CdkDropList,
        CdkDropListGroup,
        CdkDrag,
        CdkDragPreview,
        CdkDragPlaceholder,
        KvPairComponent,
        BeaconSelectComponent,
    ],
    templateUrl: "./commitment-compliance-requirement-builder.component.html",
    styleUrl: "./commitment-compliance-requirement-builder.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommitmentComplianceRequirementBuilderComponent implements OnInit, IDeactivateComponent {
    canExit: () => Observable<boolean> | Promise<boolean> | boolean = () => {
        return !this.isEditingComplianceRequirement && !this.isReordering && !this.creatingNewComplianceRequirement;
    };

    drop(
        event:
            | CdkDragDrop<ComplianceRequirementDto[]>
            | {
                  item: { data: ComplianceRequirementDto };
                  previousContainer: { data: ComplianceRequirementDto[] };
                  container: { data: ComplianceRequirementDto[] };
                  previousIndex: number;
                  currentIndex: number;
              },
        phase: PhaseDto,
        allComplianceRequirements: ComplianceRequirementDto[]
    ) {
        event.item.data.Phase = phase;
        let newPreviousIndex = allComplianceRequirements.indexOf(event.item.data);
        let startIndexForPhase = allComplianceRequirements.findIndex((x) => x.Phase.PhaseID === phase.PhaseID);
        let newIndex = event.currentIndex + startIndexForPhase;
        moveItemInArray(allComplianceRequirements, newPreviousIndex, newIndex);

        allComplianceRequirements.forEach((item, index) => {
            item.DisplayIndex = index;
        });
        this.cdr.markForCheck();
    }

    public isEditingComplianceRequirement: boolean = false;
    public isLoadingSubmit: boolean = false;
    public creatingNewComplianceRequirement: boolean = false;
    public creatingWithPhaseID: string = null;
    public isReordering: boolean = false;

    private createEvent: BehaviorSubject<ComplianceRequirementDto> = new BehaviorSubject<ComplianceRequirementDto>(null);
    private deleteEvent: BehaviorSubject<ComplianceRequirementDto> = new BehaviorSubject<ComplianceRequirementDto>(null);

    private _formBuilder = inject(FormBuilder);
    private confirmService = inject(ConfirmService);
    private alertService = inject(AlertService);
    public cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
    private route: ActivatedRoute = inject(ActivatedRoute);
    private commitmentService: CommitmentService = inject(CommitmentService);
    private evidenceOfComplianceFilesService = inject(EvidenceOfComplianceFileService);
    private complianceRequirementService: ComplianceRequirementService = inject(ComplianceRequirementService);
    private phaseService: PhaseService = inject(PhaseService);
    private frequencyService: FrequencyService = inject(FrequencyService);
    private crTypeService: ComplianceRequirementTypeService = inject(ComplianceRequirementTypeService);
    private currentCommitmentService: CurrentCommitmentService = inject(CurrentCommitmentService);
    private scopeService: ScopeService = inject(ScopeService);

    public allMilestones$: Observable<IBeaconSelectOption[]> = this.complianceRequirementService.complianceRequirementsMilestonesGet().pipe(
        map((milestones) => {
            return milestones.map((crType) => {
                return {
                    value: crType,
                    displayValue: crType,
                };
            });
        }),
        share()
    );

    public phases$: Observable<PhaseDto[]> = this.phaseService.phasesGet().pipe(share());
    public crTypes$: Observable<IBeaconSelectOption[]> = this.crTypeService.complianceRequirementTypesGet().pipe(
        map((crTypes) => {
            return crTypes.map((crType) => {
                return {
                    value: crType.ComplianceRequirementTypeID,
                    displayValue: crType.Name,
                };
            });
        }),
        share()
    );

    public frequencies$: Observable<FrequencyDto[]> = this.frequencyService.frequenciesGet().pipe(share());
    public scopes$: Observable<ScopeDto[]> = this.scopeService.scopesGet().pipe(share());
    commitment$: Observable<CommitmentDto> = this.route.paramMap.pipe(
        switchMap((params) => this.commitmentService.commitmentsCommitmentIDGet(params.get(routeParams.commitmentID)))
    );
    existingComplianceRequirements$ = this.route.paramMap.pipe(
        switchMap((params) => this.complianceRequirementService.commitmentsCommitmentIDComplianceRequirementsGet(params.get(routeParams.commitmentID)))
    );

    complianceRequirements$: Observable<ComplianceRequirementDto[]>;

    nameFormGroup = this._formBuilder.group({
        Name: ["", Validators.required],
    });
    phaseFormGroup = this._formBuilder.group({
        Phase: ["", Validators.required],
        Scope: ["", Validators.required],
        Frequency: ["", Validators.required],
    });

    commitmentTextFormGroup = this._formBuilder.group({
        ApplicableCommitmentText: [null, Validators.required],
    });
    complianceRequirementTypeFormGroup = this._formBuilder.group({
        ComplianceRequirementType: ["", Validators.required],
    });

    milestoneFormGroup = this._formBuilder.group({
        Milestone: [""],
    });

    @ViewChild("stepper") stepper: MatStepper;

    ngOnInit(): void {
        this.setObservables();
    }

    editFormSubmitted($event: { currentComplianceReqID: string; currentCommitmentID: string; updatedForm: ComplianceRequirementUpsertDto }) {
        this.complianceRequirementService
            .commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDPut(
                $event.currentCommitmentID,
                $event.currentComplianceReqID,
                $event.updatedForm
            )
            .subscribe((response) => {
                this.isEditingComplianceRequirement = false;

                this.setObservables();

                this.cdr.markForCheck();
            });
    }

    editingComplianceRequirement($event: ComplianceRequirementDto) {
        this.isEditingComplianceRequirement = true;
    }

    canceledEdit($event: boolean) {
        this.isEditingComplianceRequirement = false;
    }

    setObservables() {
        this.deleteEvent.next(null); // resets the combined accumulator since we've requested fresh again
        this.createEvent.next(null); // resets the combined accumulator since we've requested fresh again
        this.complianceRequirements$ = combineLatest([
            this.existingComplianceRequirements$,
            this.createEvent.asObservable().pipe(scan((acc, value) => (value ? [...acc, value] : []), [] as ComplianceRequirementDto[])),
            this.deleteEvent.asObservable().pipe(scan((acc, value) => (value ? [...acc, value] : []), [] as ComplianceRequirementDto[])),
        ]).pipe(
            map(([existing, created, deleted]) => {
                if (created == null && deleted == null) {
                    return existing;
                }

                let value = existing;

                if (created && created.length > 0) {
                    value = [...value, ...created];
                }

                if (deleted && deleted.length > 0) {
                    value = value.filter((x) => !deleted.some((y) => y.ComplianceRequirementID == x.ComplianceRequirementID));
                }

                return value;
            })
        );
    }

    resetCreation() {
        this.stepper.reset();
        this.creatingNewComplianceRequirement = false;
        this.creatingWithPhaseID = null;
    }

    createComplianceRequirement(phase: PhaseDto): void {
        this.creatingNewComplianceRequirement = true;
        if (phase) {
            this.creatingWithPhaseID = phase.PhaseID;

            this.phaseFormGroup.controls.Phase.setValue(phase.PhaseID);
        }
    }

    submitNewComplianceRequirement() {
        var commitmentID = this.route.snapshot.params[routeParams.commitmentID];
        var upsertDto = new ComplianceRequirementUpsertDto({
            Name: this.nameFormGroup.value.Name,
            PhaseID: this.phaseFormGroup.value.Phase,
            FrequencyID: this.phaseFormGroup.value.Frequency,
            ScopeID: this.phaseFormGroup.value.Scope,
            Milestone: this.milestoneFormGroup.value.Milestone,
            ApplicableCommitmentText: this.commitmentTextFormGroup.value.ApplicableCommitmentText,
            ComplianceRequirementTypeID: this.complianceRequirementTypeFormGroup.value.ComplianceRequirementType,
        });
        this.isLoadingSubmit = true;
        this.complianceRequirementService.commitmentsCommitmentIDComplianceRequirementsPost(commitmentID, upsertDto).subscribe({
            next: (response) => {
                this.isLoadingSubmit = false;
                this.createEvent.next(response);
                this.currentCommitmentService.setCurrentCommitment(response.Commitment);
                this.resetCreation();
            },
            error: (error) => {
                this.isLoadingSubmit = false;
                console.error(error);
            },
        });
    }

    deleteComplianceRequirement(compliancerequirement: ComplianceRequirementDto) {
        this.evidenceOfComplianceFilesService
            .evidenceOfComplianceFileCommitmentIDComplianceRequirementIDGet(
                compliancerequirement.Commitment?.CommitmentID,
                compliancerequirement.ComplianceRequirementID
            )
            .subscribe((count) => {
                const confirmationText =
                    count > 0
                        ? `Are you sure you want to delete this Compliance Requirement? <br/> <strong>Deleting this Compliance Requirement will also DELETE ${count} EVIDENCE OF COMPLIANCE FILE(S).</strong> <br/> This action CANNOT BE UNDONE.`
                        : `Are you sure you want to delete this Compliance Requirement? This action cannot be undone.`;

                this.confirmService
                    .confirm({
                        color: "warn",
                        header: `Delete Compliance Requirement`,
                        text: confirmationText,
                    })
                    .subscribe((result) => {
                        if (!result) return;
                        this.complianceRequirementService
                            .commitmentsCommitmentIDComplianceRequirementsComplianceRequirementIDDelete(
                                compliancerequirement.Commitment?.CommitmentID,
                                compliancerequirement.ComplianceRequirementID
                            )
                            .subscribe({
                                next: (result) => {
                                    this.currentCommitmentService.setCurrentCommitment(compliancerequirement.Commitment);
                                    this.alertService.pushAlert(new Alert("The compliance requirement was successfully deleted.", AlertContext.Success), 5000);
                                    this.deleteEvent.next(compliancerequirement);
                                },
                                error: (error) => {
                                    this.alertService.pushAlert(new Alert("The compliance requirement could not be deleted.", AlertContext.Danger), 5000);
                                },
                            });
                    });
            });
    }

    saveSortOrder(complianceRequirementDtos: ComplianceRequirementDto[]) {
        this.complianceRequirementService
            .commitmentsCommitmentIDComplianceRequirementsUpdateDisplayIndexPut(this.route.snapshot.params[routeParams.commitmentID], complianceRequirementDtos)
            .subscribe((response) => {
                this.isReordering = false;
                this.setObservables();
                this.cdr.markForCheck();
            });
    }

    cancelSortOrder() {
        this.isReordering = false;
        this.setObservables();
    }

    editSortOrder() {
        this.isReordering = true;
    }
}
