import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { TrackableService } from "src/app/shared/generated/api/trackable.service";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { DatePipe, NgIf, NgFor, NgClass, NgStyle, AsyncPipe } from "@angular/common";
import { Gallery, ImageItem } from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { FulcrumPhotoService } from "src/app/shared/generated/api/fulcrum-photo.service";
import { MapComponent } from "../../../../shared/components/map/map.component";
import { EsaMaterialFormFieldComponent, EsaMaterialButtonComponent } from "esa-material-form-field";
import { FormsModule } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { MatTabGroup, MatTab, MatTabContent } from "@angular/material/tabs";
import { MatCardContent, MatCard, MatCardHeader, MatCardTitle } from "@angular/material/card";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatButton } from "@angular/material/button";
import { LoadingSpinnerComponent } from "src/app/shared/components/loading-spinner/loading-spinner.component";

@Component({
    selector: "daily-monitoring-report-observation-details",
    templateUrl: "./daily-monitoring-report-observation-details.component.html",
    styleUrls: ["./daily-monitoring-report-observation-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatProgressSpinner,
        MatCardContent,
        MatTabGroup,
        MatTab,
        NgFor,
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatIcon,
        FormsModule,
        EsaMaterialFormFieldComponent,
        MatTabContent,
        NgClass,
        NgStyle,
        MapComponent,
        MatButton,
        MatIcon,
        AsyncPipe,
        LoadingSpinnerComponent,
    ],
    providers: [DatePipe],
})
export class DailyMonitoringReportObservationDetailsComponent implements OnInit, OnChanges {
    @ViewChild("customItemTemplate") customItemTemplate: TemplateRef<any>;

    @Input() observation: any;
    @Input() reportDate: any;

    public observationLogs$: Observable<any[]>;
    public observationLogs: any[];
    public loadedLogs: boolean = false;

    public observationLogPhotos$: Observable<any[]>;
    public observationLogPhotos: any[];
    public selectedPhoto: any;
    public loadedPhotos: boolean = false;

    public selectedFeature;
    public allObservationLogFeatures: any[] = [];
    public filteredObservationLogFeatures: any[];

    constructor(
        private trackablesService: TrackableService,
        private fulcrumPhotoService: FulcrumPhotoService,
        private gallery: Gallery,
        private lightbox: Lightbox,
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.observationLogs$ = this.trackablesService.trackablesTrackableRecordIDObservationEntriesGet(this.observation["_record_id"]).pipe(
            map((obs: any[]) => {
                let logs = obs.filter((log) => {
                    return log["date"] == this.reportDate;
                });

                logs.sort((a, b) => {
                    return a["time"].localeCompare(b["time"]);
                });

                this.observationLogs = logs;
                this.loadedLogs = true;
                this.cdr.markForCheck();
                return logs;
            }),
            tap((logs) => {
                var photoIDs = logs.reduce((acc, l) => acc.concat(l.photos), []);

                this.observationLogPhotos$ = this.fulcrumPhotoService.fulcrumPhotosGet(photoIDs).pipe(
                    tap((results) => {
                        results = results.map((result) => {
                            let dateTimeKey = "date_time";
                            if (!result["photo"]["exif"][dateTimeKey]) {
                                dateTimeKey = "date_time_original";
                            }

                            let hasExifDate = result["photo"]["exif"][dateTimeKey] != null;
                            if (hasExifDate) {
                                let date = result["photo"]["exif"][dateTimeKey];
                                result["photo"].$SortTime = this.datePipe.transform(date, "HH:mm:ss", "UTC");
                            } else {
                                let date = result["photo"]["created_at"];
                                result["photo"].$SortTime = this.datePipe.transform(date, "HH:mm:ss", "PST"); //TODO: This is a temporary fix until we can get the correct timezone from the Tenant/API.
                            }

                            return result;
                        });

                        this.observationLogPhotos = results.sort((a, b) => {
                            let timeA = a["photo"].$SortTime;
                            let timeB = b["photo"].$SortTime;

                            if (timeA === null && timeB === null) {
                                return 0;
                            } else if (timeA === null) {
                                return -1;
                            } else if (timeB === null) {
                                return 1;
                            }

                            return timeA.localeCompare(timeB);
                        });

                        this.processPhotos();
                        this.loadedPhotos = true;
                        this.cdr.markForCheck();
                    }),
                    catchError((error) => {
                        this.observationLogPhotos = [];
                        this.filteredObservationLogFeatures = [];
                        this.loadedPhotos = true;
                        this.cdr.markForCheck();
                        return [];
                    })
                );

                this.cdr.markForCheck();
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.observation && changes.observation.currentValue) {
            this.allObservationLogFeatures = [changes.observation.currentValue];
            this.filteredObservationLogFeatures = [changes.observation.currentValue];
            this.selectedFeature = changes.observation.currentValue;
        }
    }

    private processPhotos() {
        let galleryItems = [];
        this.observationLogPhotos.forEach((photoData) => {
            let photo = photoData["photo"];
            let recordID = photo["record_id"];
            let latitude = photo["exif"]["gps_latitude"];
            let longitude = photo["exif"]["gps_longitude"];

            let observationLog = this.observationLogs.find((ol) => {
                return ol["_record_id"] == recordID;
            });

            let indexOfPhotoID = observationLog["photos"].indexOf(photo["access_key"]);
            if (indexOfPhotoID != -1) {
                let caption = observationLog["photos_captions"][indexOfPhotoID];
                photo.$Caption = caption;
            } else {
                photo.$Caption = null;
            }

            let dateTimeKey = "date_time";
            if (!photo["exif"][dateTimeKey]) {
                dateTimeKey = "date_time_original";
            }

            let hasExifDate = photo["exif"][dateTimeKey] != null;
            let date = photo["exif"][dateTimeKey] ?? photo["created_at"];
            if (photo.$Caption == null) {
                let timezone = hasExifDate ? this.extractTimezone(date) : "PST";
                let formattedDate = this.datePipe.transform(date, "short", timezone);
                let caption = `Photo taken by ${photo["created_by"]} at ${formattedDate}`;
                photo.$Caption = caption;
            }

            galleryItems.push(new ImageItem({ src: photo["original"], thumb: photo["thumbnail"], alt: photo.$Caption }));

            if (latitude && longitude) {
                //Needs to have the same shape as the observation log feature
                let geometryWrapper = {
                    _record_id: photo["access_key"],
                    tooltip: photo.$Caption,
                    _geometry: {
                        type: "Point",
                        coordinates: [longitude, latitude],
                        properties: {
                            _record_id: photo["access_key"], //The record_id on the photo object refers to its parent record, the map needs unique keys which is the photo's access_key. We don't support multiple unique keys per map atm so I have to slot it into _record_id.
                            photo: photo,
                            tooltip: photo.$Caption,
                        },
                    },
                };

                this.allObservationLogFeatures.push(geometryWrapper);
            }
        });

        this.filteredObservationLogFeatures = this.allObservationLogFeatures;

        const galleryRef = this.gallery.ref(this.observation["_record_id"]);
        galleryRef.load(galleryItems);
        galleryRef.state.subscribe((state) => {
            if (state.action == "indexChanged") {
                let image = this.observationLogPhotos[state.currIndex];
                this.imageClicked(image, false);
            }
        });

        galleryRef.setConfig({
            itemTemplate: this.customItemTemplate,
        });
    }

    private extractTimezone(dateTimeStr: string): string {
        let match = dateTimeStr.match(/([+-]\d{2}:\d{2})$/); // Regex to extract timezone offset
        return match ? match[1] : "+0000"; // Default to UTC if no timezone info found
    }

    imageClicked(image, openGallery = true) {
        if (openGallery && image == this.selectedPhoto) {
            this.viewPhotos();
            return;
        }

        this.selectedPhoto = image;

        let clickedFeature = this.allObservationLogFeatures.find((f) => {
            return f?._geometry?.properties?.photo?.access_key == image?.photo?.access_key;
        });

        this.selectedFeature = clickedFeature;
        this.cdr.markForCheck();
    }

    observationLogFeatureClicked(feature) {
        let clickedImage = this.observationLogPhotos.find((olp) => {
            if (!feature.properties.photo) {
                return false;
            }

            return olp.photo.access_key == feature.properties.photo.access_key;
        });

        this.selectedPhoto = clickedImage;
        this.cdr.markForCheck();
    }

    viewPhotos() {
        let indexOfSelectedPhoto = 0;

        if (this.selectedPhoto != null) {
            indexOfSelectedPhoto = this.observationLogPhotos.findIndex((i) => {
                return i.photo.access_key == this.selectedPhoto.photo.access_key;
            });
        }

        indexOfSelectedPhoto = indexOfSelectedPhoto ?? 0;

        this.lightbox.open(indexOfSelectedPhoto, this.observation["_record_id"], {
            panelClass: "fullscreen",
        });
    }
}
