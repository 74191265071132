import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { CustomRichTextService } from "src/app/shared/generated/api/custom-rich-text.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { AlertService } from "../../services/alert.service";
import { Alert } from "../../models/alert";
import { AlertContext } from "../../models/enums/alert-context.enum";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { CustomRichTextDto } from "src/app/shared/generated/model/custom-rich-text-dto";
import { PermissionEnum } from "../../generated/enum/permission-enum";
import { RightsEnum } from "../../models/enums/rights.enum";
import { TinymceEditorComponent } from "../tinymce-editor/tinymce-editor.component";
import { FormsModule } from "@angular/forms";

import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";

@Component({
    selector: "custom-rich-text",
    templateUrl: "./custom-rich-text.component.html",
    styleUrls: ["./custom-rich-text.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MatProgressSpinner, MatButton, MatIcon, FormsModule, TinymceEditorComponent, LoadingSpinnerComponent],
})
export class CustomRichTextComponent implements OnInit {
    @ViewChild("tinyMceEditor") tinyMceEditor: TinymceEditorComponent;
    @Input() customRichTextTypeID: number;
    public customRichTextContent: SafeHtml;
    public isLoading = true;
    public isEditing: boolean = false;
    public isEmptyContent: boolean = false;

    public editedContent: string;

    currentUser: UserDto;

    constructor(
        private customRichTextService: CustomRichTextService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });

        this.customRichTextService.customRichTextCustomRichTextTypeIDGet(this.customRichTextTypeID).subscribe((x) => {
            this.customRichTextContent = this.sanitizer.bypassSecurityTrustHtml(x.CustomRichTextContent);
            this.isEmptyContent = x.IsEmptyContent;
            this.editedContent = x.CustomRichTextContent;
            this.isLoading = false;
            this.cdr.markForCheck();
        });
    }

    public showEditButton(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.CustomRichTextRights, RightsEnum.Update);
    }

    public enterEdit(): void {
        this.isEditing = true;
        this.cdr.markForCheck();
    }

    public cancelEdit(): void {
        this.isEditing = false;
    }

    public saveEdit(): void {
        this.isEditing = false;
        this.isLoading = true;
        const updateDto = new CustomRichTextDto({ CustomRichTextContent: this.editedContent });
        this.customRichTextService.customRichTextCustomRichTextTypeIDPut(this.customRichTextTypeID, updateDto).subscribe(
            (x) => {
                this.customRichTextContent = this.sanitizer.bypassSecurityTrustHtml(x.CustomRichTextContent);
                this.editedContent = x.CustomRichTextContent;
                this.isLoading = false;
                this.cdr.markForCheck();
            },
            (error) => {
                this.isLoading = false;
                this.alertService.pushAlert(new Alert("There was an error updating the rich text content", AlertContext.Danger, true));
            }
        );
    }
}
