import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AlertService } from "../../services/alert.service";
import { TenantService } from "../../services/tenant/tenant-service.service";
import { filter, map } from "rxjs";

export const tenantModuleGuard: CanActivateFn = (route, state) => {
    let tenantService = inject(TenantService);
    let router = inject(Router);
    let alertService = inject(AlertService);
    const moduleID = route.data?.moduleID;

    /**
     * If the route has a guard but doesn't have a data property for moduleID, then it's a mistake in the route configuration.
     */
    if (!moduleID) {
        alertService.pushNotFoundUnauthorizedAlert();
        console.error("Module ID not provided in route data");
        return router.createUrlTree(["/"]);
    }

    /**
     * Otherwise we check if the current tenant has the moduleID in its list of allowed modules.
     */
    return tenantService.currentTenant$.pipe(
        filter((currentTenant) => currentTenant != null),
        map((currentTenant) => {
            let tenantHasModule = currentTenant.TenantModules.map((m) => m.Module.ModuleID).includes(moduleID);
            return tenantHasModule ? true : router.createUrlTree(["/not-found"]);
        })
    );
};
