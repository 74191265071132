import { Component, ChangeDetectionStrategy, Input, OnInit, EventEmitter, Output, ViewChild, QueryList, ViewChildren, ElementRef } from "@angular/core";
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelContent } from "@angular/material/expansion";
import { DailyMonitoringReportDetailsEventingServiceService as DailyMonitoringReportDetailsEventingService } from "../daily-monitoring-report-details-eventing-service/daily-monitoring-report-details-eventing-service.service";
import { DailyMonitoringReportObservationDetailsComponent } from "./daily-monitoring-report-observation-details/daily-monitoring-report-observation-details.component";
import { MatTooltip } from "@angular/material/tooltip";
import { MatCardTitle, MatCardSubtitle } from "@angular/material/card";
import { MatIcon } from "@angular/material/icon";
import { NgIf, NgFor, DatePipe } from "@angular/common";

@Component({
    selector: "daily-monitoring-report-observations",
    templateUrl: "./daily-monitoring-report-observations.component.html",
    styleUrls: ["./daily-monitoring-report-observations.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatAccordion,
        NgFor,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatIcon,
        MatCardTitle,
        MatTooltip,
        MatCardSubtitle,
        MatExpansionPanelContent,
        DailyMonitoringReportObservationDetailsComponent,
        DatePipe,
    ],
})
export class DailyMonitoringReportObservationsComponent implements OnInit {
    @ViewChild(MatAccordion) observationAccordion: MatAccordion;

    //Need to get both the native element and the MatExpansionPanel to open the correct panel. For some reason the MatExpansionPanel alone does not have the ID on it required to select the correct panel.
    @ViewChildren("observationExpanionPanel", { read: ElementRef }) expPanelElems: QueryList<ElementRef>;
    @ViewChildren("observationExpanionPanel") observationExpansionPanels: QueryList<MatExpansionPanel>;

    @Output() observationAccordionToggled = new EventEmitter<any>();

    @Input() dailyMonitoringReport: any;
    @Input() observations: any[];
    @Input() selectedObservation: any;

    private lastClosedPanel: any;
    constructor(private dailyMonitoringReportDetailsEventingServivce: DailyMonitoringReportDetailsEventingService) {}

    ngOnInit(): void {
        this.dailyMonitoringReportDetailsEventingServivce.mapClicked$.subscribe((data) => {
            if (this.observationAccordion) {
                this.observationAccordion.closeAll();
                this.handleObservationClick(data);
            }
        });
    }

    handleObservationClick(observation) {
        if (this.observationAccordion && this.observationAccordion["_record_id"] == observation["_record_id"]) {
            return;
        }

        if (this.lastClosedPanel && this.lastClosedPanel["_record_id"] == observation["_record_id"]) {
            this.lastClosedPanel = null;
            return;
        }

        let panelElementIndex = this.expPanelElems.toArray().findIndex((panel) => {
            return panel.nativeElement.id === observation["_record_id"];
        });

        let panel = this.observationExpansionPanels.toArray()[panelElementIndex];
        if (panel && panel.expanded == false) {
            panel.open();
        }
    }

    panelOpened(observation) {
        this.observationAccordionToggled.emit(observation);
    }

    panelClosed(observation) {
        this.lastClosedPanel = observation;
        this.observationAccordionToggled.emit(observation);
    }
}
