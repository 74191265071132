<main class="main">
    <div class="page-header">
        <div class="actions-bar flex-between">
            <h1 class="page-title">Source Documents</h1>
        </div>
        <div class="g-col-6">
            <p>View all source documents for {{ currentTenant?.Name }} projects.</p>
        </div>
    </div>

    <div class="table-responsive">
        <beacon-grid-actions [grid]="sourceDocumentsGrid" downloadFileName="source-documents">
            <button mat-flat-button *ngIf="canCreateSourceDocument" color="primary" (click)="createSourceDocument()">
                <mat-icon>add</mat-icon>Source Document
            </button>
        </beacon-grid-actions>
        <ag-grid-angular
            #sourceDocumentsGrid
            style="width: 100%; height: 100%"
            class="ag-theme-material"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [suppressMenuHide]="true"
            (gridReady)="onSourceDocumentsGridReady($event)">
        </ag-grid-angular>
    </div>
</main>
