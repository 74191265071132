import { Component, inject, Input } from "@angular/core";
import { ComponentSummaryDto } from "../../generated/model/component-summary-dto";
import { MatIcon } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { PillComponent } from "../pill/pill.component";
import { DatePipe } from "@angular/common";
import { PillComponentStatusComponent } from "../pill-component-status/pill-component-status.component";
import { MatTooltip } from "@angular/material/tooltip";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";
import { ComponentService } from "../../generated/api/component.service";
import { FileService } from "src/app/services/file/file.service";
import { MatButtonModule } from "@angular/material/button";
import { LoadingButtonDirective } from "../../directives/loading-button/loading-button.directive";
import { SourceService } from "../../generated/api/source.service";
import { SourceSummaryDto } from "../../generated/model/source-summary-dto";

@Component({
    selector: "source-summary",
    standalone: true,
    imports: [
        MatIcon,
        RouterModule,
        PillComponent,
        DatePipe,
        PillComponentStatusComponent,
        MatTooltip,
        BeaconEntityIconComponent,
        MatButtonModule,
        LoadingButtonDirective,
    ],
    templateUrl: "./source-summary.component.html",
    styleUrl: "./source-summary.component.scss",
})
export class SourceSummaryComponent {
    public isLoading: boolean = false;
    @Input() source: SourceSummaryDto;
}
