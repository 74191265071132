import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlContainer, NgForm, FormsModule } from "@angular/forms";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { NgIf } from "@angular/common";

@Component({
    selector: "tinymce-editor",
    templateUrl: "./tinymce-editor.component.html",
    styleUrls: ["./tinymce-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [NgIf, EditorComponent, FormsModule],
})
export class TinymceEditorComponent implements OnInit {
    @ViewChild("tinyMceEditor") tinyMceEditor: EditorComponent;
    public tinyMceConfig: object;

    @Input() isRequired: boolean;
    @Input() content: any;
    @Input() name: string;
    @Output() contentChange = new EventEmitter<any>();

    modelEvents = "change input undo redo";

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        let config = {
            plugins: "lists advlist link image table code help wordcount autoresize media",
            file_picker_types: "image",
            images_file_types: "jpg,svg,webp,gif",
            image_title: true,
            base_url: "/tinymce", // Root for resources
            suffix: ".min", // Suffix to use when loading resources
            selector: "textarea", // change this value according to your HTML
            autoresize_bottom_margin: 10,
            promotion: false,
            toolbar:
                "undo redo | styleselect | bold italic | bullist numlist | alignleft aligncenter alignright alignjustify | outdent indent | blockquote table | image media link unlink | styles | code",
            iframe_template_callback: (data) => `<iframe title="${data.title}" width="${data.width}" height="${data.height}" src="${data.source}"></iframe>`,
            audio_template_callback: (data) =>
                "<audio controls>\n" +
                `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ""} />\n` +
                (data.altsource ? `<source src="${data.altsource}"${data.altsourcemime ? ` type="${data.altsourcemime}"` : ""} />\n` : "") +
                "</audio>",
            video_template_callback: (data) =>
                `<video width="${data.width}" height="${data.height}"${data.poster ? ` poster="${data.poster}"` : ""} controls="controls">\n` +
                `<source src="${data.source}"${data.sourcemime ? ` type="${data.sourcemime}"` : ""} />\n` +
                (data.altsource ? `<source src="${data.altsource}"${data.altsourcemime ? ` type="${data.altsourcemime}"` : ""} />\n` : "") +
                "</video>",
            file_picker_callback: (cb, value, meta) => {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");
                input.addEventListener("change", (e) => {
                    const file = (e.target as any).files[0];

                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        // Note: Now we need to register the blob in TinyMCEs image blob
                        // registry. In the next release this part hopefully won't be
                        // necessary, as we are looking to handle it internally.

                        const id = "blobid" + new Date().getTime();
                        const blobCache = this.tinyMceEditor.editor.editorUpload.blobCache;
                        const base64 = (reader.result as any).split(",")[1];
                        const blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);

                        /* call the callback and populate the Title field with the file name */
                        cb(blobInfo.blobUri(), { title: file.name });
                    });
                    reader.readAsDataURL(file);
                });
                input.click();
            },
        };
        let overrideConfig: object;
        this.tinyMceConfig = this.OverrideConfig(config, (overrideConfig = null));
        this.cdr.markForCheck();
    }

    private OverrideConfig(config: object, overrideConfig: object): object {
        if (!overrideConfig) return config;
        Object.keys(overrideConfig).forEach((configKey) => {
            config[configKey] = overrideConfig[configKey];
        });
        return config;
    }

    emitChange($event: any) {
        this.contentChange.emit(this.content);
    }
}
