<h1 mat-dialog-title>Create New Relationship</h1>
<div mat-dialog-content>
    <div *ngIf="relationshipTypes && commitmentsWithDisplayName">
        <esa-material-ff
            name="Relationship Type"
            [editMode]="true"
            [required]="true"
            type="dropdown"
            [options]="relationshipTypes"
            optionLabel="RelationshipText"
            optionValue="RelationshipTypeID"
            [(ngModel)]="commitmentRelationshipCreateDto.RelationshipTypeID"
            [customLabel]="relationshipTypeCustomLabel">
        </esa-material-ff>
        <ng-template #relationshipTypeCustomLabel>
            <custom-form-label label="Relationship Type"></custom-form-label>
        </ng-template>

        <esa-material-ff
            name="Commitment"
            [editMode]="true"
            [required]="true"
            type="dropdown"
            [options]="commitmentsWithDisplayName"
            optionLabel="DisplayName"
            optionValue="CommitmentID"
            [(ngModel)]="commitmentRelationshipCreateDto.OtherCommitmentID"
            [customLabel]="commitmentLabel">
        </esa-material-ff>
        <ng-template #commitmentLabel>
            <custom-form-label label="Commitment"></custom-form-label>
        </ng-template>
    </div>
</div>

<div mat-dialog-actions>
    <button
        mat-flat-button
        color="primary"
        [disabled]="!commitmentRelationshipCreateDto.RelationshipTypeID || !commitmentRelationshipCreateDto.OtherCommitmentID"
        [mat-dialog-close]="commitmentRelationshipCreateDto"
        style="margin-right: 1rem">
        <mat-icon>add</mat-icon> Create
    </button>
    <button mat-button type="button" color="primary" mat-dialog-close><mat-icon>cancel</mat-icon> Cancel</button>
</div>
