<div class="custom-file-upload">
    <div
        id="drop-zone"
        [class.over]="hoveringWithFiles"
        (drop)="dropHandler($event)"
        (dragover)="dragEnterHandler($event)"
        (dragenter)="dragEnterHandler($event)"
        (dragleave)="dragLeaveHandler($event)">
        <div class="drop-text">
            <mat-icon [inline]="true">cloud_upload</mat-icon>
            <span>Drag & Drop to Upload Files</span>
            <span>OR</span>
            <button mat-stroked-button color="primary" type="button" class="" (click)="onClickFileUpload($event)">Browse Files</button>
        </div>
        @if (uploadFileAcceptsArray().length > 0) {
        <em class="supports">
            Supports @for (extension of uploadFileAcceptsArray(); track extension) {
            <strong>{{ extension }} </strong>
            }
        </em>
        }
    </div>

    <input type="file" [multiple]="multiple" #fileUploadField (change)="updateFiles(fileUploadField.files)" [accept]="uploadFileAccepts()" />
</div>

@if(uploadingFileByteSize / 1000000 > 50) {
<alert-box [alertContext]="AlertContext.Danger"
    ><strong>Warning:</strong> Your total upload is larger than 50 MB. Please be patient when you submit this form.</alert-box
>
}
