import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatTooltip } from "@angular/material/tooltip";
import { EsaMaterialFormFieldComponent } from "esa-material-form-field";
import { map, Observable, shareReplay } from "rxjs";
import { EvidenceOfComplianceService } from "src/app/shared/generated/api/evidence-of-compliance.service";
import { EvidenceOfComplianceDto } from "src/app/shared/generated/model/evidence-of-compliance-dto";

@Component({
    selector: "evidence-of-compliance-select-existing-dialog",
    standalone: true,
    imports: [EsaMaterialFormFieldComponent, FormsModule, CommonModule, MatTooltip, MatButtonModule, MatDialogModule],
    templateUrl: "./evidence-of-compliance-select-existing-dialog.component.html",
    styleUrl: "./evidence-of-compliance-select-existing-dialog.component.scss",
})
export class EvidenceOfComplianceSelectExistingDialogComponent {
    public selectedEvidenceOfComplianceIDs: [] = [];
    constructor(
        public dialogRef: MatDialogRef<EvidenceOfComplianceSelectExistingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { componentID: string; availableEvidencesOnComponent: EvidenceOfComplianceDto[] }
    ) {}

    onSave(): void {
        this.dialogRef.close(this.selectedEvidenceOfComplianceIDs);
    }
}
