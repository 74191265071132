import { Component, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { ModuleEnum } from "../../generated/model/module-enum";
import { BeaconEntityIconComponent } from "../beacon-entity-icon/beacon-entity-icon.component";

@Component({
    selector: "module-icon",
    standalone: true,
    imports: [MatIcon, BeaconEntityIconComponent],
    templateUrl: "./module-icon.component.html",
    styleUrl: "./module-icon.component.scss",
})
export class ModuleIconComponent {
    ModuleEnum = ModuleEnum;
    @Input() moduleID: number;
}
