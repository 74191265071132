<div class="details-layout">
    <section class="sidebar">
        <layout-sidebar-nav [sideNavGroups]="projectSideNav" [sidebarHeader]="sidebarHeader"></layout-sidebar-nav>

        <ng-template #sidebarHeader>
            @if (project$ | async; as project) {
                <h2>{{ project.Name }}</h2>
                <pill>{{ project.StartDate | date }} - {{ project.EndDate | date }}</pill>
            }
        </ng-template>
    </section>
    <section class="outlet">
        <router-outlet />
    </section>
</div>
