import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SeasonalityTriggerDto } from "../../generated/model/seasonality-trigger-dto";
import { FileDropUploadComponent } from "../file-drop-upload/file-drop-upload.component";
import { CommonModule, DatePipe } from "@angular/common";
import { ConfirmService } from "src/app/services/confirm.service";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { SimpleFileDisplayComponent } from "../simple-file-display/simple-file-display.component";
import { KvPairComponent } from "../kv-pair/kv-pair.component";
import { MatTooltip } from "@angular/material/tooltip";
import { LoadingSpinnerComponent } from "../loading-spinner/loading-spinner.component";
import { SeasonalityTriggerService } from "../../generated/api/seasonality-trigger.service";
import { Observable } from "rxjs";
import {
    SeasonalityTriggerUpsertDialogComponent,
    ISeasonalityTriggerUpsertDialogComponentData,
    ISeasonalityTriggerUpsertDialogComponentResponse,
} from "../dialogs/seasonality-trigger-upsert-dialog/seasonality-trigger-upsert-dialog.component";
import { SeasonalityTriggerDatePipe } from "../../pipes/seasonality-trigger-date/seasonality-trigger-date.pipe";

@Component({
    selector: "seasonality-trigger",
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        FileDropUploadComponent,
        SimpleFileDisplayComponent,
        KvPairComponent,
        MatTooltip,
        LoadingSpinnerComponent,
        SeasonalityTriggerDatePipe,
    ],
    providers: [DatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    templateUrl: "./seasonality-trigger.component.html",
    styleUrls: ["./seasonality-trigger.component.scss"],
})
export class SeasonalityTriggerComponent implements OnInit {
    isLoading = false;
    isDialogVisible = false;
    public _commitmentID: string;
    commitmentExistingSeasonalityTriggers$: Observable<SeasonalityTriggerDto[]>;

    @Input() set commitmentID(value: string) {
        if (value) {
            this._commitmentID = value;
        }
    }

    constructor(
        private fb: FormBuilder,
        private seasonalityTriggerService: SeasonalityTriggerService,
        private cdr: ChangeDetectorRef,
        private confirmService: ConfirmService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.fetchExistingSeasonalityTriggers();
    }

    fetchExistingSeasonalityTriggers(): void {
        this.commitmentExistingSeasonalityTriggers$ = this.seasonalityTriggerService.commitmentsCommitmentIDSeasonalityTriggersGet(this._commitmentID);
    }

    addSeasonalityTrigger() {
        const dialogRef = this.dialog.open<
            SeasonalityTriggerUpsertDialogComponent,
            ISeasonalityTriggerUpsertDialogComponentData,
            ISeasonalityTriggerUpsertDialogComponentResponse
        >(SeasonalityTriggerUpsertDialogComponent, {
            width: "700px",
            data: {
                SeasonalityTrigger: new SeasonalityTriggerDto({ SeasonalityTriggerID: "" }),
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.saveSeasonalityTrigger(result);
            }
        });
    }

    editSeasonalityTrigger(seasonalityTrigger: SeasonalityTriggerDto) {
        const dialogRef = this.dialog.open<
            SeasonalityTriggerUpsertDialogComponent,
            ISeasonalityTriggerUpsertDialogComponentData,
            ISeasonalityTriggerUpsertDialogComponentResponse
        >(SeasonalityTriggerUpsertDialogComponent, {
            width: "700px",
            data: {
                CommitmentID: this._commitmentID,
                SeasonalityTrigger: seasonalityTrigger,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.saveSeasonalityTrigger(result);
            }
        });
    }

    saveSeasonalityTrigger(seasonalityTriggerData: ISeasonalityTriggerUpsertDialogComponentResponse) {
        this.isLoading = true;
        this.cdr.markForCheck();

        this.seasonalityTriggerService
            .commitmentsCommitmentIDSeasonalityTriggersPut(
                this._commitmentID,
                seasonalityTriggerData.Title,
                seasonalityTriggerData.StartDay,
                seasonalityTriggerData.StartMonth,
                seasonalityTriggerData.EndDay,
                seasonalityTriggerData.EndMonth,
                seasonalityTriggerData.SeasonalityTriggerID,
                seasonalityTriggerData.Description
            )
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.fetchExistingSeasonalityTriggers();
                    this.cdr.markForCheck();
                },
                error: () => {
                    this.isLoading = false;
                    this.cdr.markForCheck();
                },
            });
    }

    deleteSeasonalityTrigger(seasonalityTrigger: SeasonalityTriggerDto) {
        this.confirmService
            .confirm({ color: "warn", header: `Delete Seasonality Trigger`, text: `Are you sure you want to delete this seasonality trigger?` })
            .subscribe((result) => {
                if (!result) {
                    return;
                }
                this.seasonalityTriggerService
                    .commitmentsCommitmentIDSeasonalityTriggersSeasonalityTriggerIDDelete(this._commitmentID, seasonalityTrigger.SeasonalityTriggerID)
                    .subscribe(() => {
                        this.fetchExistingSeasonalityTriggers();
                        this.cdr.markForCheck();
                    });
            });
    }

    closeDialog() {
        this.isDialogVisible = false;
    }
}
