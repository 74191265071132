<div class="list-view">
    <div class="g-col-12">
        <div class="table-responsive" style="height: 800px">
            <beacon-grid-actions
                [grid]="evidenceOfComplianceGrid"
                downloadFileName="project-related-evidence-of-compliance"
                [downloadColIDsToExclude]="['0']"></beacon-grid-actions>
            <ag-grid-angular
                #evidenceOfComplianceGrid
                style="width: 100%; height: 100%"
                class="ag-theme-material"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [suppressMenuHide]="true"
                (gridReady)="onEvidencesOfComplianceGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>
