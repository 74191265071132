import { CommonModule } from "@angular/common";
import { Component, computed, EventEmitter, inject, input, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AlertBoxComponent } from "../alert-box/alert-box.component";
import { AlertContext } from "../../models/enums/alert-context.enum";
import { AlertService } from "../../services/alert.service";
import { Alert } from "../../models/alert";

@Component({
    selector: "file-drop-upload",
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatButton, FontAwesomeModule, MatIcon, AlertBoxComponent],
    templateUrl: "./file-drop-upload.component.html",
    styleUrl: "./file-drop-upload.component.scss",
})
export class FileDropUploadComponent {
    alertService = inject(AlertService);
    AlertContext = AlertContext;
    @Input() multiple: boolean = false;
    @ViewChild("fileUploadField") fileUploadField: any;
    @Output() filesChanged = new EventEmitter<File[]>();
    public uploadingFileByteSize: number;

    /**
     * comma separated list of file types: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
     */
    uploadFileAccepts = input<string>();
    uploadFileAcceptsArray = computed(
        () =>
            this.uploadFileAccepts()
                ?.split(",")
                .map((x) => x.trim()) ?? []
    );
    public hoveringWithFiles: boolean = false;
    public value: File[];

    constructor() {}

    updateFiles(event: any): void {
        this.value = Array.from(event);

        if (this.value.length > 0) {
            this.uploadingFileByteSize = this.value.map((x) => x.size).reduce((a, b) => a + b);
        } else {
            this.uploadingFileByteSize = 0;
        }

        this.filesChanged.emit(this.value);
    }

    onClickFileUpload(event: any): void {
        const fileUploadInput = this.fileUploadField.nativeElement;
        fileUploadInput.click();
    }

    dragOverHandler(event: any) {
        event.preventDefault();
    }

    dropHandler(event: any) {
        event.preventDefault();
        this.hoveringWithFiles = false;
        let files = [];
        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            [...event.dataTransfer.items].forEach((item, i) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    if (file.size === 0) {
                        this.alertService.pushAlert(
                            new Alert(
                                `The file "${file.name}" is empty and cannot be uploaded. This can happen if you are uploading directly from a shared network drive. Please use the picker to select files to upload instead.`,
                                AlertContext.Danger
                            )
                        );
                        return;
                    }
                    files.push(file);
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            [...event.dataTransfer.files].forEach((file, i) => {
                if (file.size === 0) {
                    this.alertService.pushAlert(
                        new Alert(
                            `The file "${file.name}" is empty and cannot be uploaded. This can happen if you are uploading directly from a shared network drive. Please use the picker to select files to upload instead.`,
                            AlertContext.Danger
                        )
                    );
                    return;
                }
                files.push(file);
            });
        }
        if (files.length > 0) {
            this.updateFiles(files);
        }
    }

    dragEnterHandler(event: DragEvent) {
        event.preventDefault();
        this.hoveringWithFiles = true;
    }

    dragLeaveHandler($event: DragEvent) {
        $event.preventDefault();
        this.hoveringWithFiles = false;
    }
}
