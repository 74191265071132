<h1 mat-dialog-title>Version Commitment</h1>
<mat-dialog-content mat-dialog-content>
    <form class="form grid-12" (ngSubmit)="version()" #versionCommitmentForm="ngForm">
        <div class="g-col-12">
            <esa-material-ff
                name="OriginatorOfChange"
                [editMode]="true"
                [required]="true"
                type="text"
                placeholder="Originator of Change"
                [(ngModel)]="originatorOfChange"
                [customLabel]="titleCustomLabel">
            </esa-material-ff>
            <ng-template #titleCustomLabel>
                <custom-form-label label="Originator of Change" fieldDefinitionType="OriginatorOfChange" [popperOptions]="popperOptions"></custom-form-label>
            </ng-template>

            <div class="kv-pair">
                <custom-form-label label="Summary of Change" fieldDefinitionType="SummaryOfChange" [popperOptions]="popperOptions"></custom-form-label>
                <div class="value">
                    <div class="grid-12" style="margin-bottom: 1rem">
                        <div class="g-col-12">
                            <tinymce-editor #tinyMceEditor name="MeasureText" [(content)]="summaryOfChange" [isRequired]="true"></tinymce-editor>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="kv-pair">
                <custom-form-label label="Files"></custom-form-label>
                <div class="value">
                    <button mat-flat-button color="primary" type="button" (click)="fileInput.click()"><mat-icon>file_open</mat-icon> Select Files</button>
                    <input type="file" multiple hidden (change)="onFilesSelected(fileInput.files)" #fileInput />
                    <div *ngIf="filesToUpload && filesToUpload.length > 0" style="margin-top: 1rem">
                        <ul>
                            <li *ngFor="let file of filesToUpload; let i = index">
                                {{ file.name }}
                                <button mat-icon-button matTooltip="Remove File" type="button" color="primary" [disabled]="isLoading" (click)="spliceFiles(i)">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <kv-pair key="Files" [value]="fileDropper">
                <ng-template #fileDropper>
                    <file-drop-upload uploadFileAccepts=".pdf,.csv,.xlsx" [multiple]="true" (filesChanged)="onFileUpload($event)"></file-drop-upload>
                    <ul class="file-max-height">
                        <li *ngFor="let file of filesToUpload">
                            <simple-file-display
                                [isNotYetUploaded]="true"
                                [fileName]="file.name"
                                [displayDelete]="true"
                                (deleteFile)="deleteNewFile(file)"></simple-file-display>
                        </li>
                    </ul>
                </ng-template>
            </kv-pair>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="cancel()" style="margin-right: 1rem"><mat-icon>cancel</mat-icon> Cancel</button>
    <button mat-flat-button color="primary" (click)="version()" [disabled]="!originatorOfChange || !summaryOfChange || isLoading" label="Version">
        <mat-icon>difference</mat-icon> Version
    </button>
</mat-dialog-actions>
