import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/services/authentication.service";
import { RoleService } from "src/app/shared/generated/api/role.service";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { RoleDto } from "src/app/shared/generated/model/role-dto";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { GridCommonService } from "src/app/shared/services/grid-common.service";
import { EsaMaterialButtonComponent } from "esa-material-form-field";
import { NgIf } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { GridActionsComponent } from "src/app/shared/components/ag-grid/grid-actions/grid-actions.component";

@Component({
    selector: "role-list",
    templateUrl: "./role-list.component.html",
    styleUrls: ["./role-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AgGridAngular, MatIcon, MatButton, MatIconButton, GridActionsComponent],
})
export class RoleListComponent implements OnInit, OnDestroy {
    @ViewChild("roleGrid") roleGrid: AgGridAngular;

    public currentUser: UserDto;
    public rowData = [];
    public columnDefs: ColDef[];
    public columnDefsUnassigned: ColDef[];

    public roles: RoleDto[];

    userSubscription: Subscription;
    getRolesSubscription: Subscription;

    constructor(
        private roleService: RoleService,
        private authenticationService: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private gridCommonService: GridCommonService
    ) {
        this.columnDefs = [
            {
                headerName: "Name",
                valueGetter: function (params: any) {
                    return { LinkValue: params.data.RoleID, LinkDisplay: params.data.RoleName };
                },
                cellRendererSelector: () => this.gridCommonService.renderLink("/manage/roles/"),
                filterValueGetter: function (params: any) {
                    return params.node.rowPinned ? null : params.data.FullName;
                },
                comparator: function (id1: any, id2: any) {
                    let link1 = id1.LinkDisplay;
                    let link2 = id2.LinkDisplay;
                    if (link1 < link2) {
                        return -1;
                    }
                    if (link1 > link2) {
                        return 1;
                    }
                    return 0;
                },
                sortable: true,
                filter: true,
                width: 100,
            },
            { headerName: "Description", field: "RoleDescription", sortable: true, filter: true },
            { headerName: "Is System Role", field: "IsSystemRole", sortable: true, filter: true },
        ];
    }

    ngOnInit(): void {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.cdr.detach();
        this.userSubscription?.unsubscribe();
        this.getRolesSubscription?.unsubscribe();
    }

    addRole() {
        this.router.navigateByUrl("/manage/roles/create");
    }

    onRoleGridReady(gridEvent: any) {
        this.roleGrid?.api.showLoadingOverlay();

        this.getRolesSubscription = this.roleService.rolesGet().subscribe((roles) => {
            this.rowData = roles;
            this.roleGrid.api.sizeColumnsToFit();
            this.cdr.markForCheck();
            this.roleGrid.api.hideOverlay();
        });
    }

    get canCreateRole() {
        return this.currentUser && this.authenticationService.hasPermission(this.currentUser, PermissionEnum.RoleRights, RightsEnum.Create);
    }
}
