<ng-container *ngIf="commitment$ | async as commitment">
    <h2 mat-dialog-title>Commitment Summary</h2>
    <mat-dialog-content>
        <div class="form">
            <esa-material-ff name="Title" [editMode]="false" type="text" [(ngModel)]="commitment.Title" [customLabel]="titleLabel"> </esa-material-ff>
            <ng-template #titleLabel>
                <custom-form-label label="Commitment Title" fieldDefinitionType="Title"></custom-form-label>
            </ng-template>

            <esa-material-ff
                name="ID"
                [editMode]="false"
                type="text"
                [(ngModel)]="commitment.ClientCommitmentID"
                [customLabel]="idLabel"
                [customDisplay]="idDisplay">
            </esa-material-ff>
            <ng-template #idLabel>
                <custom-form-label label="Commitment ID" fieldDefinitionType="CommitmentID"></custom-form-label>
            </ng-template>
            <ng-template #idDisplay>
                <a target="_blank" [routerLink]="['/commitments/', commitment.CommitmentID]">
                    <p>{{ commitment.ClientCommitmentID }}</p>
                </a>
            </ng-template>

            <esa-material-ff
                name="CommitmentTypeName"
                [editMode]="false"
                type="text"
                [(ngModel)]="commitment.CommitmentType.Name"
                [customLabel]="commitmentTypeDisplay">
            </esa-material-ff>
            <ng-template #commitmentTypeDisplay>
                <custom-form-label label="Commitment Type" fieldDefinitionType="Name"></custom-form-label>
            </ng-template>

            <esa-material-ff
                name="Source"
                [editMode]="false"
                type="text"
                [(ngModel)]="commitment.Source.Name"
                [customLabel]="sourceLabel"
                [customDisplay]="sourceDisplay">
            </esa-material-ff>
            <ng-template #sourceLabel>
                <custom-form-label label="Source" fieldDefinitionType="Source"></custom-form-label>
            </ng-template>
            <ng-template #sourceDisplay>
                <a target="_blank" [routerLink]="['/source-documents/', commitment.Source.SourceID]">
                    <p>{{ commitment.Source.Name }}</p>
                </a>
            </ng-template>

            <esa-material-ff
                name="ResourceCategory"
                [editMode]="false"
                [(ngModel)]="commitment.ResourceCategory.Name"
                [customLabel]="ResourceCategoryCustomLabel">
            </esa-material-ff>
            <ng-template #ResourceCategoryCustomLabel>
                <custom-form-label label="Resource Category" fieldDefinitionType="ResourceCategory"></custom-form-label>
            </ng-template>

            <div class="kv-pair form-spacing">
                <custom-form-label label="Full Commitment Text" fieldDefinitionType="FullCommitmentText"></custom-form-label>
                <div class="value-display" [innerHTML]="commitment.FullCommitmentText | bypassHtml"></div>
            </div>
        </div>
    </mat-dialog-content>
</ng-container>
<mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
