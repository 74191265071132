/**
 * Beacon.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EvidenceOfComplianceChecklistItemDto } from '././evidence-of-compliance-checklist-item-dto';
import { EvidenceOfComplianceFileDto } from '././evidence-of-compliance-file-dto';
import { UserDto } from '././user-dto';

export class EvidenceOfComplianceDto { 
    EvidenceOfComplianceID?: string;
    Notes?: string;
    Title?: string;
    EvidenceOfComplianceFiles?: Array<EvidenceOfComplianceFileDto>;
    EvidenceOfComplianceChecklistItems?: Array<EvidenceOfComplianceChecklistItemDto>;
    CreateUser?: UserDto;
    UpdateUser?: UserDto;
    CreateDate?: string;
    UpdateDate?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
