import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { MatButton } from "@angular/material/button";
import { LinkRendererComponent } from "src/app/shared/components/ag-grid/link-renderer/link-renderer.component";
import { CustomRichTextTypeEnum } from "src/app/shared/generated/enum/custom-rich-text-type-enum";
import { CommitmentLibraryService } from "src/app/shared/generated/api/commitment-library.service";
import { CustomRichTextComponent } from "src/app/shared/components/custom-rich-text/custom-rich-text.component";
import { CommonModule } from "@angular/common";
import { BeaconEntityIconComponent } from "src/app/shared/components/beacon-entity-icon/beacon-entity-icon.component";
import { LoadingSpinnerComponent } from "../../shared/components/loading-spinner/loading-spinner.component";
import { Observable, share, Subscription } from "rxjs";
import { ProjectSummaryComponent } from "../../shared/components/project-summary/project-summary.component";
import { CommitmentLibrarySummaryDto } from "src/app/shared/generated/model/commitment-library-summary-dto";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "src/app/services/authentication.service";
import {
    ProjectUpsertDialogComponent,
    IProjectUpsertDialogComponentData,
    IProjectUpsertDialogComponentResponse,
} from "src/app/shared/components/dialogs/project-upsert-dialog/project-upsert-dialog.component";
import { PermissionEnum } from "src/app/shared/generated/enum/permission-enum";
import { ProjectDto } from "src/app/shared/generated/model/project-dto";
import { Alert } from "src/app/shared/models/alert";
import { AlertContext } from "src/app/shared/models/enums/alert-context.enum";
import { RightsEnum } from "src/app/shared/models/enums/rights.enum";
import { AlertService } from "src/app/shared/services/alert.service";
import { UserDto } from "src/app/shared/generated/model/user-dto";
import { MatIcon } from "@angular/material/icon";
import { EmptyListComponent } from "../../shared/components/empty-list/empty-list.component";

@Component({
    selector: "commitment-library-home",
    templateUrl: "./commitment-library-home.component.html",
    styleUrls: ["./commitment-library-home.component.scss"],
    standalone: true,
    imports: [
        MatButton,
        MatIcon,
        RouterLink,
        CustomRichTextComponent,
        CommonModule,
        BeaconEntityIconComponent,
        LoadingSpinnerComponent,
        ProjectSummaryComponent,
        EmptyListComponent,
    ],
})
export class CommitmentLibraryHomeComponent {
    CustomRichTextTypeEnum = CustomRichTextTypeEnum;
    public currentUser: UserDto;
    public summary$: Observable<CommitmentLibrarySummaryDto> = this.commitmentLibraryService.commitmentLibrarySummaryGet();
    userSubscription: Subscription;

    constructor(
        private router: Router,
        private commitmentLibraryService: CommitmentLibraryService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.userSubscription = this.authenticationService.getCurrentUser().subscribe((result) => {
            this.currentUser = result;
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.userSubscription?.unsubscribe();
    }

    get canCreateProject(): boolean {
        return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.ProjectRights, RightsEnum.Create);
    }

    createProject(project: ProjectDto = null) {
        const dialogRef = this.dialog.open<ProjectUpsertDialogComponent, IProjectUpsertDialogComponentData, IProjectUpsertDialogComponentResponse>(
            ProjectUpsertDialogComponent,
            {
                width: "1000px",
                data: {
                    Project: project,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.router.navigateByUrl(`/compliance-tracking/projects/${result.ProjectID}`).then(() => {
                    this.alertService.pushAlert(new Alert("The project was successfully created.", AlertContext.Success), 5000);
                });
            }
        });
    }
}
